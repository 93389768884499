<template>
  <div class="pageAllMontages" :class="{ 'pa-3': !isMobile }">
    <SocketWatch :tables="['montages']" @loadData="loadData" />
    <v-card :tile="isMobile">
      <div v-if="isMobile">
        <v-row class="page_filterTable ma-0 px-1">
          <v-col cols="8">
            <v-text-field
              color="rgb(224, 166, 119)"
              v-model="filterMontagesByDateText"
              @click="selectDate()"
              label="Дата"
              clearable
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="2" class="pt-6">
              <v-btn icon @click="openFilters = !openFilters">
                <v-badge
                  v-if="filtersSelected"
                  color="orange"
                  overlap
                  :content="filtersSelected"
                >
                  <v-icon>mdi-tune</v-icon>
                </v-badge>
                <v-icon v-else>mdi-tune</v-icon>
              </v-btn>
          </v-col>
          <v-col cols="2" class="pt-6">
            <v-btn icon @click="createMontage()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card flat tile v-show="openFilters">
            <v-divider />
            <v-row class="page_filterTable ma-0 px-1">
              <v-col cols="12" class="pt-0">
                <v-autocomplete
                  color="rgb(224, 166, 119)"
                  v-model="filterMontagesByClient"
                  @change="$store.dispatch('montages/loadMontages')"
                  label="Клиент"
                  :items="clients"
                  item-text="name"
                  return-object
                  clearable
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  color="rgb(224, 166, 119)"
                  v-model="filterMontagesByStatus"
                  @change="$store.dispatch('montages/loadMontages')"
                  label="Статус"
                  :items="montagesStatusArr"
                  clearable
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  color="rgb(224, 166, 119)"
                  v-model="filterMontagesByManager"
                  @change="$store.dispatch('montages/loadMontages')"
                  label="Менеджер"
                  :items="managers"
                  item-text="displayName"
                  return-object
                  clearable
                />
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </div>
      <div v-else class="d-flex align-center px-4">
        <v-row class="page_filterTable">
          <v-col cols="2">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              v-model="filterMontagesByDateText"
              @click="selectDate()"
              label="Дата"
              clearable
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterMontagesByClient"
              @change="$store.dispatch('montages/loadMontages')"
              label="Клиент"
              :items="clients"
              item-text="name"
              return-object
              clearable
            />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterMontagesByStatus"
              @change="$store.dispatch('montages/loadMontages')"
              label="Статус"
              :items="montagesStatusArr"
              clearable
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterMontagesByManager"
              @change="$store.dispatch('montages/loadMontages')"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
          <!-- <v-col cols="2" class="d-flex align-center justify-center pa-0">
            <v-btn
              small
              icon
              @click="montagesPage > 1 ? $store.dispatch('montages/loadMontages', 'prev') : null"
              :disabled="montagesPage === 1"
            >
              <v-icon size="22">mdi-chevron-left</v-icon>
            </v-btn>
            <span style="font-size: 15px;">{{ montagesPage }} стр.</span>
            <v-btn
              small
              icon
              @click="montages.length > 0 && (20 * montagesPage - (20 - montages.length)) < maxMontagesCount ? $store.dispatch('montages/loadMontages', 'next') : null"
              :disabled="(20 * montagesPage - (20 - montages.length)) >= maxMontagesCount"
            >
              <v-icon size="22">mdi-chevron-right</v-icon>
            </v-btn>
          </v-col> -->
        </v-row>
        <v-spacer></v-spacer>
        <v-btn small icon @click="createMontage()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <v-skeleton-loader
        v-if="!montagesLoaded && (!montages || montages.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div 
        v-if="montagesLoaded && (!montages || montages.length === 0)"
        class="pa-3 d-flex justify-center"
      >
        Нет монтажей
      </div>

      <v-data-table
        v-if="montages && montages.length > 0"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 20, 30, 40]
				}"
				:headers="[
          {
            text: 'Дедлайн',
            align: 'start',
            sortable: false,
            value: 'date'
				  },
          {
            text: 'Клиент',
            align: 'start',
            sortable: false,
            value: 'client'
				  },
          {
            text: 'Номер',
            align: 'start',
            sortable: false,
            value: 'numberOrder'
				  },
          {
            text: 'Бригада',
            align: 'start',
            sortable: false,
            value: 'brig'
				  },
          {
            text: 'Что делать',
            align: 'start',
            sortable: false,
            value: 'tasks'
				  },
          {
            text: 'Статус',
            align: 'start',
            sortable: false,
            value: 'status'
				  },
          {
            text: 'Менеджер',
            align: 'start',
            sortable: false,
            value: 'manager'
				  },
        ]"
				:items="montages"
				:items-per-page="10"
        :dense="!isMobile"
				@click:row="editMontage"
			>

      <template v-slot:[`item.date`]="{ item }">
				{{ $formatDate(new Date(item.date)) }}
			</template>
      <template v-slot:[`item.client`]="{ item }">
        {{ item.client.name }}
			</template>
      <template v-slot:[`item.numberOrder`]="{ item }">
        {{ item.numberOrder || 'Без номера' }}
			</template>
      <template v-slot:[`item.brig`]="{ item }">
        {{ item.brig ? item.brig.displayName : 'Не указана'}}
			</template>
      <template v-slot:[`item.tasks`]="{ item }">
        {{ $sliceStr($getStrTasks(item.tasks), 125) }}
			</template>
      <template v-slot:[`item.status`]="{ item }">
        {{ montagesStatus[item.status] }}
			</template>
      <template v-slot:[`item.manager`]="{ item }">
       {{item.manager.displayName}}
			</template>
    </v-data-table>



      <!-- <v-simple-table :dense="!isMobile" v-if="montages && montages.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дедлайн</th>
							<th class="text-left">Клиент</th>
              <th class="text-left">Номер</th>
              <th class="text-left">Бригада</th>
              <th class="text-left">Что делать</th>
              <th class="text-left">Статус</th>
              <th class="text-left">Менеджер</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="montage in montages"
              :key="montage.id"
              @click="editMontage(montage)"
              class="tableItemList"
            >
              <td style="min-width: 80px;">{{ $formatDate(new Date(montage.date)) }}</td>
							<td :style="`min-width: 125px; ${isMobile ? 'min-width: 150px;' : ''}`">
								{{ montage.client.name }}
							</td>
							<td style="min-width: 80px;">
								<span v-if="montage.numberOrder">{{ montage.numberOrder }}</span>
								<span v-else>Без номера</span>
							</td>
              <td style="min-width: 125px;">
                <span v-if="montage.brig">{{ montage.brig.displayName }}</span>
                <span v-else>Не указана</span>
              </td>
              <td :style="`max-width: 280px; ${isMobile ? 'min-width: 150px;' : ''}`">
                {{ $sliceStr($getStrTasks(montage.tasks), 125) }}
              </td>
              <td :style="`${isMobile ? 'min-width: 120px;' : ''}`">
                {{ montagesStatus[montage.status] }}
              </td>
              <td>{{ montage.manager.displayName }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table> -->

      
      <v-divider v-if="isMobile && montages.length > 0" />
      <v-row v-if="isMobile && montages.length > 0" class="ma-0">
        <v-col cols="12" class="d-flex align-center justify-center mt-1">
          <v-btn 
            icon
            large
            @click="montagesPage > 1 ? $store.dispatch('montages/loadMontages', 'prev') : null"
            :disabled="montagesPage === 1"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <span>{{ montagesPage }} стр.</span>
          <v-btn 
            icon
            large
            @click="montages.length > 0 && (10 * montagesPage - (10 - montages.length)) < maxMontagesCount ? $store.dispatch('montages/loadMontages', 'next') : null"
            :disabled="(10 * montagesPage - (10 - montages.length)) >= maxMontagesCount"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { montagesStatus, montagesStatusArr } from '@/helpers'
import MontageModal from  '@/components/Modals/Montage/Index'
import SelectDate from '@/components/Modals/SelectDate'

export default {
  name: 'AllMontages',
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    },
    montagesStatus: montagesStatus,
    montagesStatusArr: montagesStatusArr,
    filterMontagesByDateText: '',
    openFilters: false
  }),
  async beforeRouteLeave(to, from, next) {
    this.$store.commit('montages/resetAllFilterMontages')
    this.$store.dispatch('montages/loadMontages')
    next()
  },
  created() {
    this.loadData()
  },
  computed: {
    maxMontagesCount() {
      return this.$store.getters['montages/getMaxMontagesCount']
    },
    montagesPage() {
      return this.$store.getters['montages/getMontagesPage']
    },
    filtersSelected() {
      let filters = [this.filterMontagesByClient, this.filterMontagesByStatus, this.filterMontagesByManager]
      filters = filters.filter(x => !!x)
      return filters.length
    },
    managers() {
      const managers = this.$store.getters['users/getManagers']
      return managers //.filter((del) => console.log(del, 'delete'))
    },
    montagesLoaded() {
      return this.$store.getters['montages/getMontagesLoaded']
    },
    montages() {
      return this.$store.getters['montages/getMontages']
    },
    clients() {
      return this.$store.getters['clients/getClients']
    },
    filterMontagesByDate: {
      get() { return this.$store.getters['montages/getFilterMontagesByDate'] },
      set(val) { this.$store.commit('montages/setFilterMontagesByDate', val) }
    },
    filterMontagesByClient: {
      get() { return this.$store.getters['montages/getFilterMontagesByClient'] },
      set(val) { this.$store.commit('montages/setFilterMontagesByClient', val) }
    },
    filterMontagesByStatus: {
      get() { return this.$store.getters['montages/getFilterMontagesByStatus'] },
      set(val) { this.$store.commit('montages/setFilterMontagesByStatus', val) }
    },
    filterMontagesByManager: {
      get() { return this.$store.getters['montages/getFilterMontagesByManager'] },
      set(val) { this.$store.commit('montages/setFilterMontagesByManager', val) }
    },
  },
  methods: {
    loadData() {
      this.$store.dispatch('montages/loadMontages')
      this.$store.dispatch('clients/loadClients')
      this.$store.dispatch('users/loadManagers')
    },
    hc(e) {
      console.log(e)
    },
    clearDate() {
      this.filterMontagesByDate = null
      this.filterMontagesByDateText = ''
      this.$store.dispatch('montages/loadMontages')
    },
    async selectDate() {
      let modalDate = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: 'Дата заказа',
          selectDate: this.filterMontagesByDate ? this.filterMontagesByDate.toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)
        }
      })
      if (modalDate) {
        this.filterMontagesByDate = new Date(modalDate)
        this.filterMontagesByDateText = new Date(modalDate).toLocaleString('ru', {weekday: 'short', day: 'numeric', month: 'short'})
        this.$store.dispatch('montages/loadMontages')
      }
    },
    goUrl(url) {
      window.open(url, '_blank').focus();
    },
    async createMontage() {
      const res = await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: false
        }
      })
      this.$store.dispatch('montages/loadMontages')
    },
    async editMontage(montage) {
      const res = await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: true,
          data: montage
        }
      })
      this.$store.dispatch('montages/loadMontages')
    }
  }
}
</script>
