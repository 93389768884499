// Montages
import ViewMontages from '@/views/montages/ViewMontages.vue'
import UnassignedMontages from '@/components/Montages/Pages/UnassignedMontages.vue'
import AssignedMontages from '@/components/Montages/Pages/AssignedMontages.vue'
import Montages from '@/components/Montages/Pages/Montages.vue'

const adminMobRoutes = [
  {
    path: '/',
    component: ViewMontages,
    meta: {
      order: 0,
      navDrawer: true,
      text: 'Монтажи',
      icon: 'mdi-truck-minus',
      tabs: [
        { path: '/', text: 'Не назначенные' },
        { path: '/assigned', text: 'Назначенные' },
        { path: '/all', text: 'Все монтажи' }
      ]
    },
    children: [
      { path: '', name: 'UnassignedMontages', component: UnassignedMontages },
      { path: 'assigned', component: AssignedMontages },
      { path: 'all', component: Montages },
    ]
  }
]

export default adminMobRoutes
