<template>
	
		<v-card style="min-height: 4em;" class="d-flex align-center justify-center">
			<div style="width: 100%">
				<template v-if="currentUser.role === 'admin'">
					<v-card-title class="py-2 pl-3 pr-2">
						<v-list-item-title style="max-width: calc(100% - 130px);">
							<span v-if="selectedCategory">{{ selectedCategory.name }}</span>
							<span v-else>Категории</span>
						</v-list-item-title>
						
						<v-spacer />
						<v-btn class="mr-1" small icon :disabled="!selectedCategory" @click="closeCategory()">
							<v-icon>mdi-close</v-icon>
						</v-btn>
						<v-btn class="mr-1" small icon  @click="drag = !drag">
							<v-icon size="18">mdi-sort</v-icon>
						</v-btn>
						
						<v-btn class="mr-1" small icon :disabled="!selectedCategory || isEditOrder" @click="editCategory()">
							<v-icon size="20">mdi-pencil</v-icon>
						</v-btn>

						<v-menu bottom left>
							<template v-slot:activator="{ on, attrs }">
								<v-btn small icon v-bind="attrs" v-on="on">
									<v-icon size="22">mdi-dots-vertical</v-icon>
								</v-btn>
							</template>

							<v-list>
								
								<v-list-item @click="addCategory()" :disabled="isEditOrder">
									<v-list-item-icon>
										<v-icon :disabled="isEditOrder">mdi-plus</v-icon>
									</v-list-item-icon>
									<v-list-item-title>Новая категория</v-list-item-title>
								</v-list-item>
								<v-list-item @click="delCategory()" :disabled="!selectedCategory || isEditOrder">
									<v-list-item-icon>
										<v-icon :disabled="!selectedCategory || isEditOrder" color="red">mdi-trash-can-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-title>Удалить</v-list-item-title>
								</v-list-item>
							
							</v-list>
						
						</v-menu>
					</v-card-title>
					<v-divider />
				</template>

				<template v-if="items && items.length > 0">
					
					<v-list v-show="!isEditOrder" >
					
						<v-list-item-group
							v-model="selectedItem"
							color="primary"
							dense
						>
						<draggable
            v-if="drag"
            class="tableMontage"
						animation="200"
						group="menu"
            v-model="items"
          >

							<transition-group type="transition" name="flip-list">
								<v-list-item v-for="item in items" :key="item.id + item.name" @click="setSelectedCategory(item)">
									<v-icon>mdi-drag-vertical</v-icon>
									<v-list-item-content>
										
										<v-list-item-title>{{ item.name }}</v-list-item-title>
									</v-list-item-content>

								</v-list-item>
							</transition-group>
						</draggable>


						<template v-else>
							<v-list-item v-for="item in items" :key="item.id + item.name" @click="setSelectedCategory(item)">
							
								<v-list-item-content>
								<v-list-item-title>{{ item.name }}</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						</template>
					</v-list-item-group>
					</v-list>
				</template>

				<div v-else style="color: grey; font-size: 1.2em;" class="d-flex justify-center py-3">
					<span>Нет элементов</span>
				</div>

			</div>
		</v-card>
	
</template>

<script>
	import ModalCategory from './modals/Category.vue'
	import Confirm from '@/components/Modals/Confirm'
	import draggable from 'vuedraggable'
	import { mapActions, mapGetters, mapState } from 'vuex'
	
	export default {
		components: {
			draggable,
		},
		data: () => ({
			isEditOrder: false,
			loadItems: false,
			selectedItem: null,
			drag: false,
		}),
		watch: {
			// selectedItem(value) {
			// 	this.setSelectedCategory(typeof value === 'number'? this.items[value]  : null)
			// },
		},
		computed: {
			...mapState('warehouse', [
				'categorys',
        'selectedCategory',
      ]),
			
			// items() {
			// 	return this.categorys
			// },
			items: {
        get() {
          return this.categorys
        },
        set(value) {
          this.sortCategorys(value)
        }
      },

			dragOptions() {
				return {
					animation: 200,
					disabled: !this.isEditOrder,
				}
			},
			currentUser() {
				return this.$store.getters['user/getUser']
			},
		},
		methods: {
			...mapActions('warehouse', [
				'setSelectedCategory',
        'createCategory',
        'updateCategory',
        'deleteCategory',
				'sortCategorys'
      ]),

			async delCategory() {
				const resConfirm = await this.$showModal({
					component: Confirm,
					isPersistent: true,
					props: {
						title: `Удаление категории`,
						text: `Действительно хотите удалить категорию "${this.selectedCategory.name}"?`
					}
				})
				if (resConfirm) {
					await this.deleteCategory({ ...this.selectedCategory})
					this.$router.go()
				}
			},
			
			async addCategory() {
				let res = await this.$showModal({
					component: ModalCategory,
					isPersistent: false,
					props: {
						edit: false,
						data: {}
					}
				})
				if (res) {
					await this.createCategory(res)
					this.$router.go()
				}
			},
			async editCategory() {
				let res = await this.$showModal({
					component: ModalCategory,
					isPersistent: false,
					props: {
						edit: true,
						data: this.selectedCategory
					}
				})
				if (res) {
					this.updateCategory({ ...this.selectedCategory, ...res})
				}
			},

			closeCategory() {
				this.setSelectedCategory(null)
			},
		}
	}
</script>

<style lang="scss">
	.menuDraggableItem {
		padding-left: 12px !important;
		padding: 0 12px !important;

		&:hover {
			cursor: grab;
			background-color: rgba(114, 114, 114, 0.39);
		}

		.v-list-item__title {
			color: #473f3f;
		}
	}
</style>