<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <NewComment :order="order" :order_id="order_id" @load="loadComments" />
    </v-col>
    <v-col
      cols="12"
      class="d-flex align-center py-0"
      v-for="comment in filtredComments"
      :key="comment.id"
    >
      <v-row>
        <v-col class="py-0">
          <v-row>
            <v-col cols="2">
              <my-mini-avatar :user="comment.fromData" />
            </v-col>
            <v-col cols="10" style="margin-left: -4.5%;">
              <v-row>
                <v-col cols="12" class="pa-0">
                  <v-subheader class="pa-0" style="height: 23px;">
                    <span style="font-weight: 500;">{{ comment.fromData.displayName }}</span>
										<span v-if="comment.program" class="ml-2">Авто. создан</span>
                    <span class="ml-2">{{ $formatDateTime(new Date(comment.date)) }}</span>
										<span 
											v-if="user.id === comment.fromData.id && (!comment.program && !comment.del)"
											class="ml-1"
										>
											<v-btn icon small @click="deleteComment(comment)">
												<v-icon size="20" color="red">
													mdi-trash-can-outline
												</v-icon>
											</v-btn>
										</span>
                  </v-subheader>
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-row>
                    <v-col 
											v-if="comment.text"
											:class="['py-0', { del: comment.del}]"
											v-html="comment.text"
										/>
                 
                    <v-col 
                      v-if="!comment.text && (!comment.files || comment.files.length === 0)"
                      :class="['py-0', { del: comment.del}]"
                    >
                      Пустой комментарий
                    </v-col>
                    <v-col v-if="comment.files && comment.files.length > 0" cols="12" class="py-0">
                      <v-container fluid class="pa-0">
                        <v-row>
                          <v-col
                            v-for="(image, index_image) in comment.files"
                            :key="index_image"
                            class="d-flex child-flex"
                            cols="4"
                          >
                            <my-preview-image :canDownload="true" :fbPath="image" @delete="deleteImage(image, comment)" />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import NewComment from './NewComment.vue'

import api from '@/utils/api'

export default {
  components: {
    NewComment
  },
  props: ['order_id', 'order', 'showProgramComment'],
  data: () => ({
    comments: [],
  }),
  
  created() {
    this.loadComments()
  },
  methods: {
		async deleteComment(comment) {
      await api.put('comments/update',{ id: comment.id, del: true }).then(response => {
        if(response.status == 200 && response.data.status){
          this.loadComments()
        }
      })

		},
    async deleteImage(image, comment) {
      comment.files = comment.files.map(function(file) {
        return file !== image ? file : "deleted";
      });
      await api.put('comments/update',{ id: comment.id, files: comment.files.join(',') }).then(response => {
        if(response.status == 200 && response.data.status){
          this.$emit('delete-image')
          setTimeout(() => this.loadComments(), 1000)
        }
      })
		},
    async loadComments() {
      if (this.order_id) {
        // let queryStore = ordersCollection.doc(this.order_id).collection('comments')
        let queryStore = await api.get('orders/get',this.order_id)
        let comments = queryStore.data.data.comments || []
        
        comments = comments.sort(function(a,b){
            return new Date(b.date) - new Date(a.date);
        })
        comments.forEach(el => {
          if(el.files){
            el.files = el.files.split(',')
          }
        })
  
        this.comments = comments || []
        
      }
    },
  },
	computed: {
		user() {
      return this.$store.getters['user/getUser']
    },
		filtredComments() {
			let comments = this.comments
			if (!this.showProgramComment) {
				comments = comments.filter(x => !x.program)
        comments = comments.filter(x => !x.del)
			}
			
			return comments
		}
	},
  beforeDestroy() {
    
  }
}
</script>