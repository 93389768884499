import { metaInfoCollection } from '@/plugins/firebase'
import { sortList } from '@/helpers'
import api from '@/utils/api'

export default {
    namespaced: true,
    state: {
      settings: {},
      companyActivitys: [],
			companyWhereFrom: [],
      settingsId: 0,
    },
    getters: {
      getSettings: state => {
        return state.settings
      },
      getHourPayWorkMasters: (state, getters, rootState, rootGetters) => {
        let settings = state.settings
        let user = rootGetters['user/getUser']
        return (user && ['master', 'admin', 'prodDir'].includes(user.role)) && settings.hourPayWorkMasters || false
      },
      stngHourPayWorkMasters: state => {
        return state.settings.hourPayWorkMasters || false
      },
      getCompanyActivitys: state => {
        return sortList(state.companyActivitys, 'title')
      },
      getWhereFromCompany: state => {
        return sortList(state.companyWhereFrom, 'title')
      }
    },
    mutations: {
      setSettings(state, payload) {
        state.settings = payload;
      },
      setCompanyActivitys(state, payload) {
        state.companyActivitys = payload;
      },
			setCompanyWhereFrom(state, payload) {
        state.companyWhereFrom = payload;
      },
      setSettingsId(state, payload) {
        state.settingsId = payload;
      }
    },
    actions: {
      async loadCompanyActivitys({ commit, dispatch }) {
        commit('templ/setLoading', true, { root: true })
        await api.post('company-activitys/get-list',{
          limit: 10000,
          offset: 0
        }).then(response => {
          if(response.status == 200 && response.data.status){
            commit('setCompanyActivitys', response.data.data)
            commit('templ/setLoading', false, { root: true })
          }
        })
      },
			async loadCompanyWhereFrom({ commit, dispatch }) {
        commit('templ/setLoading', true, { root: true })
        await api.post('company-where-from/get-list',{
          limit: 10000,
          offset: 0
        }).then(response => {
          if(response.status == 200 && response.data.status){
            commit('setCompanyWhereFrom', response.data.data)
            commit('templ/setLoading', false, { root: true })  
          }
        })
      },
			async updateCompanyActivity({dispatch}, value) {
        await api.put('company-activitys/update',value)
        await dispatch('loadCompanyActivitys')

			},
			async updateCompanyWhereFrom({dispatch}, value) {
        await api.put('company-where-from/update',value)
        await dispatch('loadCompanyWhereFrom')
			},
			async checkCompanyActivity({ getters,dispatch }, value) {
				const companyActivitys = getters.getCompanyActivitys
				let findItem = companyActivitys.find(x => x.title === value)
				if (!findItem) {
					await api.post('company-activitys/create', {title: value})
          await dispatch('loadCompanyActivitys')
				}
			},
			async checkCompanyWhereFrom({ getters,dispatch }, value) {
				const whereFromCompany = getters.getWhereFromCompany
				let findItem = whereFromCompany.find(x => x.title === value)
				if (!findItem) {
          await api.post('company-where-from/create', { title: value })
          await dispatch('loadCompanyWhereFrom')
				}
			},
			async delCompanyActivity({ getters,dispatch }, value) {
				const companyActivitys = getters.getCompanyActivitys
				let findItem = companyActivitys.find(x => x.id === value)
				if (findItem) {
					await api.delete('company-activitys/delete', {id:value})
          await dispatch('loadCompanyActivitys')
				}
			},
			async delCompanyWhereFrom({ getters,dispatch}, value) {
				const companyWhereFrom = getters.getWhereFromCompany
				let findItem = companyWhereFrom.find(x => x.id === value)
        console.log(value)
				if (findItem) {
					await api.delete('company-where-from/delete', {id:value})
          await dispatch('loadCompanyWhereFrom')
				}
			},
      async loadSettings({ commit, dispatch }) {
        commit('templ/setLoading', true, { root: true })
        await dispatch('loadCompanyActivitys')
        await dispatch('loadCompanyWhereFrom')
        commit('templ/setLoading', false, { root: true })
        await api.get('get-settings').then(response => {
          if(response.status == 200 && response.data.status){
            let settings = JSON.parse(response.data.data.value)
            commit('setSettings',settings)
            commit('setSettingsId',response.data.data.id)
          }
        })
       
        
        // snapshots.settings = metaInfoCollection.doc('settings').onSnapshot(async snapshot => {
        //   let settings = snapshot.data()
        //   commit('setSettings', settings)
        //   commit('templ/setLoading', false, { root: true })
        // })
      },
      async updSettings({ commit,state }, data) {
        commit('templ/setLoading', true, { root: true })
        await api.post('update-settings',{
          id: state.settingsId,
          value: JSON.stringify(data)
        })
        // await metaInfoCollection.doc('settings').update(data)
        commit('templ/setLoading', false, { root: true })
      }
    }
}