<template>
  <v-card>
    <v-card-title class="userTableTitle">
      <div style="font-weight: 400;">Выплаты</div>
      <v-spacer></v-spacer>
      <v-btn v-if="currentUser.role === 'admin'" small icon @click="createPrepayment()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <div v-if="prepayments.length === 0" class="infoNoItems">
      Нет выплат
    </div>
    <v-simple-table :dense="!$isMobile" v-if="prepayments && prepayments.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Дата</th>
            <th class="text-left">Сумма</th>
            <th v-if="currentUser.role === 'admin'" class="text-left"></th>
            <th v-if="currentUser.role === 'admin'" class="text-center">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="prepayment in prepayments"
            :key="prepayment.id"
            class="tableItemList"
          >
            <td style="min-width: 80px;">{{ $formatDate(new Date(prepayment.date)) }}</td>
            <td>{{ prepayment.cost }}р.</td>
            <td v-if="currentUser.role === 'admin'" style="min-width: 80px;"></td>
            <td v-if="currentUser.role === 'admin'" class="text-center">
              <v-btn @click.stop="deleteItem(prepayment)" icon color="#bf4037">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { usersCollection } from '@/plugins/firebase'
import Finance from '@/components/Modals/Finance.vue'
import Confirm from  '@/components/Modals/Confirm'

export default {
  props: {
    currentUser: {
      type: Object,
      default: {}
    },
    user: {
      type: Object,
      default: {}
    }
  },
  computed: {
    prepayments() {
      return this.$store.getters['prepayments/getPrepayments']
      // return this.user.prepayments || []
    }
  },
  methods: {
    async deleteItem(data) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление выплаты`,
          text: `Действительно хотите удалить выплату за ${this.$formatDate(new Date(data.date))}?`
        }
      })

      if (res) {
        await this.$store.dispatch('prepayments/deletePrepayments', data)
      }
    },
    async createPrepayment() {
      let summ = await this.$showModal({
        component: Finance,
        isPersistent: false,
        props: {
          title: 'Выдать выплату',
          event: 'Выдать'
        }
      })

      if (summ) {
        this.$store.dispatch('prepayments/createPrepayments', {
          cost: Number(summ),
          // user: usersCollection.doc(this.user.uid)
          user_id: this.user.id
        })
      }
    }
  }
}
</script>