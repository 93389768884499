import { premiumsCollection, usersCollection } from '@/plugins/firebase'
import axios from 'axios'
import api from '@/utils/api'

const url = process.env.VUE_APP_SERVICE_URL

export default {
    namespaced: true,
    state: {
      premiums: []
    },
    getters: {
        getPremiums: state => {
          const sortedDeductions = state.premiums.sort(function(a,b){
            return new Date(b.date) - new Date(a.date);
          })
          return sortedDeductions
        }
    },
    mutations: {
      setPremiums(state, payload) {
        state.premiums = payload;
      },
      addPremium(state, payload){
        let premiums = state.premiums
        premiums.push(payload)
        state.premiums = premiums
      },
      delPremium(state, id){
        let premiums = state.premiums
        premiums = premiums.filter(el => el.id != id)
        state.premiums = premiums
      }
    },
    actions: {
      async deletePremiums({ commit, rootState }, data) {
        commit('templ/setLoading', true, { root: true })
        // await premiumsCollection.doc(data.id).update({ del: true })
        data.del = true
        await api.put('premiums/update',{ id: data.id, del: true })
          .then(() => {
            commit('delPremium',data.id)
            console.log('Премия удалена')
          }).catch((err) => {
              commit('templ/setError', 'Ошибка удаления премии, попробуйте позже.')
              console.log(err)
          }).finally(() => {
              commit('templ/setLoading', false, { root: true })
          })
        
      },
      async createPremiums({ commit, dispatch, rootGetters }, data) {
        commit('templ/setLoading', true, { root: true })
        let filterDate = rootGetters['users/getFilterUserDataByMonth']
        let date = new Date(filterDate), y = date.getFullYear(), m = date.getMonth()
        filterDate = new Date(y, m + 1, 0, 23, 59)
        let toDay = new Date()

        if (filterDate.getMonth() != toDay.getMonth()) {
            date = new Date(filterDate.toISOString().substr(0, 10))
        } else {
            date = new Date(toDay.toISOString().substr(0, 10))
        }

        const obj = { 
            ...data,
            date: date,
            del: false,
            cost: Number(data.cost)
        }
        // await premiumsCollection.add(obj)
        await api.post('premiums/create',obj).then(response => {
          if(response.status == 200){
            commit('addPremium',response.data.premiums)
          }
        })
        commit('templ/setLoading', false, { root: true })
      },
      async loadPremiums({ commit, dispatch }, data) {
        commit('templ/setLoading', true, { root: true })

        // let userRef = await usersCollection.doc(data.uid)

        // let queryStore = premiumsCollection.where('user', '==', userRef)
        let queryStore = await api.post('premiums/get-list', {
          limit: 1000,
          offset: 0,
          query: [data.month ? { input: "date", value: data.month } : null],
          query_and: [
              { input: 'user_id', value: data.id },
              { input: 'del', value: false },
              // 
          ],
          sort: 'date'
        })
        if (data.month) {
          let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
          let firstDay = new Date(y, m, 1, 0)
          let lastDay = new Date(y, m + 1, 0, 23, 59)
          // queryStore = queryStore.orderBy('date').startAt(firstDay).endAt(lastDay);
        }

        // commit('setPremiums',queryStore)
        commit('templ/setLoading',false, { root: true})
        let premiumsArr = []
        queryStore = queryStore.data.data || []
        queryStore.forEach(async (el) => {
          if(el.user){
            console.log(el.user)
          }
          premiumsArr.push(el)
        })
        // premiumsArr = premiumsArr.filter(x => !x.del)
        commit('setPremiums',premiumsArr)
        commit('templ/setLoading',false, { root: true})
        // queryStore.onSnapshot(async snapshot => {
        //   let premiumsArr = []
        //   for (let premiumDoc of snapshot.docs) {
        //       let premium = { ...premiumDoc.data(), id: premiumDoc.id }
        //       if (premium.user) {
        //           let user = await dispatch('users/userSerializer', premium.user, { root: true })
        //           premium.user = user
        //       }
        //       premiumsArr.push(premium)
        //   }
        //   premiumsArr = premiumsArr.filter(x => !x.del)

        //   commit('setPremiums', premiumsArr)
        //   commit('templ/setLoading', false, { root: true })
        // })
      },
    }
}