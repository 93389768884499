<template>
	<div class="page faq">
		<v-row class="fill-height" no-gutters>
			<v-col cols="3" class="faq_col-menu">
				<Menu
					@selectCategory="selectCategory($event)"
				/>
			</v-col>
			<v-col cols="9" class="faq_col-content">
				<Content :selectedCategory="selectedCategory" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Menu from './components/Menu.vue'
import Content from './components/Content.vue'

export default {
	components: {
		Menu,
		Content
	},
	data: () => ({
		selectedCategory: null
	}),
	methods: {
		selectCategory(item) {
			this.selectedCategory = item
		}
	}
}
</script>

<style lang="scss">
.faq {
	// &_col-menu {
	// 	border-color: rgba(255, 255, 255, 0.12);
  //   border-width: 0px 3px 0px 0px;
  //   border-style: solid;
	// }
}
</style>
