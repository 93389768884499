<template>
  <v-hover v-slot="{ hover }">
    <v-card flat class="mx-auto">
     
      <v-img
        v-if="image !== 'deleted'"
        :src="image"
        aspect-ratio="1.2"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
              size="22"
              width="3"
            />
          </v-row>
        </template>
        <div
          v-if="hover"
          class="d-flex previewImage"
        >
          <v-btn small icon size="sm" color="grey" @click="isMobile ? showImageMob(image) : showImage(image)">
            <v-icon size="20" color="grey" class="pr-1">mdi-magnify-plus-outline</v-icon>
          </v-btn>
					<v-btn small v-if="canDownload" icon color="grey" @click="downloadImg(image)">
            <v-icon size="20" color="grey" class="pl-1">mdi-download</v-icon>
          </v-btn>
          <v-btn small v-if="!disTrach" icon color="#bf4037" @click="$emit('delete', path)">
            <v-icon size="20" color="#bf4037" class="pl-1">mdi-trash-can-outline</v-icon>
          </v-btn>
        </div>
      </v-img>
      <v-img
        v-if="image === 'deleted'"
        src="https://api.m4crm.ru/get-file/19580a2d3fde7be304f3370939d0ce3f3804678731.jpg"
        aspect-ratio="1.2"
        class="grey lighten-2"
      > </v-img>
    </v-card>
  </v-hover>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import ShowImage from '@/components/Modals/ShowImage.vue'
import ShowImageMob from '@/components/Modals/ShowImageMob.vue'
import downloadjs from 'downloadjs';
import axios from 'axios';

async function forceDownload(urll){
  const url = urll.replace('get-file', 'down-file');
    const { data } = await axios.get(url)
    const base64 = await fetch(`data:image/jpeg;base64,${data.data}`)
    const blob = await base64.blob()
    const link = window.URL.createObjectURL(blob)
    let anchor = document.createElement("a")
    anchor.href = link
    anchor.download = url.split("down-file/")[1]
    anchor.click()
}

export default {
  name: 'PreviewImage',
  props: {
    fbPath: {
      default: ''
    },
    disTrach: {
      type: Boolean,
      default: false
    },
		canDownload: {
			type: Boolean,
      default: false
		}
  },
  data: () => ({
    isMobile: isMobile,
    image: ''
  }),
  created() {
    if (this.path && !this.path.uploading) {
      this.loadingImage(this.path)
    }
  },
  methods: {
		downloadImg(image) {
    //   const url = image.replace("com//", "com/get-file/").replace('https://f.', 'https://api.')
      const url = image.replace("com//", "com/down-file/").replace('https://f.', 'https://api.')
      forceDownload(url)
      
			// downloadjs(image.replace("com//", "com/get-file/").replace('https://f.', 'https://api.'))
      // return image.replace("com//", "com/get-file/").replace('https://f.', 'https://api.')
		},
    async loadingImage(path) {
      // this.image = await this.$getFbFileUrl(path)
      this.image = path
    },
    showImage(imgUrl, images){
      this.$showModal({
        component: ShowImage,
        isPersistent: false,
        props: {
          image: imgUrl,
          images: images ? images : null
        }
      })
    },
    showImageMob(imgUrl, images) {
      this.$showModal({
        component: ShowImageMob,
        isPersistent: false,
        props: {
          image: imgUrl,
          images: images ? images : null
        }
      })
    }
  },
  computed: {
    path() {
      let path = this.fbPath
      return path ? path : ''
    }
  },
  watch: {
    path(val) {
      if (!val.uploading) {
        this.loadingImage(val)
      }
    }
  }
}
</script>