<template>
  <div class="pageAllMontages" :class="{ 'pa-3': !isMobile }">
    <SocketWatch :tables="['montages']" @loadData="loadData" />
    <v-card :tile="isMobile">
      <div v-if="isMobile">
        <v-row class="page_filterTable ma-0 px-1">
          <v-col cols="8">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              :value="filterUnassignedMontagesByDateText"
              @click="selectDate()"
              label="Дата"
              clearable
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="2" class="pt-6">
              <v-btn icon @click="openFilters = !openFilters">
                <v-badge
                  v-if="filtersSelected"
                  color="orange"
                  overlap
                  :content="filtersSelected"
                >
                  <v-icon>mdi-tune</v-icon>
                </v-badge>
                <v-icon v-else>mdi-tune</v-icon>
              </v-btn>
          </v-col>
          <v-col cols="2" class="pt-6">
            <v-btn icon @click="createMontage()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card flat tile v-show="openFilters">
            <v-divider />
            <v-row class="page_filterTable ma-0 px-1">
              <v-col cols="12" class="pt-0">
                <v-autocomplete
                  color="rgb(224, 166, 119)"
                  v-model="filterUnassignedMontagesByClient"
                  @change="$store.dispatch('montages/loadUnassignedMontages')"
                  label="Клиент"
                  :items="clients"
                  item-text="name"
                  return-object
                  clearable
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-select
                  color="rgb(224, 166, 119)"
                  v-model="filterUnassignedMontagesByManager"
                  @change="$store.dispatch('montages/loadUnassignedMontages')"
                  label="Менеджер"
                  :items="managers"
                  item-text="displayName"
                  return-object
                  clearable
                />
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </div>
      <div v-else class="d-flex align-center px-4">
        <v-row class="page_filterTable">
          <v-col cols="2">
            <v-text-field
              dense
              color="rgb(224, 166, 119)"
              :value="filterUnassignedMontagesByDateText"
              @click="selectDate()"
              label="Дата"
              clearable
              @click:clear="clearDate()"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterUnassignedMontagesByClient"
              @change="$store.dispatch('montages/loadUnassignedMontages')"
              label="Клиент"
              :items="clients"
              item-text="name"
              return-object
              clearable
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterUnassignedMontagesByManager"
              @change="$store.dispatch('montages/loadUnassignedMontages')"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
          </v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn icon small @click="createMontage()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <v-skeleton-loader
        v-if="!montagesLoaded && (!montages || montages.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div 
        v-if="montagesLoaded && (!montages || montages.length === 0)"
        class="pa-3 d-flex justify-center"
        :class="{ 'infoNoItems': !isMobile }"
      >
        Нет монтажей
      </div>
      <v-simple-table :dense="!isMobile" v-if="montages && montages.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дедлайн</th>
							<th class="text-left">Клиент</th>
              <th class="text-left">Номер</th>
              <th class="text-left">Адрес</th>
              <th class="text-left">Что делать</th>
              <th class="text-left">Статус</th>
              <th class="text-left">Менеджер</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="montage in montages"
              :key="montage.id"
              @click="editMontage(montage)"
              class="tableItemList"
            >
              <td style="min-width: 80px;">{{ $formatDate(new Date(montage.date)) }}</td>
							<td :style="`min-width: 125px; ${isMobile ? 'min-width: 150px;' : ''}`">
								{{ montage.client.name }}
								<!-- <v-menu bottom right :close-on-content-click="false">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon x-small v-bind="attrs" v-on="on" style="cursor: context-menu;">
											<v-icon>mdi-information-outline</v-icon>
										</v-btn>
									</template>
									<my-info-client :id="montage.client.id" />
								</v-menu> -->
							</td>
							<td style="min-width: 80px;">
								<span v-if="montage.numberOrder">{{ montage.numberOrder }}</span>
								<span v-else>Без номера</span>
							</td>
              <td style="min-width: 125px;">{{ montage.address }}</td>
              <td :style="`max-width: 280px; ${isMobile ? 'min-width: 150px;' : ''}`">
                {{ $sliceStr($getStrTasks(montage.tasks), 125) }}
              </td>
              <td :style="`${isMobile ? 'min-width: 120px;' : ''}`">{{ montagesStatus[montage.status] }}</td>
              <td>{{ montage.manager.displayName }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { montagesStatus, montagesStatusArr } from '@/helpers'
import MontageModal from  '@/components/Modals/Montage/Index'
import SelectDate from '@/components/Modals/SelectDate'

export default {
  name: 'UnassignedMontages',
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    },
    montagesStatus: montagesStatus,
    montagesStatusArr: montagesStatusArr,
    openFilters: false
  }),
  async beforeRouteLeave(to, from, next) {
    this.$store.commit('montages/resetAllFilterUnassignedMontages')
    this.$store.dispatch('montages/loadUnassignedMontages')
    next()
  },
  created() {
    this.loadData()
  },
  computed: {
    filtersSelected() {
      let filters = [this.filterUnassignedMontagesByClient, this.filterUnassignedMontagesByManager]
      filters = filters.filter(x => !!x)
      return filters.length
    },
    montagesLoaded() {
      return this.$store.getters['montages/getUnassignedMontagesLoaded']
    },
    montages() {
      return this.$store.getters['montages/getUnassignedMontages']
    },
    clients() {
      return this.$store.getters['clients/getClients']
    },
    managers() {
      return this.$store.getters['users/getManagers']
    },
    filterUnassignedMontagesByDateText() {
      let date = this.filterUnassignedMontagesByDate
      if (date) {
        return new Date(date).toLocaleString('ru', {weekday: 'long', day: 'numeric', month: 'short'})
      }
      return ''
    },
    filterUnassignedMontagesByDate: {
      get() { return this.$store.getters['montages/getFilterUnassignedMontagesByDate'] },
      set(val) { this.$store.commit('montages/setFilterUnassignedMontagesByDate', val) }
    },
    filterUnassignedMontagesByClient: {
      get() { return this.$store.getters['montages/getFilterUnassignedMontagesByClient'] },
      set(val) { this.$store.commit('montages/setFilterUnassignedMontagesByClient', val) }
    },
    filterUnassignedMontagesByManager: {
      get() { return this.$store.getters['montages/getFilterUnassignedMontagesByManager'] },
      set(val) { this.$store.commit('montages/setFilterUnassignedMontagesByManager', val) }
    },
  },
  methods: {
    loadData() {
      // if (!this.montages || this.montages.length === 0) {
        this.$store.dispatch('montages/loadUnassignedMontages')
      // }
      // if (!this.clients || this.clients.length === 0) {
        this.$store.dispatch('clients/loadClients')
      // }
      // if (!this.managers || this.managers.length === 0) {
        this.$store.dispatch('users/loadManagers')
      // }
    },
    clearDate() {
      this.filterUnassignedMontagesByDate = null
      this.$store.dispatch('montages/loadUnassignedMontages')
    },
    async selectDate() {
      let modalDate = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: 'Дата заказа',
          selectDate: this.filterUnassignedMontagesByDate ? this.filterUnassignedMontagesByDate.toISOString().substr(0, 10) : new Date().toISOString().substr(0, 10)
        }
      })
      if (modalDate) {
        this.filterUnassignedMontagesByDate = new Date(modalDate)
        this.$store.dispatch('montages/loadUnassignedMontages')
      }
    },
    goUrl(url) {
      window.open(url, '_blank').focus();
    },
    async createMontage() {
      await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: false
        }
      })
      this.$store.dispatch('montages/loadUnassignedMontages')
    },
    async editMontage(montage) {
      await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: true,
          data: montage
        }
      })
      this.$store.dispatch('montages/loadUnassignedMontages')
    }
  }
}
</script>
