<template>
  <div class="page brig" :class="{ 'pa-3': !$isMobile }">
    <SocketWatch :tables="['clients']" @loadData="loadData" />
    <v-card :tile="$isMobile">
			<div v-if="$isMobile">
        <v-row class="page_filterTable ma-0 px-1 pt-4">
          <v-col cols="8">
            <v-text-field
              dense
              v-model="searchClients"
              color="rgb(224, 166, 119)"
              label="Фамилия/имя/телефон клиента"
              placeholder="Иванов/Иван/79884317765"
            />
          </v-col>
          <v-col cols="2" class="pt-4">
              <v-btn icon @click="openFilters = !openFilters">
                <v-badge
                  v-if="filtersSelected"
                  color="orange"
                  overlap
                  :content="filtersSelected"
                >
                  <v-icon>mdi-tune</v-icon>
                </v-badge>
                <v-icon v-else>mdi-tune</v-icon>
              </v-btn>
          </v-col>
          <v-col cols="2" class="pt-5">
						<v-btn icon small @click="createClient()">
							<v-icon size="21">mdi-account-plus-outline</v-icon>
						</v-btn>
          </v-col>
        </v-row>
        <v-expand-transition>
          <v-card flat tile v-show="openFilters">
            <v-divider />
            <v-row class="page_filterTable ma-0 px-1 pt-4">
              <v-col cols="12" class="pt-0">
                <v-autocomplete
									dense
									color="rgb(224, 166, 119)"
									v-model="filterTasksByManager"
									@change="$store.dispatch('clients/loadClients')"
									label="Менеджер"
									:items="managers"
									item-text="displayName"
									return-object
									clearable
								/>
              </v-col>
            </v-row>
          </v-card>
        </v-expand-transition>
      </div>
      <div v-else class="d-flex align-center px-4">
        <v-row class="page_filterTable">
          <v-col cols="6">
            <v-text-field
              dense
              v-model="searchClients"
              color="rgb(224, 166, 119)"
              label="Фамилия/имя/телефон клиента"
              placeholder="Иванов/Иван/79884317765"
            />
          </v-col>
					<v-col cols="4">
						<v-autocomplete
              dense
              color="rgb(224, 166, 119)"
              v-model="filterTasksByManager"
              @change="$store.dispatch('clients/loadClients')"
              label="Менеджер"
              :items="managers"
              item-text="displayName"
              return-object
              clearable
            />
					</v-col>
        </v-row>
        <v-spacer></v-spacer>
        <v-btn icon small @click="createClient()">
          <v-icon size="21">mdi-account-plus-outline</v-icon>
        </v-btn>
      </div>
      <v-divider />
			<v-data-table
				:headers="tableHeadres"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 20, 30, 40]
				}"
				:items="clients"
				:items-per-page="30"
				:server-items-length="count"
        @pagination="changePagination"
 				dense
			>
				<template v-slot:[`item.surname`]="{ item }">
					<span v-if="item.surname">{{ item.surname }}</span>
					<span v-else>Не указана</span>
				</template>
				<template v-slot:[`item.phonenumber`]="{ item }">
					<span v-if="item.contacts && item.contacts.length > 0">
						{{ getMainContact(item.contacts) }}
					</span>
					<span v-else>Не указан</span>
				</template>
				<template v-slot:[`item.creatorData`]="{ item }">
					<span v-if="item.creatorData">{{ item.creatorData.displayName }}</span>
					<span v-else>Не задан</span>
				</template>
				<template v-slot:[`item.montages`]="{ item }">
					<span v-if="item.montages && item.montages.length > 0">{{ item.montages.length }}</span>
					<span v-else>Нет монтажей</span>
				</template>
				<template v-slot:[`item.manufactory`]="{ item }">
					<span v-if="item.manufactory && item.manufactory.length > 0">{{ item.manufactory.length }}</span>
					<span v-else>Нет задач в производство</span>
				</template>
				<template v-slot:[`item.action`]="{ item }">
					<v-btn small @click="editClient(item)" icon color="#424242">
						<v-icon size="18">mdi-pencil</v-icon>
					</v-btn>
					<v-btn small v-if="user.role === 'admin'" @click="deleteClient(item)" icon color="#bf4037">
						<v-icon size="18">mdi-trash-can-outline</v-icon>
					</v-btn>
				</template>
			</v-data-table>
    </v-card>
  </div>
</template>

<script>
import {debounce} from 'lodash';
import Client from  '@/components/Modals/Clients/Index'
import Confirm from  '@/components/Modals/Confirm'
import { RoleText } from '@/helpers'

export default {
  name: 'Clients',
  components: {},
  data: () => ({
    roleText: RoleText,
    error: null,
		openFilters: false
  }),
	async beforeRouteLeave(to, from, next) {
    this.searchClients = ''
		this.filterTasksByManager = null
    next()
  },
	created() {
		this.loadData()
	},
  computed: {
		tableHeadres() {
			return [
				{
					text: 'Клиент',
					align: 'start',
					sortable: false,
					value: 'name',
				},
				{
					text: 'Фамилия',
					align: 'start',
					sortable: false,
					value: 'surname',
				},
				{
					text: 'Номер телефона',
					align: 'start',
					sortable: false,
					value: 'phonenumber',
				},
				{
					text: 'Создал',
					align: 'start',
					sortable: false,
					value: 'creatorData',
				},
				{
					text: 'Монтажи',
					align: 'start',
					sortable: false,
					value: 'montages',
				},
				{
					text: 'Производство',
					align: 'start',
					sortable: false,
					value: 'manufactory',
				},
				{
					text: 'Действия',
					align: 'end',
					sortable: false,
					value: 'action',
				},
			]
		},
    count(){
      return this.$store.getters['clients/count']
    },
    searchClients: {
      get() { return this.$store.getters['clients/getSearchClients'] },
      set(val) { this.$store.commit('clients/setSearchClients', val) }
    },
		filterTasksByManager: {
      get() { return this.$store.getters['clients/getFilterClientByManager'] },
      set(val) { this.$store.commit('clients/setFilterClientByManager', val) }
    },
		filtersSelected() {
      let filters = [this.searchClients, this.filterTasksByManager]
      filters = filters.filter(x => !!x)
      return filters.length
    },
    clients() {
      return this.$store.getters['clients/getClients']
    },
		managers() {
      return this.$store.getters['users/getManagers']
    },
    user() {
      return this.$store.getters['user/getUser']
    }
  },
  watch: {
    searchClients: debounce(function(){
      this.$store.dispatch('clients/loadClients');
    }, 300)
  },
  methods: {
    loadData() { 
      // alert('load')
      this.$store.dispatch('clients/loadClients')

      if (!this.managers || this.managers.length === 0) {
        this.$store.dispatch('users/loadManagers')
      }
    },
    getMainContact(item) {
			if (item && item.length > 0) {
				let main = item.find(x => x.main)
				if (main) {
					return `${main.name}: ${main.phonenumber}`
				}
				return null
			}
			return null
		},
    async editClient(client) {
      // console.log(client.id)
      const editClient = await this.$showModal({
        component: Client,
        isPersistent: true,
        props: {
          edit: true,
          data: client
        }
      })
      if (editClient) {
        await this.$store.dispatch('clients/updateClient', {
          id: client.id,
          data: editClient
        })
        this.loadData()
      }
      
    },
    async createClient() {
      const createClient = await this.$showModal({
        component: Client,
        isPersistent: true
      })

      if (createClient) {
        await this.$store.dispatch('clients/createClient', createClient)
        this.loadData()
      }
    },
    async deleteClient(client) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление клиента`,
          text: `Действительно хотите удалить клиента "${client.name}"?`
        }
      })

      if (res) {
        this.$store.dispatch('clients/deleteClient', client)
      }
    },
    async changePagination(e){
      this.$store.commit('clients/SET_LIMIT',e.itemsPerPage)
			this.$store.commit('clients/SET_OFFSET',e.pageStart)
			this.$store.dispatch('clients/loadClients')

		if (!this.managers || this.managers.length === 0) {
      this.$store.dispatch('users/loadManagers')
    }
		}
  }
}
</script>
