import {
  metaInfoCollection,
  montagesCollection,
  usersCollection,
  clientsCollection,
  brigsCollection
} from '@/plugins/firebase'
import loadedMontages from './loaded'
import filterMontages from './filter'
import { montagesStatus } from '@/helpers'
import api from '@/utils/api'

import axios from 'axios'
const url = process.env.VUE_APP_SERVICE_URL


const snapshots = {
  loadUnassignedMontages: null,
  loadMontages: null,
  maxMontagesPaginationCount: null,
  loadMontagesForMontagerProfile: null,
  loadMontagesForMontager: null,
  loadAssignedMontages: null,
  loadManagerUnassignedMontages: null
}

export default {
  namespaced: true,
  state: {
    // montages
    montages: [],
    unassignedMontages: [],
    managerUnassignedMontages: [],
    assignedMontages: [],
    montagesForMontager: [],
    montagesForMontagerProfile: [],
    // loaded montages
    ...loadedMontages.state,
    // filters
    ...filterMontages.state,
    // pagination all montages
    montagesPage: 1,
    montagesPrev: null,
    montagesNext: null,
    maxMontagesCount: 0,
    // pagination user montages
    montagesForMontagerPage: 1,
    montagesForMontagerLastPage: 1,
    limit: 10000,
    offset: 0,
  },
  getters: {
    // montages
    getMontages: state => {
      const sortedMontages = state.montages.sort(function (a, b) {
        // return b.date.toDate() - a.date.toDate();
        return new Date(b.date) - new Date(a.date)
      })
      return sortedMontages
    },
    getUnassignedMontages: state => {
      const sortedMontages = state.unassignedMontages.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      })
      return sortedMontages
    },
    getUnassignedManagerMontages: state => {
      const sortedMontages = state.managerUnassignedMontages.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      })
      return sortedMontages
    },
    getAssignedMontages: state => {
      let montages = state.assignedMontages
      if (montages) {
        for (let keyForBrig in montages) {
          let sortedMontages = []
          console.log(montages[keyForBrig], montages[keyForBrig].montages, 'montages')
          let montagesWithOrder = montages[keyForBrig].montages.filter(x => {

            return x.order !== undefined
          })
          let montagesWithoutOrder = montages[keyForBrig].montages.filter(x => x.order === undefined)
          montagesWithOrder = montagesWithOrder.sort((a, b) => {
            return a.order - b.order
          })

          sortedMontages = [...montagesWithOrder, ...montagesWithoutOrder]
          // console.log(sortedMontages, 'sortedMontages')
          montages[keyForBrig].montages = sortedMontages
        }
        return montages
      }
      return []
    },
    getMontagesForMontager: state => {
      const sortedMontages = state.montagesForMontager.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      })
      return sortedMontages
    },
    getListMontagesPageForMontager: (state, getters) => {
      let montages = getters.getMontagesForMontager
      let pageMontages = state.montagesForMontagerPage

      let montagesPages = [];
      for (let i = 0; i < montages.length; i += 10) {
        montagesPages.push(montages.slice(i, i + 10));
      }

      let montagesArr = montagesPages[pageMontages - 1]

      return montagesArr ? montagesArr : []
    },
    getMontagesForMontagerProfile: state => {
      let montages = state.montagesForMontagerProfile
      let sortedMontages = []
      let montagesWithOrder = montages.filter(x => x.order != undefined)
      let montagesWithoutOrder = montages.filter(x => x.order === undefined)
      montagesWithOrder = montagesWithOrder.sort(function (a, b) {
        return a.order - b.order
      })
      sortedMontages = [...montagesWithOrder, ...montagesWithoutOrder]
      return sortedMontages
    },
    // filters
    ...filterMontages.getters,
    // loaded montages
    ...loadedMontages.getters,
    // pagination all montages
    getMontagesPage: state => {
      return state.montagesPage
    },
    getMontagesPrev: state => {
      return state.montagesPrev
    },
    getMontagesNext: state => {
      return state.montagesNext
    },
    getMaxMontagesCount: state => {
      return state.maxMontagesCount
    },
    // pagination user montages
    getMontagesForMontagerPage: state => {
      return state.montagesForMontagerPage
    },
    getMontagesForMontagerLastPage: state => {
      return state.montagesForMontagerLastPage
    }
  },
  mutations: {
    // montages
    setMontages(state, payload) {
      state.montages = payload
    },
    setUnassignedMontages(state, payload) {
      state.unassignedMontages = payload;
    },
    setManagerUnassignedMontages(state, payload) {
      state.managerUnassignedMontages = payload;
    },
    setAssignedMontages(state, payload) {
      state.assignedMontages = payload;
    },
    setMontagesForMontager(state, payload) {
      state.montagesForMontager = payload;
    },
    setMontagesForMontagerProfile(state, payload) {
      state.montagesForMontagerProfile = payload;
    },
    updAssignedMontagesForOrder(state, payload) {
      if (payload.brig) {
        let montagesForBrig = state.assignedMontages.find(item => item.brig.id === payload.brig)
        let montages = montagesForBrig.montages
        if (payload.montage && payload.montage.id) {
          let item = montages.find(item => item.id === payload.montage.id)
          Object.assign(item, payload.montage)
        }
      }
    },
    // filters
    ...filterMontages.mutations,
    // loaded montages
    ...loadedMontages.mutations,
    // pagination all montages
    setMontagesPaginationPage(state, payload) {
      state.montagesPage = payload
    },
    setMontagesPagination(state, payload) {
      state[`montages${payload.action}`] = payload.cursor
    },
    setMaxMontagesPaginationCount(state, payload) {
      state.maxMontagesCount = payload
    },
    // pagination user montages
    setMontagesForMontagerPaginationPage(state, payload) {
      state.montagesForMontagerPage = payload
    },
    setMontagesForMontagerPaginationLastPage(state, payload) {
      state.montagesForMontagerLastPage = payload
    },
    addMontage(state, montage) {
      let montages = state.montages
      montages.push(montage)
      state.montages = montages
    },
    addUnassignedMontage(state, montage) {
      let montages = state.unassignedMontages
      montages.push(montage)
      state.unassignedMontages = montages
    },
    SET_LIMIT(state, payload) {
      state.limit = payload
    },
    SET_OFFSET(state, payload) {
      state.offset = payload
    }
  },
  actions: {
    async loadMontageById({
      commit,
      dispatch,
      getters
    }, id) {
      commit('templ/setLoading', true, {
        root: true
      })

      // let queryStore = montagesCollection.doc(id)
      let queryStore = await api.get('/montages/get', id).then(response => {
        if (response.status == 200) {
          return response.data.data
        }
      })
      // let doc = await queryStore.get()
      // doc = await dispatch('montageSerializer', { ...doc.data(), id: doc.id })
      let doc = await dispatch('montageSerializer', {
        ...queryStore,
        id: queryStore.id
      })

      commit('templ/setLoading', false, {
        root: true
      })
      return doc
    },
    async loadMontagesForMontagerProfile({
      commit,
      dispatch,
      getters,
      state
    }, data) {
      commit('templ/setLoading', true, {
        root: true
      })
      // console.log('loadMontagesForMontagerProfile')
      // if (snapshots.loadMontagesForMontagerProfile) {
      //   snapshots.loadMontagesForMontagerProfile()
      //   snapshots.loadMontagesForMontagerProfile = null
      // }

      // let queryStore = montagesCollection.where('del', '!=', true)


      let queryStore = await api.post('montages/get-list', {
        limit: state.limit,
        offset: state.offset,
        query_and: [{
          input: 'del',
          value: false
        }, data.id ? {
          input: 'montagers',
          value: `\"${data.id}\"`
        } : null]
      }).then(async (response) => {
        if (response.status == 200) {
          let montagesArr = []

          commit('setMaxMontagesPaginationCount', response.data.count)

          let montages = response.data.data || []

          if (data.date) {
            montages = montages.filter(el => el.date.split('T')[0] == data.date.toISOString().split('T')[0]) || []
          }

          if (!Array.isArray(montages)) {
            montages = [montages]
          }

          montages.map(async (el) => {
            let montage = await dispatch('montageSerializer', el)
            montagesArr.push(montage)
          })

          commit('setMontagesForMontagerProfile', montagesArr)
          commit('templ/setLoading', false, {
            root: true
          })
        }
      })

      // console.log(queryStore, 'queryStore')

      // if (data.uid) {
      // queryStore = queryStore.where('montagers', 'array-contains', data.uid)
      // }

      // if (data.date) {
      //   queryStore = queryStore.where('date', '==', data.date)
      // }

      // snapshots.loadMontagesForMontagerProfile = queryStore.onSnapshot(async snapshot => {
      //   let montagesArr = []
      //   for (let doc of snapshot.docs) {
      //     let montage = await dispatch('montageSerializer', { ...doc.data(), id: doc.id })
      //     montagesArr.push(montage)
      //   }

      //   commit('setMontagesForMontagerProfile', montagesArr)
      //   commit('templ/setLoading', false, { root: true })
      // })
    },
    async loadMontagesForMontager({
      commit,
      dispatch,
      getters,
      state
    }, data) {
      commit('templ/setLoading', true, {
        root: true
      })
      // console.log('loadMontagesForMontager',data)
      // if (snapshots.loadMontagesForMontager) {
      //   snapshots.loadMontagesForMontager()
      //   snapshots.loadMontagesForMontager = null
      // }

      // let queryStore = montagesCollection.where('status', '==', 'ready')
      if (data.month) {
        let date = new Date(data.month),
          y = date.getFullYear(),
          m = date.getMonth()
        let firstDay = new Date(y, m, 1, 0)
        let lastDay = new Date(y, m + 1, 0, 23, 59)
        // queryStore = queryStore.orderBy('readyDate').startAt(firstDay).endAt(lastDay);
      }
      await api.post('montages/get-list', {
        limit: state.limit,

        offset: state.offset,
        query_and: [{
          input: 'status',
          value: 'ready'
        },
        data.id ? {
          input: 'montagers',
          value: String(data.id)
        } : null,
        data.month ? {
          input: 'date',
          value: data.month
        } : null,
        ],

        // query: [data.uid ? {'input':'montagers', 'value': data.uid} : ''],

      }).then(async (response) => {
        if (response.status == 200 && response.data.status) {
          // console.log(response.data, 'response.data')
          let montagesArr = []
          commit('setMaxMontagesPaginationCount', response.data.count)
          for (let doc of response.data.data || []) {
            let montage = await dispatch('montageSerializer', {
              ...doc,
              id: doc.id
            })
            montagesArr.push(montage)
          }
          montagesArr = montagesArr.filter(x => !x.del)
          let newMontagesArr = [];
          for (let i = 0; i < montagesArr.length; i += 10) {
            newMontagesArr.push(montagesArr.slice(i, i + 10));
          }
          commit('setMontagesForMontagerPaginationLastPage', newMontagesArr.length)

          commit('setMontagesForMontager', montagesArr)
          // console.log('montagesArr',montagesArr)
          commit('templ/setLoading', false, {
            root: true
          })
        }
      })
      // if (data.uid) {
      //   queryStore = queryStore.where('montagers', 'array-contains', data.uid)
      // }

      // if (data.month) {
      //   let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
      //   let firstDay = new Date(y, m, 1, 0)
      //   let lastDay = new Date(y, m + 1, 0, 23, 59)

      //   // queryStore = queryStore.orderBy("date").startAt(firstDay).endAt(lastDay)
      // }

      // snapshots.loadMontagesForMontager = await queryStore.onSnapshot(async snapshot => {
      //   let montagesArr = []
      //   for (let doc of snapshot.docs) {
      //     let montage = await dispatch('montageSerializer', { ...doc.data(), id: doc.id })
      //     montagesArr.push(montage)
      //   }
      //   montagesArr = montagesArr.filter(x => !x.del)

      //   let newMontagesArr = [];
      //   for (let i = 0; i < montagesArr.length; i += 10) {
      //     newMontagesArr.push(montagesArr.slice(i, i + 10));
      //   }
      //   commit('setMontagesForMontagerPaginationLastPage', newMontagesArr.length)

      //   commit('setMontagesForMontager', montagesArr)
      //   commit('templ/setLoading', false, { root: true })
      // })
    },
    async loadAssignedMontages({
      commit,
      dispatch,
      getters,
      state
    }) {
      commit('templ/setLoading', true, {
        root: true
      })

      // if (snapshots.loadAssignedMontages) {
      //   snapshots.loadAssignedMontages()
      //   snapshots.loadAssignedMontages = null
      // }

      // let queryStore = montagesCollection.where('del', '!=', true).where('status', 'in', ['assigned', 'work', 'no_work', 'ready'])
      let queryStore = await api.post('montages/get-list', {
        limit: state.limit,
        offset: state.offset,
        strict: [{
          input: 'del',
          value: false
        }],
        strict_or: [
          // { input: 'del', value: false },
          {
            input: 'status',
            value: 'assigned'
          },
          {
            input: 'status',
            value: 'work'
          },
          {
            input: 'status',
            value: 'no_work'
          },
          {
            input: 'status',
            value: 'ready'
          }
        ]
      })

      let filterByDate = getters.getFilterAssignedMontagesByDate
      if (filterByDate) {
        // queryStore = queryStore.where('date', '==', filterByDate)
        // queryStore = await api.post('montages/get-list', {
        //   limit: 10000,
        //   offset: 0,
        //   query: [{input: 'date', value: filterByDate}]
        // })
        queryStore = queryStore.data.data.filter(el => {
          let date = el.date.split('T')[0]
          // console.log('filterByDate',filterByDate.toISOString())
          if (date == filterByDate.toISOString().split('T')[0]) {
            return el
          }
        })
        const f = queryStore.sort((a, b) => {
          console.log(a.order, b.order)
          return a.order - b.order
        })
        console.log(f, queryStore)
      }

      let brigsArr = []
      let brigs = await api.post('brigs/get-list', {
        limit: 100,
        offset: 0,
        query: [{
          input: 'del',
          value: false
        }]
      }).then(response => {
        if (response.status == 200) {
          return response.data.data || []
        }
      })

      brigs.forEach(async (el) => {
        let brig = el
        let obj = {
          brig: brig,
          montages: []
        }
        brigsArr.push(obj)
      })
      // queryStore = queryStore.data.data || []
      queryStore.forEach(async (el) => {
        let montage = await dispatch('montageSerializer', el)
        let brigIndex = brigsArr.findIndex(x => x.brig && x.brig.id === montage.brig_id)
        if (brigIndex != -1) {
          brigsArr[brigIndex].montages.push(montage)
        }
      })

      commit('setAssignedMontages', brigsArr)
      commit('templ/setLoading', false, {
        root: true
      })
      commit('setAssignedMontagesLoaded', true)

      // snapshots.loadAssignedMontages = queryStore.onSnapshot(async snapshot => {
      //   let brigsArr = []
      //   let brigs = await brigsCollection.where('del', '!=', true).get()
      //   for (let brigDoc of brigs.docs) {
      //     // let brig = await dispatch('brigs/brigSerializer', { ...brigDoc.data(), id: brigDoc.id }, { root: true })
      //     let brig = { ...brigDoc.data(), id: brigDoc.id }
      //     let obj = { brig: brig, montages: [] }
      //     brigsArr.push(obj)
      //   }
      //   for (let montageDoc of snapshot.docs) {
      //     let montage = await dispatch('montageSerializer', { ...montageDoc.data(), id: montageDoc.id })
      //     let brigIndex = brigsArr.findIndex(x => x.brig && x.brig.id === montage.brig.id)
      //     if (brigIndex != -1) {
      //       brigsArr[brigIndex].montages.push(montage)
      //     }
      //   }

      //   commit('setAssignedMontages', brigsArr)
      //   commit('templ/setLoading', false, { root: true })
      //   commit('setAssignedMontagesLoaded', true)
      // })
    },
    async loadMontages({
      commit,
      dispatch,
      getters,
      state
    }, pagination) {
      commit('templ/setLoading', true, {
        root: true
      })
      // console.log('pagination',pagination)
      if (pagination) {
        let page = getters.getMontagesPage
        if (pagination == 'prev') {
          commit('setMontagesPaginationPage', page - 1)
          commit('SET_OFFSET', (page - 2) * state.limit)
          // queryStore = queryStore.endBefore(prev).limitToLast(20)
        }
        if (pagination == 'next') {
          // console.log('PaginationNext')
          commit('setMontagesPaginationPage', page + 1)
          commit('SET_OFFSET', page * state.limit)
          // queryStore = queryStore.startAfter(next).limit(20)
        }
      } else {
        commit('setMontagesPaginationPage', 1)
        commit('SET_OFFSET', 0)
        // queryStore = queryStore.limit(20)
      }
      let tomorrow = new Date()
      tomorrow.setDate(tomorrow.getDate() + 1)
      let toDay = new Date(tomorrow.toISOString().substr(0, 10))
      let filterByDate = getters.getFilterMontagesByDate
      if (filterByDate) {
        filterByDate = filterByDate.toISOString().split('T')[0]
      }
      let filterByClient = getters.getFilterMontagesByClient
      if (filterByClient && filterByClient.id) {
        filterByClient = filterByClient.id
      }
      let filterByStatus = getters.getFilterMontagesByStatus
      // let queryStore = montagesCollection
      let filterByManager = getters.getFilterMontagesByManager
      if (filterByManager && filterByManager.id) {
        filterByManager = filterByManager.id
      }

      let queryStore = await api.post('montages/get-list', {
        limit: state.limit,
        offset: state.offset,
        query: [filterByDate ? {
          input: 'date',
          value: filterByDate
        } : null],
        strict: [filterByStatus ? {
          input: 'status',
          value: filterByStatus
        } : null],
        query_and: [{
          input: 'del',
          value: false
        }, filterByClient ? {
          input: 'client_id',
          value: filterByClient
        } : null, filterByManager ? {
          input: 'manager_id',
          value: filterByManager
        } : null]
      })
      commit('setMaxMontagesPaginationCount', queryStore.data.count)
      queryStore = queryStore.data.data || []
      if (queryStore.length > 0 && queryStore[0]) {
        commit('setMontagesPagination', {
          action: 'Prev',
          cursor: queryStore[0]
        })
      }
      if (queryStore.length === 20 && queryStore[queryStore.length - 1]) {
        commit('setMontagesPagination', {
          action: 'Next',
          cursor: queryStore[queryStore.length - 1]
        })
      }

      let montagesArr = []

      queryStore.forEach(async (el) => {
        let montage = await dispatch('montageSerializer', el)
        montagesArr.push(montage)
      })

      commit('setMontages', montagesArr)
      commit('templ/setLoading', false, {
        root: true
      })
      commit('setMontagesLoaded', true)
    },
    async loadUnassignedMontages({
      commit,
      dispatch,
      getters
    }) {
      commit('templ/setLoading', true, {
        root: true
      })

      // if (snapshots.loadUnassignedMontages) {
      //   snapshots.loadUnassignedMontages()
      //   snapshots.loadUnassignedMontages = null
      // }

      // let queryStore = montagesCollection.where('del', '!=', true).where('status', '==', 'unassigned')
      // let queryStore = await api.post('montages/get-list',{
      //   limit: 10000,
      //   offset: 0,
      //   query_and: [{input: 'del', value: false}, {input: 'status', value: 'unassigned'}]
      // })

      let filterByClient = getters.getFilterUnassignedMontagesByClient
      if (filterByClient && filterByClient.id) {
        // let clientRef = clientsCollection.doc(filterByClient.id)
        // queryStore = queryStore.where('client', '==', clientRef)

        // queryStore = await api.post('montages/get-list',{
        //   limit: 10000,
        //   offset: 0,
        //   query_and: [{input: 'del', value: false}, {input: 'status', value: 'unassigned'}, {input: 'client_id', value: filterByClient.id}]
        // })
        filterByClient = filterByClient.id
      }

      let filterByManager = getters.getFilterUnassignedMontagesByManager

      if (filterByManager && filterByManager.id) {
        // let managerRef = usersCollection.doc(filterByManager.uid)
        // queryStore = queryStore.where('manager', '==', managerRef)
        // queryStore = await api.post('montages/get-list',{
        //   limit: 10000,
        //   offset: 0,
        //   query_and: [{input: 'del', value: false}, {input: 'status', value: 'unassigned'}, {input: 'manager_id', value: filterByManager.id}]
        // })
        filterByManager = filterByManager.id
      }

      let filterByDate = getters.getFilterUnassignedMontagesByDate
      if (filterByDate) {
        filterByDate = filterByDate.toISOString().split('T')[0]
      }
      let queryStore = await api.post('montages/get-list', {
        limit: 10000,
        offset: 0,
        query: [filterByDate ? {
          input: 'date',
          value: filterByDate
        } : null],
        query_and: [{
          input: 'del',
          value: false
        },
        {
          input: 'status',
          value: 'unassigned'
        },
        filterByManager ? {
          input: 'manager_id',
          value: filterByManager
        } : null,
        filterByClient ? {
          input: 'client_id',
          value: filterByClient
        } : null
        ]
      })
      queryStore = queryStore.data.data || []
      let montagesArr = []
      queryStore.forEach(async (el) => {
        let montage = await dispatch('montageSerializer', el)
        montagesArr.push(montage)
      })

      commit('setUnassignedMontages', montagesArr)
      commit('templ/setLoading', false, {
        root: true
      })
      commit('setUnassignedMontagesLoaded', true)

      // snapshots.loadUnassignedMontages = queryStore.onSnapshot(async snapshot => {
      //   let montagesArr = []

      //   for (let doc of snapshot.docs) {
      //     let montage = await dispatch('montageSerializer', { ...doc.data(), id: doc.id })
      //     montagesArr.push(montage)
      //   }

      //   commit('setUnassignedMontages', montagesArr)
      //   commit('templ/setLoading', false, { root: true })
      //   commit('setUnassignedMontagesLoaded', true)
      // })
    },
    async loadManagerUnassignedMontages({
      commit,
      dispatch
    }) {
      commit('templ/setLoading', true, {
        root: true
      })

      // if (snapshots.loadManagerUnassignedMontages) {
      //   snapshots.loadManagerUnassignedMontages()
      //   snapshots.loadManagerUnassignedMontages = null
      // }

      // let manager = this.getters['user/getUser']
      // let managerRef = usersCollection.doc(manager.uid)
      let manager = this.getters['user/getUser']
      // let queryStore = montagesCollection.where('del', '!=', true).where('manager', '==', managerRef).where('status', '==', 'unassigned')
      let queryStore = await api.post('montages/get-list', {
        limit: 10000,
        offset: 0,
        query_and: [{
          input: 'del',
          value: false
        }, {
          input: 'manager',
          value: manager.id
        }, {
          input: 'status',
          value: 'unassigned'
        }]
      })
      queryStore = queryStore.data.data || []
      let montagesArr = []
      queryStore.forEach(async (el) => {
        let montage = await dispatch('montageSerializer', el)
        montagesArr.push(montage)
      })
      commit('setManagerUnassignedMontages', montagesArr)
      commit('templ/setLoading', false, {
        root: true
      })
      commit('setManagerMontagesLoaded', true)
      // snapshots.loadManagerUnassignedMontages = queryStore.onSnapshot(async snapshot => {
      //   let montagesArr = []

      //   for (let doc of snapshot.docs) {
      //     let montage = await dispatch('montageSerializer', { ...doc.data(), id: doc.id })
      //     montagesArr.push(montage)
      //   }

      //   commit('setManagerUnassignedMontages', montagesArr)
      //   commit('templ/setLoading', false, { root: true })
      //   commit('setManagerMontagesLoaded', true)
      // })
    },
    async createMontage({
      commit,
      rootState,
      dispatch
    }, data) {
      commit('templ/setLoading', true, {
        root: true
      })
      const obj = {
        ...data,
        del: false
      }
      if (!obj.numberOrder) {
        let number = await dispatch('getNewNumberMontage')
        obj.numberOrder = `m${number}`
      }
      // obj.manager? obj.manager_id = obj.manager : ''
      // delete obj.manager
      // client
      if (obj.client) {
        // let client = await obj.client.get()
        // let clientData = client.data()
        // obj.clientData = {
        //   id: client.id,
        //   del: clientData.del,
        //   name: clientData.name,
        //   path: clientData.path || '',
        //   phonenumber: clientData.phonenumber,
        //   surname: clientData.surname || '',
        //   address: clientData.address ? clientData.address : []
        // }
        obj.client_id = obj.client
        delete obj.client
      }

      // manager
      if (obj.manager) {
        // let manager = await obj.manager.get()
        // let managerData = manager.data()
        // obj.managerData = {
        //   uid: managerData.uid,
        //   del: managerData.del,
        //   displayName: managerData.displayName,
        //   email: managerData.email,
        //   location: managerData.location
        // }
        obj.manager_id = obj.manager
        delete obj.manager
      }

      // brig
      if (obj.brig) {
        // let brig = await obj.brig.get()
        // let brigData = brig.data()
        // obj.brigData = {
        //   id: brig.id,
        //   displayName: brigData.displayName,
        //   del: brigData.del,
        //   location: brigData.location
        // }
        obj.brig_id = obj.brig.id
        delete obj.brig
      }
      if (obj.tasks) {
        obj.tasks = JSON.stringify(obj.tasks)
      }
      if (obj.montagers) {
        obj.montagers = obj.montagers.map(el => {
          el = String(el)
          return el
        })
      }
      obj.images = String(obj.images)
      obj.montagersInfo = JSON.stringify(obj.montagersInfo)
      obj.priceForBrig = String(obj.priceForBrig)
      // await montagesCollection.add(obj)
      let montage = await api.post('montages/create', obj).then(async response => {
        if (response.status == 200) {
          dispatch('socket/sendSocket', ['montages'], {
            root: true
          })
          console.log(obj, response)
          // if (obj.brig) {


          // отправляем монтажникам инфу о том что на них назначен монтаж
          let date = obj.date.toLocaleString('ru', { day: 'numeric', month: 'short' })
          let title = `Назначен монтаж на ${date}`
          let text = `Клиент: ${response.data.montages.client_data.name}`
          if (obj.montagers && obj.montagers.length > 0) {
            for (let to of obj.montagers) {

              await dispatch('notify/notify', {
                to,
                title: title,
                text: text,
                action: 'assignedMontage',
                payload: {
                  montageId: response.data.montages.id
                }
              }, {
                root: true
              })
            }
          }
          // }

          return response.data.montages
        }
      })
      // TODO 
      if (montage.status == 'unassigned') {
        let serializedMontage = await dispatch('montageSerializer', montage)
        commit('addUnassignedMontage', serializedMontage)
      } else {
        let serializedMontage = await dispatch('montageSerializer', montage)
        commit('addMontage', serializedMontage)
      }
      commit('templ/setLoading', false, {
        root: true
      })
    },
    async updateMontage({
      commit,
      rootState,
      rootGetters,
      dispatch
    }, obj) {
      commit('templ/setLoading', true, {
        root: true
      })
      let user = rootGetters['user/getUser']
      let action = obj.action || null
      let manager = obj.manager || null
      let oldData = obj.oldData || null
      let id = obj.id
      obj = obj.data

      if (obj.client) {
        obj.client_id = obj.client
        delete obj.client
      }

      // manager
      if (manager) {
        obj.manager_id = manager.id
      }

      // brig
      if (obj.brig) {
        obj.brig_id = obj.brig.id
        delete obj.brig
      }
      if (obj.montagers) {
        obj.montagers = obj.montagers.map(el => {
          el = String(el)
          return el
        })
      }
      if (obj.tasks) {
        obj.tasks = JSON.stringify(obj.tasks)
      }

      obj.priceForBrig = String(obj.priceForBrig)

      if (obj.images) {
        obj.images = String(obj.images)
      }


      obj.montagersInfo = JSON.stringify(obj.montagersInfo)
      // await montagesCollection.doc(id).update(obj)
      const put = { ...obj }

      console.log(obj, 'obj', manager)
      const res = await api.put('montages/update', {
        id,
        ...put
      })


      const data = res.data.montages
      console.log(data, 'data')

      if (action === 'assigned' || action === 'unassigned') {
        // отправляем монтажникам и менеджеру инфу о том что монтаж назначен или отменён
        let whoTo = []
        if (action === 'assigned') {
          whoTo = [...obj.montagers, manager.id]
        } else if (action === 'unassigned' && oldData && oldData.montagers) {
          whoTo = [...oldData.montagers, manager.id]
        }
        let indexIID = whoTo.findIndex(x => x === user.id)
        if (indexIID != -1) {
          whoTo.splice(indexIID, 1)
        }

        let date = obj.date ? obj.date : new Date(oldData.date)
        date = date.toLocaleString('ru', { day: 'numeric', month: 'short' })
        let title = ''
        let text = ''
        if (action === 'assigned') {
          title = `Назначен монтаж на ${date}`
          text = `Клиент: ${data.client_data.name}`
        } else if (action === 'unassigned') {
          title = `Монтаж на ${date}, отменён`
          text = `Клиент: ${oldData.client.name}`
        }

        if (whoTo && whoTo.length > 0) {
          for (let to of whoTo) {
            await dispatch('notify/notify', {
              to,
              title: title,
              text: text,
              action: `montage`,
              payload: {
                montageId: id
              }
            }, { root: true })
          }
        }
      }

      if (['no_work', 'work', 'ready'].includes(obj.status)) {
        let date = new Date(oldData.date)
        date = date.toLocaleString('ru', { day: 'numeric', month: 'short' })
        let title = `Монтаж для ${oldData.client.name} на ${date}`
        let text = `Обновился статус: ${montagesStatus[obj.status]}`
        await dispatch('notify/notify', {
          to: manager.uid || manager.id,
          title: title,
          text: text,
          action: `montage`,
          payload: {
            montageId: id
          }
        }, { root: true })
      }


      dispatch('socket/sendSocket', ['montages'], {
        root: true
      })
      commit('templ/setLoading', false, {
        root: true
      })
      return data
    },
    async deleteMontage({
      commit,
      rootState,
      dispatch
    }, data) {
      commit('templ/setLoading', true, {
        root: true
      })
      data.del = true
      if (data.client) {
        // let client = await obj.client.get()
        // let clientData = client.data()
        // obj.clientData = {
        //   id: client.id,
        //   del: clientData.del,
        //   name: clientData.name,
        //   path: clientData.path || '',
        //   phonenumber: clientData.phonenumber,
        //   surname: clientData.surname || '',
        //   address: clientData.address ? clientData.address : []
        // }
        data.client_id = data.client.id
        delete data.client
      }

      // manager
      if (data.manager) {
        // let manager = await obj.manager.get()
        // let managerData = manager.data()
        // obj.managerData = {
        //   uid: managerData.uid,
        //   del: managerData.del,
        //   displayName: managerData.displayName,
        //   email: managerData.email,
        //   location: managerData.location
        // }
        data.manager_id = data.manager.id
        delete data.manager
      }

      // brig
      if (data.brig) {
        // let brig = await obj.brig.get()
        // let brigData = brig.data()
        // obj.brigData = {
        //   id: brig.id,
        //   displayName: brigData.displayName,
        //   del: brigData.del,
        //   location: brigData.location
        // }
        data.brig_id = data.brig.id
        delete data.brig
      }
      if (data.tasks) {
        data.tasks = JSON.stringify(data.tasks)
      }
      data.images = String(data.images)
      data.montagersInfo = JSON.stringify(data.montagersInfo)
      // await montagesCollection.doc(data.id).update({ del: true })
      await api.put('montages/update', data)
        .then(async () => {
          console.log('Монтаж удален')
          dispatch('socket/sendSocket', ['montages'], {
            root: true
          })
          if (data.status == 'unassigned') {
            await dispatch('loadUnassignedMontages')
          }
        }).catch((err) => {
          commit('templ/setError', 'Ошибка удаления монтажа, попробуйте позже.')
          console.log(err)
        }).finally(() => {
          commit('templ/setLoading', false, {
            root: true
          })
        })
    },
    montageSerializer({
      state,
      dispatch
    }, ref) {
      let montage = ref
      // if (ref.id) {
      //   montage = ref
      // } else {
      //   let res = await ref.get()
      //   montage = { ...res.data(), id: res.id }
      // }
      if (montage.manager_data) {
        // let manager = await api.get('users/get',montage.managerData.id)
        // montage = { ...montage, manager: montage.managerData }
        montage = {
          ...montage,
          manager: montage.manager_data
        }
        delete montage.manager_data
      }
      if (montage.tasks) {
        // console.log(montage.tasks, typeof montage.tasks, 'montage.tasks' )
        montage.tasks = JSON.parse(montage.tasks)
      }
      if (montage.montagersInfo) {
        montage.montagersInfo = JSON.parse(montage.montagersInfo)
      }
      if (montage.brig_data) {
        montage = {
          ...montage,
          brig: montage.brig_data
        }
        delete montage.brig_data
      }

      if (montage.client_data) {
        montage = {
          ...montage,
          client: montage.client_data
        }
        delete montage.client_data
      }
      if (montage.images && montage.images !== 'undefined') {
        montage.images = montage.images.split(',')
      } else {
        montage.images = []
      }
      if (montage.priceForBrig) {
        montage.priceForBrig = Number(montage.priceForBrig)
      }
      // console.log('montageSerializer', montage)
      return montage
    },
    async getNewNumberMontage() {
      let toDay = new Date()
      let year = String(toDay.getFullYear()).substring(2)
      let month = toDay.getMonth() + 1

      if (month < 10) {
        month = `0${month}`
      }

      let date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth()
      let firstDay = new Date(y, m, 1, 0)
      let lastDay = new Date(y, m + 1, 0, 23, 59)

      // console.log(`Дата по UTC: ${date}`)
      // console.log(`Первый день(${firstDay}), последний день(${lastDay})`)

      let numberInMonth = '01'
      // let QueryStore = await montagesCollection.orderBy('date').startAt(firstDay).endAt(lastDay).get()
      let queryStore = await api.post('montages/get-list', {
        limit: 10000,
        offset: 0,
        sort: 'date'
      }).then(response => {
        if (response.status == 200) {
          return response.data.data
        }
      })
      // let lengthList = QueryStore.docs
      let lengthList = queryStore.length
      lengthList = lengthList + 1

      if (lengthList < 10) {
        numberInMonth = `0${lengthList}`
      } else {
        numberInMonth = lengthList
      }

      return `${year}${month}${numberInMonth}`
    },
    setMontagesFromUser({
      commit,
      dispatch
    }, user) {
      let montages = user.montages || []
      montages = montages.map(montage => {
        if (montage.manager_data) {
          // let manager = await api.get('users/get',montage.managerData.id)
          // montage = { ...montage, manager: montage.managerData }
          montage = {
            ...montage,
            manager: montage.manager_data
          }
          delete montage.manager_data
        }
        if (montage.tasks) {
          montage.tasks = JSON.parse(montage.tasks)
        }
        if (montage.montagersInfo) {
          montage.montagersInfo = JSON.parse(montage.montagersInfo)
        }
        if (montage.brig_data) {
          montage = {
            ...montage,
            brig: montage.brig_data
          }
          delete montage.brig_data
        }

        if (montage.client_data) {
          montage = {
            ...montage,
            client: montage.client_data
          }
          delete montage.client_data
        }
        if (montage.images) {
          montage.images = montage.images.split(',')
        }
        if (montage.priceForBrig) {
          montage.priceForBrig = Number(montage.priceForBrig)
        }
        return montage
      })
      // console.log('serializedMontages',montages)
      commit('setMontagesForMontager', montages)
      return montages
    }
  }
}