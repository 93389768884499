import { usersCollection } from '@/plugins/firebase'
import api from '@/utils/api'

import axios from 'axios'
const url = process.env.VUE_APP_SERVICE_URL

export default {
    namespaced: true,
    state: {
        user: null,
    },
    getters: {
        getUser: state => {
            return state.user
        },
    },
    mutations: {
        setUser(state, payload) {
            state.user = payload;
        }
    },
    actions: {
        async loadUser({ commit, dispatch }, data) {
            console.log('loadUser',data)
            if (data && data.id) {
                commit('templ/setLoading', true, { root: true })
                // usersCollection.doc(data.uid).onSnapshot(async snapshot => {
                    // let fullUser = await dispatch('users/userSerializer', snapshot.data(), { root: true })

                    // let fullUser = await axios.get(`${url}/users/get/${data.id}`).then(response => {
                    //     if (response.status == 200){
                    //         return response.data.data
                    //     }
                    // })
                    let fullUser = await api.get('users/get',data.id).then(response => {
                        if (response.status == 200){
                            return response.data.data
                        }
                    })
                    // console.log('fullUser',fullUser)
                    commit('setUser', fullUser)
                    commit('templ/setLoading', false, { root: true })
                // })
            }
        },
        async login({commit},data){
            let user = await api.post('users/login',{
                email: `${data.email}@m4r.ru`,
                password: data.password
            }).then(response => {
                if(response.status == 200 && response.data.status){
                    localStorage['jwt'] = response.data.token
                    commit('setUser', response.data.user)
                    return response.data.user
                }
            })
            // return status ? true : false
            console.log('login',localStorage['jwt'])
            return user
        },
        async getUserInfo({commit,state}){
            let token = localStorage['jwt']
            if(state.user){
                return state.user
            } else if(token){
                let base64Url = token.split('.')[1]
                let base64 = decodeURIComponent(
                    atob(base64Url)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                    })
                    .join('')
                    )
                const parsedToken = JSON.parse(base64)
                await api.get('users/get',parsedToken.id).then(response => {
                    if(response.status == 200){
                        commit('setUser',response.data.data)
                    }
                })
                return state.user
            }
        }
    }
}