<template>
  <div class="iframe iframeManufactoryTasks pa-3">
    <SocketWatch :tables="['manufactory']" @loadData="loadData" />
    <v-card>
       <v-card-title>
        Задания в очереди
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider />
      <v-skeleton-loader
        v-if="!tasksLoaded && (!tasks || tasks.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
      <div 
        v-if="tasksLoaded && (!tasks || tasks.length === 0)"
        class="pa-3 d-flex justify-center"
      >
        Нет заданий
      </div>
      <v-simple-table v-if="tasks && tasks.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дата</th>
              <th class="text-left">Клиент</th>
              <th class="text-left">Комментарий</th>
              <th class="text-left">Менеджер</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="task in tasks"
              :key="task.id"
              @click="editTask(task)"
              class="tableItemList"
            >
              <td style="min-width: 80px;">{{ $formatDate(new Date(task.date)) }}</td>
              <td style="min-width: 125px;">{{ task.client.name }}</td>
              <td>{{ task.comment }}</td>
              <td>{{ task.manager.displayName }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { manufactoryTaskStatus } from '@/helpers'
import ManufactoryTaskModal from  '@/components/Modals/Manufactory/Task'

  export default {
    name: 'MasterWorkTasks',
    data: () => ({
      skeletonTypesIframe: {
        'table-thead': 'heading@4',
        'table-row': 'table-cell@4'
      },
      manufactoryTaskStatus: manufactoryTaskStatus
    }),
    created() {
      if (!this.tasks || this.tasks.length === 0) {
        this.$store.dispatch('manufactory/loadTasksWorkForMaster', { id: this.user.id })
      }
    },
    computed: {
      tasksLoaded() {
        return this.$store.getters['manufactory/getTasksWorkForMasterLoaded']
      },
      tasks() {
        return this.$store.getters['manufactory/getTasksWorkForMaster']
      },
      user() {
        return this.$store.getters['user/getUser']
      }
    },
    methods: {

      loadData() { 
        this.$store.dispatch('manufactory/loadTasksWorkForMaster', { id: this.user.id })
        this.$store.dispatch('manufactory/loadTasksProducedForMaster', { id: this.user.id })
      },
      async createTask() {
        await this.$showModal({
          component: ManufactoryTaskModal,
          isPersistent: true,
          props: {
            edit: false
          }
        })
        this.loadData()
      },
      async editTask(task) {
        await this.$showModal({
          component: ManufactoryTaskModal,
          isPersistent: true,
          props: {
            edit: true,
            data: task
          }
        })
        this.loadData()
      }
      
    }
  }
</script>
