<template>
  <v-form ref="formNewTask" class="d-flex align-center" style="width: 100%;">
    <v-row>
      <v-col class="py-0">
        <v-text-field
          dense
          color="rgb(224, 166, 119)"
          v-model="title"
          :label="design ? 'Задача дизайнеру' : 'Задача от меня'"
          :rules="$fieldRules.field"
          @keyup.enter="save()"
          ref="title"
        />
      </v-col>
      <v-col cols="3" class="py-0">
        <v-select
          dense
          :value="whoTo"
          @change="changeWhoTo"
          :items="users"
          label="Для кого задача"
          :rules="$fieldRules.select"
          multiple
          item-value="uid"
          item-color="rgb(224, 166, 119)"
          ref="whoTo"
        >
          <template v-slot:selection="{ item, index }">
            <!-- <v-chip x-small v-if="index === 0">
              <span>{{ item.displayName }}</span>
            </v-chip> -->
						<my-mini-avatar
							v-if="index < 3"
							:user="item"
							:size="23"
						/>
            <span
              v-if="index === 3 && whoTo.length > 3"
              class="grey--text text-caption"
            >
              (+{{ whoTo.length - 3 }} чел.)
            </span>
          </template>
          <template v-slot:item="{ item, on, attrs }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }" dense>
              <v-list-item-avatar class="my-0">
                <my-mini-avatar :user="item"/>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-text="item.displayName" />
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox color="rgb(224, 166, 119)" :input-value="active"></v-checkbox>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col cols="2" class="py-0">
        <v-text-field
          dense
          color="rgb(224, 166, 119)"
          :value="deadlineText"
          @click="selectDate()"
          label="Дедлайн"
          clearable
          @click:clear="clearDate()"
          ref="deadline"
          :rules="$fieldRules.date"
        />
      </v-col>
    </v-row>
    <v-btn icon small class="ml-3" @click="save()">
      <v-icon size="22">mdi-keyboard-return</v-icon>
    </v-btn>
		<!-- <v-btn icon small class="ml-3" @click="newTask()">
      <v-icon size="22">mdi-plus</v-icon>
    </v-btn> -->
  </v-form>
</template>

<script>
import SelectDate from '@/components/Modals/SelectDate'
import MiniAvatar from '../../../MiniAvatar.vue'
import TaskModal from  '@/components/Modals/Task/Index'

export default {
  name: 'NewTask',
  components: { MiniAvatar },
  props: ['design'],
  data: () => ({
    deadline: null,
    title: '',
    whoTo: [],
    whoToData: {}
  }),
  methods: {
		newTask() {
			this.$showModal({
        component: TaskModal,
        isPersistent: true,
        props: {
          edit: false,
          design: this.design,
          data: null
        }
      })
		},
    async save() {
      const errors = []
      const validate = await this.$refs.formNewTask.validate()
      errors.push(validate)

      if (!errors.includes(false)) {
        let obj = {
          // from добавляется в экшене в сторе
          // accepted добавляется в экшене в сторе
          // del добавляется в экшене в сторе
          deadline: this.deadline,
          title: this.title,
          description: '',
          whoTo: this.whoTo,
          whoToData: this.whoToData,
          design: Boolean(this.design)
        }

        this.$store.dispatch('fromMeTasks/createTask', obj)

        // Надо бы проверять успешно ли добавилась задачи и только тогда чистить поля
        this.cleanFrom()
      }
    },
    cleanFrom() {
      this.deadline = null
      this.title = ''
      this.whoTo = []
      this.whoToData = {}
      this.$refs.formNewTask.reset()
    },
    changeWhoTo(val) {
      let newUid = val.filter(x => !this.whoTo.includes(x))
      newUid = newUid.length > 0 ? newUid[0] : null

      let user = this.users.find(x => x.uid === newUid)
      if (user) {
        this.whoToData[newUid] = {
          displayName: user.displayName,
          email: user.email,
          avatar: user.avatar || null,
          del: user.del,
          role: user.role
        }
      }

      let keys = Object.keys(this.whoToData)
      let delUid = keys.filter(x => !val.includes(x))
      delUid = delUid.length > 0 ? delUid[0] : null

      if (delUid) {
        delete this.whoToData[delUid]
      }

      this.whoTo = val
    },
    clearDate() {
      this.deadline = null
    },
    async selectDate() {
      let modalDate = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: 'Дедлайн',
          withTime: true,
          selectDate: new Date().toISOString().substr(0, 10)
        }
      })
      if (modalDate) {
        if (modalDate.date && modalDate.time) {
          this.deadline = new Date(`${modalDate.date}T${modalDate.time}`)
        } else {
          this.deadline = new Date(modalDate)
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    users() {
      if (this.design) {
        return this.$store.getters['users/getDesignerUsers']
      }
      return this.$store.getters['users/getOfficeUsers']
    },
    deadlineText() {
      if (this.deadline) {
        return new Date(this.deadline).toLocaleString('ru', {day: 'numeric', month: 'short', hour: 'numeric', minute: 'numeric' })
      }
      return ''
    }
  }
}
</script>