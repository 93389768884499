<template>
  <v-row justify="center">
    <v-dialog 
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="!isMobile ? '600px' : ''"
      transition="dialog-bottom-transition"
      :scrollable="!isMobile"
      :persistent="!isMobile"
			class="clientModal"
    >
      <v-card :tile="isMobile" min-height="400px">
        <v-toolbar v-if="isMobile" color="#e0a677" tile style="position: sticky; top: 0px; z-index: 20;">
          <v-tabs v-model="tab" color="white" class="clientModal_mobile_tabs">
						<v-tab
							v-for="item in tabs"
							:key="`tab_${item.tab}`"
						>
							{{ item.title }}
						</v-tab>
					</v-tabs>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title v-else>
          <v-tabs v-model="tab" color="#e0a677" class="clientModal_tabs" elevation="1">
						<v-tab
							v-for="item in tabs"
							:key="`tab_${item.tab}`"
						>
							{{ item.title }}
						</v-tab>
					</v-tabs>
          <v-spacer />
          <v-btn icon color="#e0a677" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
						<v-tabs-items v-model="tab">
							<v-tab-item :value="0" eager>
								<v-form ref="formMainInfoClient">
									<v-row>
										<v-col cols="12">
											<v-text-field
												color="#e0a677"
												v-model="form.name"
												label="Клиент*"
												:rules="$fieldRules.field"
												required
											/>
										</v-col>
										<v-col cols="12">
											<v-text-field
												color="#e0a677"
												v-model="form.path"
												label="Расположение макетов*"
												:rules="$fieldRules.field"
												required
											/>
										</v-col>
										<v-col cols="12">
											<v-textarea
												color="#e0a677"
												v-model="form.comment"
												label="Комментарий"
												rows="2"
											/>
										</v-col>
										<v-col cols="6">
											<v-autocomplete
												color="#e0a677"
												v-model="form.companyActivity"
												label="Вид деятельности*"
												:items="companyActivitys"
												:rules="$fieldRules.field"
												required
											/>
										</v-col>
										<v-col cols="6">
											<v-autocomplete
												color="#e0a677"
												v-model="form.whereFrom"
												label="Откуда пришёл*"
												:items="whereFromCompany"
												:rules="$fieldRules.field"
												required
											/>
										</v-col>
										<v-col cols="6">
											<v-select
												:disabled="currentUser.role != 'admin'"
												color="#e0a677"
												v-model="form.creator"
												label="Менеджер"
												:items="managers"
												item-text="displayName"
												return-object
											/>
										</v-col>
										<v-col
											cols="6"
											v-if="edit"
											class="d-flex align-center"
										>
											<span class="mr-1">Заказы: {{ data.orders ? data.orders.length : 0 }}</span>
											<span class="mr-1">Монтажи: {{ data.montages ? data.montages.length : 0 }}</span>
											<span>Производство: {{ data.manufactory ? data.manufactory.length : 0 }}</span>
										</v-col>
									</v-row>
								</v-form>
							</v-tab-item>
							<v-tab-item :value="1" eager>
								<Address
									:address="form.address"
									@addFieldAddress="addFieldAddress()"
									@changeAddress="changeAddress($event)"
									@removeFieldAddress="removeFieldAddress($event)"
								/>
							</v-tab-item>
							<v-tab-item :value="2" eager>
								<Contacts
									ref="contacts"
									:contacts="form.contacts"
									@newContact="newContact"
									@removeContact="removeContact"
									@setMainContact="setMainContact"
								/>
							</v-tab-item>
							<v-tab-item :value="3" eager>
								<v-row>
									<v-col>
										Юридическая информация
									</v-col>
								</v-row>
							</v-tab-item>
						</v-tabs-items>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" text @click="save()">
            <span v-if="edit">Сохранить</span>
            <span v-else>Создать</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { RoleText } from '@/helpers'
var cloneDeep = require('lodash.clonedeep');

import Contacts from './Contacts.vue'
import Address from './Address.vue'

export default {
	components: {
		Contacts,
		Address
	},
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => ({
    isMobile: isMobile,
    roleText: RoleText,
    dialog: true,
		tab: 0,
		tabs: [
			{ tab: 'mainInfo', title: 'Клиент' },
			{ tab: 'address', title: 'Адреса' },
			{ tab: 'contacts', title: 'Контакты' },
			{ tab: 'legalInfo', title: 'Юр. Инфо' }
		],
    errors: {
      email: []
    },
    form: {
      name: '',
      entity: '',
      path: '',
      address: [''],
			creator: null,
			comment: '',
			contacts: [],
			companyActivity: '',
			whereFrom: ''
    }
  }),
  async created() {
		if (!this.managers || this.managers.length === 0) {
      this.$store.dispatch('users/loadManagers')
    }

		// console.log(this.data)

    if (this.data && this.data.id) {
      let data = { ...this.data }
      this.form.name = data.name
      this.form.entity = data.entity
      this.form.address = data.address && data.address.length > 0 ? await cloneDeep(data.address) : ['']
      this.form.path = data.path
      this.form.creator = data.creatorData || null
      this.form.comment = data.comment || ''
      this.form.companyActivity = data.companyActivity || ''
      this.form.whereFrom = data.whereFrom || ''
      this.form.contacts = data.contacts && data.contacts.length > 0 ? await cloneDeep(data.contacts) : []
    } else if (this.data && this.data.name) { // при создании клиента из заказа
			let data = { ...this.data }
			this.form.name = data.name
		}

		if (!this.edit) {
			this.form.creator = this.currentUser
		}
  },
	computed: {
	currentUser() {
		return this.$store.getters['user/getUser']
	},
	managers() {
      return this.$store.getters['users/getManagers']
    },
	companyActivitys() {
		let items = this.$store.getters['settings/getCompanyActivitys']
		items = items.map(x => x.title)
		return items
	},
	whereFromCompany() {
		let items = this.$store.getters['settings/getWhereFromCompany']
		items = items.map(x => x.title)
		return items
	}
	},
  methods: {
		newContact(item) {
			if (this.form.contacts.length === 0) {
				this.form.contacts.push({ ...item, id: Date.now(), main: true })
			} else {
				this.form.contacts.push({ ...item, id: Date.now(), main: false })
			}
		},
		removeContact(item) {
			const findRemoveItem = this.form.contacts.findIndex(x => x.id === item.id)
			if (findRemoveItem != -1) {
				this.form.contacts.splice(findRemoveItem, 1)
			}

			const findMainItem = this.form.contacts.findIndex(x => x.main === true)
			if (findMainItem === -1 && this.form.contacts.length > 0) {
				this.form.contacts[0]['main'] = true
			}
		},
		setMainContact(data) {
			const { val, item } = data
			this.form.contacts = this.form.contacts.map((contact) => {
				let obj = { ...contact }
				obj.main = false
				if (obj.id === item.id && val) {
					obj.main = true
				}
				return obj
			})

			const findMainItem = this.form.contacts.findIndex(x => x.main === true)
			if (findMainItem === -1 && this.form.contacts.length > 0) {
				this.form.contacts[0]['main'] = true
			}
		},
		changeAddress(data) {
			let { index, val } = data
			this.$set(this.form.address, index, val)
			// console.log(this.form.address)
		},
    removeFieldAddress(index) {
      this.form.address.splice(index, 1)
    },
    addFieldAddress() {
      this.form.address.push("")
    },
    getChanges() {
      let obj = {}
      for (let key in this.form) {
       if (this.form[key] && (this.form[key] != this.data[key])) {
          obj[key] = this.form[key]
        }
      }
      return obj
    },
    async save() {
			let form = { ...this.form }

			// console.log(this.$refs)
			// проверка основной инфы
      const formMainInfoClient = await this.$refs.formMainInfoClient.validate()
			if (!formMainInfoClient) {
				this.tab = 0
				return
			}

			// проверка наличия контакта
			if (form.contacts && form.contacts.length === 0) {
				this.tab = 2
				const refContacts = await this.$refs.contacts
				if (refContacts && refContacts.$refs && refContacts.$refs.newContact) {
					await refContacts.$refs.newContact.validate()
				}
				return
			}

			// очистка массива с адресами от пустых значений
			if (form.address && form.address.length > 0) {
				form.address = form.address.filter(x => x !== '')
			}

			if (!this.edit) {
				let newClient = await this.$store.dispatch('clients/createClient', form)
				// console.log(newClient)
				this.close({
					id: newClient.id,
					data: form
				})
			} else {
				let changes = await this.getChanges()
				const res = await this.$store.dispatch('clients/updateClient', {
					id: this.data.id,
					data: changes
				})
	
				this.close({
					id: this.data.id,
					data: form
				})
			}
    },
    close(data = null) {
      this.dialog = false
      setTimeout(() => {
        this.$emit('close', data)
      }, 200)
    }
  }
}
</script>

<style lang="scss">
.clientModal {
	&_mobile {
		&_tabs {
			width: 92%;

			.v-slide-group__prev--disabled,
			.v-slide-group__next--disabled {
				display: none !important;
			}
		}
	}

	&_tabs {
		width: 90%;
	}
}
</style>