<template>
	<div class="iframe iframe_full pa-0">
		<SocketWatch :tables="['tasks']" @loadData="loadData" />
		<v-card>
			<v-card-title class="py-0 pt-3">
				<NewTask />
			</v-card-title>
			<v-divider />
			<v-skeleton-loader v-if="!tasksLoaded && (!tasks || tasks.length === 0)" class="pl-3 pr-3"
				type="table-thead, table-row-divider@3" :types="skeletonTypesIframe" />
			<div v-if="tasksLoaded && (!tasks || tasks.length === 0)" class="infoNoItems">
				Нет задач 4
			</div>
			<v-data-table v-if="tasks && tasks.length > 0" :headers="tableHeadres" :items="tasks" :items-per-page="15"
				:dense="!isMobile" :options="options" @update:options="$emit('updOptions', $event)"
				@pagination="$emit('updPagination', $event)" hide-default-footer>
				<template v-slot:item="{ item }">
					<tr :style="
							item.color && item.color[currentUser.id] ?
								 `background-color: ${item.color[currentUser.id]}` : ''" @click="openTask(item)">
						<td style="width: 47%;">
							{{ $sliceStr(item.title || item.description, 60) }}
						</td>
						<td>
							<span v-if="item.numberOrder">{{ item.numberOrder }}</span>
							<span v-else>Не указан</span>
						</td>
						<td>
							<div class="d-flex align-center">
								<div v-for="(whoTo, whoTo_index) in item.whoTo" :key="whoTo_index" class="mr-1">
									<my-mini-avatar v-if="whoTo_index < 3" :key="whoTo.id" :user="item.whoToData[whoTo_index]" />
									<span v-if="whoTo_index === 3 && item.whoTo.length > 3" class="grey--text text-caption">
										(+{{ item.whoTo.length - 3 }})
									</span>
								</div>
							</div>
						</td>
						<td>
							<span :style="
									`${$colorDeadline(new Date(item.deadline))}`">
								{{ $formatDateTime(new Date(item.deadline)) }}
							</span>
						</td>
						<td>
							<span>
								<template v-if="item && item.archive">В архиве</template>
								<template v-else-if="item && item.print">В печать</template>
								<template v-else-if="!item || !item.accepted">Ожидает</template>
								<template v-else-if="item && item.ready">Готово</template>
								<template v-else>В работе</template>
							</span>
						</td>
						<td class="text-center">
							<v-menu left>
								<template v-slot:activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" icon :disabled="disabledColorBtn(item)">
										<v-icon>mdi-format-color-fill</v-icon>
									</v-btn>
								</template>

								<div style="background-color: white;">
									<div class="pt-2 px-5">
										<v-btn v-if="item.color && item.color[currentUser.id]" :color="item.color[currentUser.id] || null"
											block @click="setColorItem('#FFF', item)">
											Сбросить цвет
										</v-btn>
									</div>
									<v-color-picker @input="setColorItem($event, item)"
										:value="item.color && item.color[currentUser.id] || null" hide-canvas hide-inputs show-swatches
										swatches-max-height="230" mode="hexa" class="rl-table-color-picker" />
								</div>
							</v-menu>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>
	</div>
</template>

<script>
	import {
		isMobile
	} from 'mobile-device-detect';
	import NewTask from '@/components/Tasks/Widgets/Components/NewTask'
	import TaskModal from '@/components/Modals/Task/Index'

	export default {
		name: 'MontagesUnassignedMontages',
		components: {
			NewTask
		},
		props: {
			options: {
				type: Object,
				default: null
			}
		},
		data: () => ({
			isMobile: isMobile,
			skeletonTypesIframe: {
				'table-thead': 'heading@4',
				'table-row': 'table-cell@4'
			}
		}),
		created() {
			this.loadData()
		},
		computed: {
			tableHeadres() {
				return [{
						text: 'Задача',
						align: 'start',
						sortable: false,
						value: 'title',
						width: '47%'
					},
					{
						text: 'Номер заказа',
						align: 'start',
						sortable: false,
						value: 'numberOrder',
					},
					{
						text: 'Кому',
						align: 'start',
						sortable: false,
						value: 'whoTo',
					},
					{
						text: 'Дедлайн',
						align: 'start',
						sortable: false,
						value: 'deadline',
					},
					{
						text: 'Статус',
						align: 'start',
						sortable: false,
						value: 'status',
					},
					{
						text: 'Цвет',
						align: 'center',
						sortable: false,
						value: 'color',
					},
				]
			},
			tasksLoaded() {
				return true
			},
			tasks() {
				return this.$store.getters['fromMeTasks/getTasks']
			},
			currentUser() {
				return this.$store.getters['user/getUser']
			},
		},
		methods: {
			loadData() {
				this.$store.dispatch('fromMeTasks/loadTasks')
			},
			async setColorItem(color, item) {
				if (color === '#FF0000FF' || (color && color.hexa === '#FF0000FF')) {
					color = null
				}
				color = color && color.hexa ? color.hexa : color
				let colorObj = {}
				if (item.color) {
					colorObj = {
						...item.color
					}
				}
				colorObj[this.currentUser.id] = color
				await this.$store.dispatch('tasks/updateTask', {
					id: item.id,
					data: {
						color: colorObj
					}
				})
			},
			disabledColorBtn(item) {
				let currentUserUid = this.currentUser.id
				let arr = [...item.whoTo]
				arr.push(item.from_id)
				if (arr.includes(currentUserUid)) {
					return false
				}
				return true
			},
			async openTask(task) {
				await this.$showModal({
					component: TaskModal,
					isPersistent: true,
					props: {
						edit: true,
						data: task
					}
				})
				this.$store.dispatch('fromMeTasks/loadTasks')
			}
		}
	}
</script>