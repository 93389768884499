import { tasksCollection, usersCollection } from '@/plugins/firebase'
import api from '@/utils/api'
import store from '@/stores/store.js'
function fDiffDays(date1, date2) {
  let diffTime = Math.abs(date2 - date1);
  let diffDay = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  if (date2.getTime() < date1.getTime()) {
    return -diffDay
  }
  return diffDay;
}
export default {
  namespaced: true,
  state: {
    tasks: [],
    designTasks: [],
    designTasksDeadline: [],
    limit: 0,
    offset: 0
  },
  getters: {
    getTasks: state => {
      const sortedTasks = state.tasks.sort(function(a,b){
        // return a.deadline.toDate() - b.deadline.toDate();
        return new Date(a.deadline) - new Date(b.deadline);
      })
      return sortedTasks
    },
    getDesignTasks: state => {
      const sortedTasks = state.designTasks.sort(function(a,b){
        return new Date(a.deadline) - new Date(b.deadline);
        // return a.deadline.toDate() - b.deadline.toDate();
      })
      return sortedTasks
    },
    getDesignTasksDeadline: state => {
      const sortedTasks = state.designTasksDeadline.sort(function(a,b){
        return new Date(a.deadline) - new Date(b.deadline);
        // return a.deadline.toDate() - b.deadline.toDate();
      }).filter((item)=>{
        return 0 >= fDiffDays(new Date, new Date(item.deadline))
      })
      return sortedTasks
    },
  },
  mutations: {
    setTasks(state, payload) {
      state.tasks = payload
    },
    setDesignTasks(state, payload) {
      state.designTasks = payload
    },
    setDesignTasksDeadline(state, payload) {
      state.designTasksDeadline = payload
    },

    addTask(state,payload){
      let tasks = state.tasks
      tasks.push(payload)
      state.tasks = tasks
    },
    SET_LIMIT(state, payload) {
      state.limit = payload
    },
    SET_OFFSET(state, payload) {
      state.offset = payload
    }
  },
  actions: {
    async loadDesignTasks({ commit, rootState,state }) {
      commit('templ/setLoading', true, { root: true })

      let user = rootState.user.user
      let queryStore = await api.post('tasks/get-list',{
        limit: state.limit,
        offset: state.offset,
        query_and: [
          {input: 'archive',value: false},
          {input: 'del',value: false},
          {input: 'from_id',value: user.id},
          {input: 'me_Task',value: false},
          {input: 'design',value: true}
        ]
      }).then(response => {
        if(response.status == 200){
          return response.data.data || []
        }
      })
      queryStore = queryStore.map(el => {
				// el.from = users.find(user => user.id == el.from)
				el.from_data? el.from = el.from_data : ''
				el.whoTo = el.who_to_data || []
				el.whoToData = el.who_to_data || []
				el.color? el.color = JSON.parse(el.color) : ''
				el.images? el.images = el.images.split(',') : ''
        el.whoTo = el.whoTo.map(whoTo => {
          whoTo = whoTo.id
          return whoTo
        })
				return el
			})
      commit('setDesignTasks', queryStore)
      commit('templ/setLoading', false, { root: true })
    },

    async loadDesignTasksDeadline({ commit, rootState,state }) {
      commit('templ/setLoading', true, { root: true })

      let user = rootState.user.user
      let queryStore = await api.post('tasks/get-list',{
        limit: state.limit,
        offset: state.offset,
        query_and: [
          {input: 'archive',value: false},
          {input: 'del',value: false},
          {input: 'from_id',value: user.id},
          {input: 'me_Task',value: false},
          {input: 'design',value: true}
        ]
      }).then(response => {
        if(response.status == 200){
          return response.data.data || []
        }
      })
      queryStore = queryStore.map(el => {
				// el.from = users.find(user => user.id == el.from)
				el.from_data? el.from = el.from_data : ''
				el.whoTo = el.who_to_data || []
				el.whoToData = el.who_to_data || []
				el.color? el.color = JSON.parse(el.color) : ''
				el.images? el.images = el.images.split(',') : ''
        el.whoTo = el.whoTo.map(whoTo => {
          whoTo = whoTo.id
          return whoTo
        })
				return el
			})
      commit('setDesignTasksDeadline', queryStore)
      commit('templ/setLoading', false, { root: true })
    },


    async loadTasks({ commit, rootState,state }) {
      // alert('FromMeTasks')
      commit('templ/setLoading', true, { root: true })

      // if (snapshots.loadTasks) {
      //   snapshots.loadTasks()
      //   snapshots.loadTasks = null
      // }

      let user = rootState.user.user
      // let fromRef = await usersCollection.doc(user.uid)
      let queryStore = await api.post('tasks/get-list',{
        limit: state.limit,
        offset: state.offset,
        query_and: [ 
          {input: 'archive', value: false}, 
          {input: 'del', value: false}, 
          {input: 'from_id', value: user.id},
          {input: 'me_Task', value: false},
          {input: 'design', value: false}
        ]
      }).then( response => {
        if(response.status == 200){
          return response.data.data || []
        }
      })
			queryStore = queryStore.map(el => {
				// el.from = users.find(user => user.id == el.from)
				el.from_data? el.from = el.from_data : ''
				el.who_to_data? el.whoTo = el.who_to_data: []
				el.who_to_data? el.whoToData = el.who_to_data: []
				el.color? el.color = JSON.parse(el.color) : ''
				el.images? el.images = el.images.split(',') : ''
        el.whoTo = el.whoTo.map(whoTo => {
          whoTo = whoTo.id
          return whoTo
        })
				// el.whoToData = users.find(user => user.id == el.whoToData);
				return el
			})
      commit('setTasks',queryStore)
      commit('templ/setLoading', false, { root: true })
      // let queryStore = tasksCollection.where('del', '!=', true)
      //   .where('archive', '==', false).where('from', '==', fromRef)
      //   .where('meTask', '==', false).where('design', '==', false)

      // snapshots.loadTasks = queryStore.onSnapshot(async snapshot => {
      //   let tasksArr = []

      //   for (let doc of snapshot.docs) {
      //     let task = { ...doc.data(), id: doc.id }
      //     tasksArr.push(task)
      //   }
      
      //   commit('setTasks', tasksArr)
      //   commit('templ/setLoading', false, { root: true })
      // })
    },
    async createTask({ commit, rootState, dispatch }, data) {
      commit('templ/setLoading', true, { root: true })
      let user = rootState.user.user
      // let fromRef = await usersCollection.doc(user.uid)
      console.log(user)
      const obj = { 
        ...data,
        // from: fromRef,
        // accepted: false,
        accepted: true,
        archive: false,
        del: false,
        meTask: false,
        ready: false,
				date: new Date(),
      }

			if (!obj.client) {
				obj.client = null
			}

			if (!obj.numberOrder) {
				let number = await dispatch('tasks/getNewNumberTask', null, { root: true })
				obj.numberOrder = `z${number}`
			}

      // await tasksCollection.add(obj)
      // console.log('fromMeTasks',obj)
      await api.post('tasks/create',{
        ...obj
      }).then(async response => {
        if(response.status == 200){
          console.log(response.data.tasks, 'response.data.tasks')
          response.data.tasks.from = response.data.tasks.from_data
          response.data.tasks.whoTo = response.data.tasks.who_to_data || []
          response.data.tasks.whoToData = response.data.tasks.who_to_data || []
          response.data.tasks.whoTo = response.data.tasks.whoTo.map(whoTo => {
            whoTo = whoTo.id
            return whoTo
          })

          if (obj.whoTo && obj.whoTo.length > 0) {
            for (let to of obj.whoTo) {
              await dispatch ('notify/notify', {
                to,
                title: `Задача от ${user.displayName}`,
                text: obj.title,
                action: 'newTask',
                payload: {
                  taskId:  response.data.tasks.id
                }
              }, {root: true})
            }
          } 

          dispatch('socket/sendSocket',['tasks'], { root: true })
          store.commit('tasks/addTask',response.data.tasks)
          commit('templ/setLoading', false, { root: true })
        }
      })

    }
  }
}