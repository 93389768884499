<template>
  <div class="page assignedMontages">
    <DateFilterAssignedMontages @updDate="updDate" />
    <SocketWatch :tables="['montages']" @loadData="loadData" />
    <!-- <div :class="{ 'iframeList': !isMobile }"> -->
    <div>
      <Skeleton v-if="!isMobile && !montagesLoaded && (!montages || montages.length === 0)" />
      <Skeleton v-if="!isMobile && !montagesLoaded && (!montages || montages.length === 0)" />
      <template v-if="!isMobile">
        <v-row no-gutters>
          <v-col class="pa-0 ma-0" v-for="(data, index_data) in montages" :key="index_data" cols="6">
            <AssignedMontagesInBrig :data="data" />
          </v-col>
        </v-row>
      </template>
      <template v-else class="mobAssignedMontages">
        <v-tabs
          v-model="tab"
          color="rgb(224, 166, 119)"
          background-color="rgb(224, 166, 119)"
          class="mobAssignedMontages_tabs"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="(data, index_data) in montages"
            :key="index_data"
          >
            {{ data.brig.displayName }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" style="background-color: #424242;">
          <v-tab-item
            v-for="(data, index_data) in montages"
            :key="index_data"
          >
            <AssignedMontagesInBrig :data="data" />
          </v-tab-item>
        </v-tabs-items>
      </template>
    </div>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import DateFilterAssignedMontages from '@/components/Montages/DateFilterAssignedMontages.vue'
import AssignedMontagesInBrig from '@/components/Montages/AssignedMontagesInBrig.vue'
import Skeleton from '@/components/Montages/Skeleton.vue'

export default {
  name: 'AssignedMontages',
  components: {
    DateFilterAssignedMontages,
    AssignedMontagesInBrig,
    Skeleton
  },
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    },
    tab: 0
  }),
  created() {
    this.loadData()
  },
  async beforeRouteLeave(to, from, next) {
    let toDay = new Date().toISOString().substr(0, 10)
    this.updDate(new Date(toDay))
    next()
  },
  methods: {
    loadData() {
      this.$store.dispatch('montages/loadAssignedMontages')
    },
    updDate(date) {
      this.filterMontagesByDate = date
      this.$store.dispatch('montages/loadAssignedMontages')
    }
  },
  computed: {
    montagesLoaded() {
      return this.$store.getters['montages/getAssignedMontagesLoaded']
    },
    montages() {
      return this.$store.getters['montages/getAssignedMontages']
    },
    filterMontagesByDate: {
      get() { return this.$store.getters['montages/getFilterAssignedMontagesByDate'] },
      set(val) { this.$store.commit('montages/setFilterAssignedMontagesByDate', val) }
    }
  }
}
</script>

<style lang="scss">
.mobAssignedMontages {
  .v-tabs-items {
    background-color: #424242 !important;
  }

  &_tabs {
    position: sticky;
    top: 56px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    z-index: 2;

    .v-slide-group__prev, .v-slide-group__next {
      display: none !important;
    }
  }
}
</style>
