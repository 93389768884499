import { Link } from 'element-tiptap';
import MenuButton from './MenuButton.vue'

export default class CustomLink extends Link {
  menuBtnView (editorContext) {
    return {
      component: MenuButton,
      componentProps: {
				editorContext
			}
    }
  }
}