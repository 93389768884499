export default {
  state: {
		// index
    filterOrdersByClient: null,
    filterOrdersByStatus: null,
    filterOrdersByManager: null,
    filterOrdersByMonth: null,
    filterOrdersByNumberOrder: null,
		// draft
    filterDraftOrdersByClient: null,
    filterDraftOrdersByManager: null,
    filterDraftOrdersByMonth: null,
		filterDraftOrdersByNumberOrder: null,
		// archive
    filterArchiveOrdersByClient: null,
    filterArchiveOrdersByStatus: null,
    filterArchiveOrdersByManager: null,
    filterArchiveOrdersByMonth: null,
		filterArchiveOrdersByNumberOrder: null,
  },
  getters: {
		// index
    getFilterOrdersByClient: state => {
      return state.filterOrdersByClient
    },
    getFilterOrdersByStatus: state => {
      return state.filterOrdersByStatus
    },
    getFilterOrdersByManager: state => {
      return state.filterOrdersByManager
    },
    getFilterOrdersByMonth: state => {
      return state.filterOrdersByMonth
    },
		getFilterOrdersByNumberOrder: state => {
      return state.filterOrdersByNumberOrder
    },
		// draft
		getFilterDraftOrdersByClient: state => {
      return state.filterDraftOrdersByClient
    },
    getFilterDraftOrdersByManager: state => {
      return state.filterDraftOrdersByManager
    },
    getFilterDraftOrdersByMonth: state => {
      return state.filterDraftOrdersByMonth
    },
		getFilterDraftOrdersByNumberOrder: state => {
      return state.filterDraftOrdersByNumberOrder
    },
		// archive
    getFilterArchiveOrdersByClient: state => {
      return state.filterArchiveOrdersByClient
    },
    getFilterArchiveOrdersByStatus: state => {
      return state.filterArchiveOrdersByStatus
    },
    getFilterArchiveOrdersByManager: state => {
      return state.filterArchiveOrdersByManager
    },
    getFilterArchiveOrdersByMonth: state => {
      return state.filterArchiveOrdersByMonth
    },
		getFilterArchiveOrdersByNumberOrder: state => {
      return state.filterArchiveOrdersByNumberOrder
    },
  },
  mutations: {
		// index
    resetAllFilterOrders(state) {
      state.filterOrdersByClient = null
      state.filterOrdersByStatus = null
      state.filterOrdersByManager = null
      state.filterOrdersByMonth = null
      state.filterOrdersByNumberOrder = null
    },
    setFilterOrdersByClient(state, payload) {
      state.filterOrdersByClient = payload;
    },
    setFilterOrdersByStatus(state, payload) {
      state.filterOrdersByStatus = payload;
    },
    setFilterOrdersByManager(state, payload) {
      state.filterOrdersByManager = payload;
    },
    setFilterOrdersByMonth(state, payload) {
      state.filterOrdersByMonth = payload;
    },
		setFilterOrdersByNumberOrder(state, payload) {
      state.filterOrdersByNumberOrder = payload;
    },
		// draft
    resetAllFilterDraftOrders(state) {
      state.filterDraftOrdersByClient = null
      state.filterDraftOrdersByManager = null
      state.filterDraftOrdersByMonth = null
      state.filterDraftOrdersByNumberOrder = null
    },
    setFilterDraftOrdersByClient(state, payload) {
      state.filterDraftOrdersByClient = payload;
    },
    setFilterDraftOrdersByManager(state, payload) {
      state.filterDraftOrdersByManager = payload;
    },
    setFilterDraftOrdersByMonth(state, payload) {
      state.filterDraftOrdersByMonth = payload;
    },
		setFilterDraftOrdersByNumberOrder(state, payload) {
      state.filterDraftOrdersByNumberOrder = payload;
    },
		// archive
    resetAllFilterArchiveOrders(state) {
      state.filterArchiveOrdersByClient = null
      state.filterArchiveOrdersByStatus = null
      state.filterArchiveOrdersByManager = null
      state.filterArchiveOrdersByMonth = null
      state.filterArchiveOrdersByNumberOrder = null
    },
    setFilterArchiveOrdersByClient(state, payload) {
      state.filterArchiveOrdersByClient = payload;
    },
    setFilterArchiveOrdersByStatus(state, payload) {
      state.filterArchiveOrdersByStatus = payload;
    },
    setFilterArchiveOrdersByManager(state, payload) {
      state.filterArchiveOrdersByManager = payload;
    },
    setFilterArchiveOrdersByMonth(state, payload) {
      state.filterArchiveOrdersByMonth = payload;
    },
		setFilterArchiveOrdersByNumberOrder(state, payload) {
      state.filterArchiveOrdersByNumberOrder = payload;
    },
  }
}