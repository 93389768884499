<template>
  <div class="page user pa-3">
    <v-card class="pb-3" v-if="user">

      <Header 
        :user="user"
        :currentUser="currentUser"
      />

      <v-row class="px-3 pt-1">
        <!-- Левая колонка -->
        <v-col cols="6">
          <v-col v-if="user.role === 'master'" cols="12" class="pa-0 pb-5">
            <Montages :user="user" />
          </v-col>
          <v-col v-if="user.role === 'master'" cols="12" class="pa-0 pb-5">
            <Tasks :user="user" />
          </v-col>
          <v-col v-if="user.role === 'prodDir'" cols="12" class="pa-0 pb-5">
            <HourlyWork :user="user" />
          </v-col>
					<v-col v-if="['manager', 'admin'].includes(user.role)" cols="12" class="pa-0 pb-5">
            <Orders :user="user" />
          </v-col>
        </v-col>
        
        <!-- Правая колонка -->
        <v-col cols="6">
          <v-col 
						v-if="!['prodDir', 'manager', 'admin', 'designer', 'admin'].includes(user.role)"
						cols="12" class="pa-0 pb-5"
					>
            <HourlyWork :user="user" />
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-row>
              <v-col class="py-0 pb-5" cols="12" xl="6" md="6">
                <Prepayments
                  :user="user"
                  :currentUser="currentUser"
                />
              </v-col>

              <v-col class="py-0 pb-5" cols="12" xl="6" md="6">
                <Deductions
                  :user="user"
                  :currentUser="currentUser"
                />
              </v-col>

              <v-col class="py-0 pb-5" cols="12" xl="6" md="6">
                <Premiums
                  :user="user"
                  :currentUser="currentUser"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Header from './Components/Header/Index.vue'

import Montages from './Components/Pc/Montages.vue'
import Tasks from './Components/Pc/Tasks.vue'
import HourlyWork from './Components/Pc/HourlyWork.vue'
import Orders from './Components/Pc/Orders.vue'

import Prepayments from './Components/Pc/Prepayments.vue'
import Deductions from './Components/Pc/Deductions.vue'
import Premiums from './Components/Pc/Premiums.vue'
import api from '@/utils/api'
export default {
  name: 'User',
  components: {
    Header,
    Montages,
    Tasks,
    HourlyWork,
    Prepayments,
    Deductions,
    Premiums,
		Orders
  },
  methods: {},
  computed: {
    uid() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser.id
      }
      return this.$route.params.uid
    },
    user() {
      if (this.currentUser && this.currentUser.role != 'admin') {
        return this.currentUser
      }
      return this.$store.getters['users/getFetchedUser']
      // return this.fetchedUser
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    currentUser() {
      return this.$store.getters['user/getUser']
    }
  },
  async created(){
    if(this.currentUser.role == 'admin'){
      await api.get('users/get',this.$route.params.uid).then(response => {
        if( response.status == 200){
          console.log('fetchedUser',this.$route.params)
          this.$store.commit('users/setFetchedUser',response.data.data)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.costRed {
  color: red;
}
.user {
  &_info {
    display: flex;
    justify-content: space-between;

    &_right {
      display: flex;
      align-items: center;

      &_credit {
        &_text {
          font-size: 1.4em;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0.57em;
        }

        &_btn {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  &_selectDataMonth {
    .v-text-field__details {
      display: none;
    }
  }
}
</style>
