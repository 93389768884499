<template>
  <div class="iframe pa-3">
    <v-card>
      <div class="px-3 py-3">
        <v-skeleton-loader style="max-width: 120px;" type="text"/>
      </div>
      <v-divider />
      <v-skeleton-loader
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@3"
        :types="skeletonTypesIframe"
      />
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'SkeletonMontages',
  data: () => ({
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    }
  })
}
</script>
