<template>
  <div>
    <v-data-table
           v-if="table"
            :headers="[{
               
                align: 'start',
                sortable: false,
                value: 'name',
              },]"
            :items="filterPositions"
           
           
           
            dense
          >
            <template v-slot:[`item.name`]="{ item }">
              <v-list-item  :key="item.id">
        <v-list-item-content >
          <!-- <pre>{{ item }}</pre> -->
          <v-list-item-title>
            {{ item.unit.split('----')[1] }}
          </v-list-item-title>
          <v-list-item-title>
            {{ users[item.who_id] ? users[item.who_id].displayName : "" }}
            {{ item.type === "plus" ? "добавил"  : "списал"}}
            {{ users[item.whomId] ? users[item.whomId].displayName : "" }} 
            <template v-if="item.type === 'minus'">
              {{ item.internal ? "для внутреннего использования" : item.orderNumber ? `для заказа № ${item.orderNumber}` : ''}}
            </template>
          </v-list-item-title>
          <v-list-item-subtitle style="white-space: normal" v-if="item.comment">{{item.comment}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text >
            {{ new Date(item.date).toLocaleDateString()}} 
          </v-list-item-action-text>
          {{ item.type === "plus" ? "+" : "-"}}  {{ item.count }} {{ item.unit.split('----')[0] }}
        </v-list-item-action>
      </v-list-item>
            </template>
          </v-data-table>
  
    <v-list v-else dense two-line class="my-3">
      <template  v-for="(position) in filterPositions" >
      <v-list-item  :key="position.id">
        <v-list-item-content>
          <!-- <pre>{{ position }}</pre> -->
          <v-list-item-title>
            {{ position.unit.split('----')[1] }}
          </v-list-item-title>
          <v-list-item-title>
            {{ users[position.who_id] ? users[position.who_id].displayName : "" }}
            {{ position.type === "plus" ? "добавил"  : "списал"}}
            {{ users[position.whomId] ? users[position.whomId].displayName : "" }} 
            <template v-if="position.type === 'minus'">
              {{ position.internal ? "для внутреннего использования" : position.orderNumber ? `для заказа № ${position.orderNumber}` : ''}}
            </template>
          </v-list-item-title>
          <v-list-item-subtitle style="white-space: normal" v-if="position.comment">{{position.comment}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-list-item-action-text >
            {{ new Date(position.date).toLocaleDateString()}} 
          </v-list-item-action-text>
          {{ position.type === "plus" ? "+" : "-"}}  {{ position.count }} {{ position.unit.split('----')[0] }}
        </v-list-item-action>
      </v-list-item>
      <v-divider />
      </template>
    </v-list>
  </div>
</template>

<script>
  
  import {
    mapActions,
    mapState,
    mapGetters
  } from 'vuex'
  export default {
    props: {
      product: Object,
      type: { type: String, default:""},
      table: Boolean,
    },
    
    created() {
      this.loadData()
    },

    data: () => ({
      expanded: [],
      count: 0
    }),

    watch: {
      product(value) {
        if (value) this.loadData()
      }
    },

    computed: {
      ...mapState('warehouse', [
        'selectProduct',
        'positions'
      ]),
      ...mapGetters('warehouse', ['categorysObj']),
      user() {
        return this.$store.getters['user/getUser']
      },
      users() {
        return this.$store.getters['users/getUsersObj']
      },
      filterPositions() {
        if (this.type) {
          return this.positions.filter(position => position.type === this.type)
        }
        return this.positions
      }
    },
    methods: {
      ...mapActions('warehouse', [
        'setSelectedProduct',
        'loadPositions',
      ]),
      async loadData() {
        if (this.product) {
          this.setSelectedProduct(this.product)
        } else {
          this.setSelectedProduct(null)
        }
        this.loadPositions()
      },
      async editProduct(item) {
        const product = await this.$showModal({
					component: ModalProduct,
					isPersistent: false,
					props: {
            edit: true,
						data: item
					}
				})

				if (product) {
					this.updateProduct({...item, ...product})
				}
      },

      async addProduct() {
        const product = await this.$showModal({
					component: ModalProduct,
					isPersistent: false,
					props: {
						data: {
              category_id: this.selectedCategory ? this.selectedCategory.id : null
            }
					}
				})

				if (product) {
					this.createProduct(product)
				}
      },

      async delProduct(item) {
        this.deleteProduct(item)
      },

      async countPlusProduct(item) {
        const res = await this.$showModal({
					component: ModalAddOrWriteOff,
					isPersistent: false,
					props: {
            who_id: this.user.id,
            type: 'plus',
						data: item
					}
				})
				if (res) {
					this.updateCountProduct(res)
				}
      },

      async countMinusProduct(item) {
        const res = await this.$showModal({
					component: ModalAddOrWriteOff,
					isPersistent: false,
					props: {
            who_id: this.user.id,
            type: 'minus',
						data: item
					}
				})
				if (res) {
					this.updateCountProduct(res)
				}
      }
    }
  }
</script>

<style>
  .pages-warehouse {

  }
</style>