import { render, staticRenderFns } from "./PcPage.vue?vue&type=template&id=698bc0d7&scoped=true&"
import script from "./PcPage.vue?vue&type=script&lang=js&"
export * from "./PcPage.vue?vue&type=script&lang=js&"
import style0 from "./PcPage.vue?vue&type=style&index=0&id=698bc0d7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "698bc0d7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VCard,VSimpleTable})
