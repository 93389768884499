export default {
  state: {
		ordersLoaded: false,
		draftOrdersLoaded: false,
		archiveOrdersLoaded: false,
  },
  getters: {
	  getOrdersLoaded: state => {
		  return state.ordersLoaded
    },
		getDraftOrdersLoaded: state => {
		  return state.draftOrdersLoaded
    },
		getArchiveOrdersLoaded: state => {
		  return state.archiveOrdersLoaded
    }
  },
  mutations: {
    setOrdersLoaded(state, payload) {
		  state.ordersLoaded = payload
    },
		setDraftOrdersLoaded(state, payload) {
		  state.draftOrdersLoaded = payload
    },
		setArchiveOrdersLoaded(state, payload) {
		  state.archiveOrdersLoaded = payload
    }
  }
}