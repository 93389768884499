import  uniqid  from 'uniqid';
let socket

export default {
  namespaced: true,
  state: {
    init: false,
    message: [],
    id: null,
  },
  getters: {
    message: (state) => state.message,
    init: (state) => state.init
  },
  mutations: {
    setInit(state, payload) {
      state.init = payload;
    },
    setMessage(state, payload) {
      state.message = payload;
    },
    setID(state, payload) {
      state.id = payload;
    }
  },
  actions: {
    async socketInit({ commit, dispatch, state }) {
      if (state.init) return
      socket = new WebSocket(`wss://socket.m4crm.ru/ws/${localStorage.getItem('jwt')}`)

      socket.onopen = async () => {
        commit('setInit', true)
        commit('setID', uniqid.time("socket-"))
      }

      socket.onerror = event => {
        commit('setInit', false)
      }

      socket.onmessage = async ({ data }) => {
        const { id, message } = JSON.parse(data)
        if (id !== state.id) {
          commit('setMessage', message)
        } else {
          commit('setMessage', message)
        }
      }

      socket.onclose = event => {
        commit('setInit', false)
        setTimeout(() => dispatch('socketInit'), 1000)
      }
    },
    sendSocket({ state }, message) {
      socket.send(JSON.stringify({ id: state.id, message }))
    }

  },

  closeSocket() {
    socket.close()
  }
}