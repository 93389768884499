<template>
  <div :class="{ 'iframe iframe_full': !isMobile }">
    <v-card :tile="isMobile">
      <v-card-title v-if="!isMobile && title">
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn v-if="create" small icon @click="createTask()">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-skeleton-loader
        v-if="!tasksLoaded && (!tasks || tasks.length === 0)"
        class="pl-3 pr-3"
        type="table-thead, table-row-divider@2"
        :types="skeletonTypesIframe"
      />
      <div 
        v-if="tasksLoaded && (!tasks || tasks.length === 0)"
        class="infoNoItems"
      >
        Нет заданий
      </div>
      <v-simple-table :dense="!isMobile" v-if="tasks && tasks.length > 0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Дедлайн</th>
              <th class="text-left">Клиент</th>
              <th class="text-left">Номер</th>
              <th class="text-left">Комментарий</th>
              <th class="text-left">Тех. задание</th>
              <th class="text-left">Статус</th>
              <th v-if="user.role === 'admin'" class="text-left">Стоимость</th>
              <th class="text-left">Менеджер</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="task in tasks"
              :key="task.id"
              @click="editTask(task)"
              class="tableItemList"
            >
              <td style="width: 5vw;">{{ $formatDate(new Date(task.date)) }}</td>
              <td style="width: 8vw;">
								{{ task.client.name }}
								<!-- <v-menu bottom right :close-on-content-click="false">
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon x-small v-bind="attrs" v-on="on" style="cursor: context-menu;">
											<v-icon>mdi-information-outline</v-icon>
										</v-btn>
									</template>
									<my-info-client :id="task.client.id" />
								</v-menu> -->
							</td>
							<td style="width: 3vw;">
								<span v-if="task.numberOrder">{{ task.numberOrder }}</span>
								<span v-else>Без заказа</span>
							</td>
              <td style="width: 18vw;">
                <span v-if="task.comment">
                  {{ $sliceStr(task.comment, 60) }}
                </span>
                <span v-else>Нет комментария</span>
              </td>
              <td style="width: 10vw;">
                <a v-if="task.techTask" @click.stop="goUrl(task.techTask)" href="#">Открыть</a>
                <span v-else>Нет тех. задания</span>
              </td>
              <td style="width: 10vw;">{{ manufactoryTaskStatus[task.status] }}</td>
              <td v-if="user.role === 'admin'" style="width: 6vw;">
                <span v-if="task.costForProd">{{ task.costForProd }}р.</span>
                <span v-else>Не указана</span>
              </td>
              <td style="width: 7vw;">{{ task.manager.displayName }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import { manufactoryTaskStatus, manufactoryTaskStatusArr } from '@/helpers'
import ManufactoryTasksModal from  '@/components/Modals/Manufactory/Task'

export default {
  name: 'TaskTableComp',
  props: {
    title: {
      type: String,
      default: ''
    },
    tasksLoaded: {
      type: Boolean,
      default: false
    },
    tasks: {
      type: Array,
      default: []
    },
    create: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    },
    manufactoryTaskStatus: manufactoryTaskStatus,
    manufactoryTaskStatusArr: manufactoryTaskStatusArr
  }),
  methods: {
    goUrl(url) {
      window.open(url, '_blank').focus();
    },
    async createTask() {
      await this.$showModal({
        component: ManufactoryTasksModal,
        isPersistent: true,
        props: {
          edit: false
        }
      })
    },
    async editTask(task) {
      await this.$showModal({
        component: ManufactoryTasksModal,
        isPersistent: true,
        props: {
          edit: true,
          data: task
        }
      })
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    }
  }
}
</script>