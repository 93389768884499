<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Добавить изображение с устройства</span>
          <v-spacer />
          <v-btn icon color="rgb(224, 166, 119)" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formAddImageByUrl">
              <v-row>
                <v-col cols="12">
                  <v-file-input
										v-model="file"
										accept="image/*"
										color="rgb(224, 166, 119)"
										prepend-icon="mdi-camera"
										label="Выберите изображение"
										:rules="$fieldRules.field"
                    required
										:loading="uploadingImage"
									/>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn 
						:disabled="!file || uploadingImage"
						color="orange"
						text
						@click="save()"
					>
            <span>Добавить</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import imageCompression from 'browser-image-compression'
import api from "@/utils/api"

const fileUrl = process.env.VUE_APP_FILE_URL

export default {
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => ({
    dialog: true,
    errors: {},
		file: null,
		uploadingImage: false,
    form: {
      url: ''
    },
  }),
  methods: {
		async uploadImage(file) {
      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight: 1520,
        useWebWorker: true
      }

			if (file && file instanceof File) {
				this.uploadingImage = true
				let compressedFile = await imageCompression(file, options)
				// let filePath = `faq/${new Date().getTime()}_${0}.${/[^.]+$/.exec(file.name)}`
				// let ref = await this.$storage.ref().child(filePath).put(compressedFile)
        this.uploadingImage = true
        let filePath = await api.upload('upload',compressedFile).then(response => {
          if(response.status == 200){
            this.uploadingImage = false
            return `${fileUrl}/${response.data.url}`
          }
        })
				this.uploadingImage = false
        return filePath
				// if (ref.state && ref.state === 'success') {
				// 	return filePath
				// } else {
				// 	alert('Ошибка загрузки')
				// }
			}
    },
    async save() {
			// кнопка save задизейблена пока не добавишь файл
			let path = await this.uploadImage(this.file)
			this.form.url = path

      const errors = []
      const validate = await this.$refs.formAddImageByUrl.validate()
      errors.push(validate)

      if (!errors.includes(false)) {
				this.$emit('close', this.form)
      }
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.$emit('close')
      }, 200)
    }
  }
}
</script>
