export default {
  state: {
    tasksLoaded: false,
    confirmationTasksLoaded: false,
    managerTasksLoaded: false,
    tasksWorkForMasterLoaded: false,
    tasksProducedForMasterLoaded: false,
    tasksForMasterLoaded: false,
    inProcessTasksLoaded: false
  },
  getters: { 
	  getTasksLoaded: state => {
		  return state.tasksLoaded
    },
    getConfirmationTasksLoaded: state => {
		  return state.confirmationTasksLoaded
    },
    getManagerTasksLoaded: state => {
		  return state.managerTasksLoaded
    },
    getTasksWorkForMasterLoaded: state => {
		  return state.tasksWorkForMasterLoaded
    },
    getTasksProducedForMasterLoaded: state => {
		  return state.tasksProducedForMasterLoaded
    },
    getTasksForMasterLoaded: state => {
		  return state.tasksForMasterLoaded
    },
    getInProcessTasksLoaded: state => {
		  return state.inProcessTasksLoaded
    },
  },
  mutations: {
    setTasksLoaded(state, payload) {
      state.tasksLoaded = payload
    },
    setConfirmationTasksLoaded(state, payload) {
      state.confirmationTasksLoaded = payload
    },
    setManagerTasksLoaded(state, payload) {
      state.managerTasksLoaded = payload
    },
    setTasksWorkForMasterLoaded(state, payload) {
      state.tasksWorkForMasterLoaded = payload
    },
    setTasksProducedForMasterLoaded(state, payload) {
      state.tasksProducedForMasterLoaded = payload
    },
    setTasksForMasterLoaded(state, payload) {
      state.tasksForMasterLoaded = payload
    },
    setInProcessTasksLoaded(state, payload) {
      state.inProcessTasksLoaded = payload
    },
  }
}