<template>
	<div class="pa-3 fill-height" style="display: block;">
		<v-card style="min-height: 4em;">
			<Materials
				v-if="selectedCategory"
				:key="selectedCategory.id"
				:selectedCategory="selectedCategory"
				@newMessage="messageFromMaterials = $event"
			/>
			<v-container
				v-if="message"
				class="d-flex justify-center py-4"
			>
				<div style="color: grey; font-size: 1.2em; line-height: 1.5em;">
					{{ message }}
				</div>
			</v-container>
		</v-card>
	</div>
</template>

<script>
import Materials from './Materials.vue'
export default {
	components: {
		Materials
	},
	props: ['selectedCategory'],
	data: () => ({
		messageFromMaterials: ''
	}),
	computed: {
		message() {
			if (!this.selectedCategory) {
				return 'Выберите категорию'
			} else if (this.messageFromMaterials) {
				return this.messageFromMaterials
			}
			return null
		},
		currentUser() {
			return this.$store.getters['user/getUser']
		}
	}
}
</script>