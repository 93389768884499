<template>
  <v-card-text v-if="montage.id" class="grey lighten-3">
    <v-container grid-list-md>
      <v-layout wrap>
          <v-flex xs12>
            <span class="name_field">Что делать:</span>
            <p class="content_task">
              <ul style="padding: 0;">
                <li 
                  v-for="(task, index_task) in montage.tasks"
                  :key="index_task"
                  style="list-style-type: none;padding-left: 9px;"
                >
                  <span style="display: inline-block;margin-left: -9px;"><v-icon style="font-size: 10px;vertical-align: initial;">mdi-adjust</v-icon></span>
                  <div style="display: inline-grid;">
                      <span 
                        v-for="(str, index_str) in task.value.split(/\n|\s\n/)"
                        :key="index_str"
                        style="display: inline-block;padding-left:5px;"
                      >
                        {{str}}<br>
                      </span>
                  </div>
                </li>
              </ul>
            </p>
          </v-flex>

          <v-flex xs12 v-if="montage.comment.length != 0">
            <span class="name_field">Взять ссобой:</span>
            <p class="content_task">{{montage.comment}}</p>
          </v-flex>

          <v-flex xs12>
            <span class="name_field">Менеджер:</span> {{montage.manager.displayName}}
          </v-flex>

          <v-flex xs12>
            <span class="name_field">Монтажники: </span>
            <span v-for="(montagers, index_montager) in montage.montagersInfo" :key="index_montager">{{ montagers.name }}<span v-if="index_montager != Object.keys(montage.montagersInfo)[Object.keys(montage.montagersInfo).length - 1]">, </span>
            </span>
          </v-flex>
          
          <v-flex xs12 v-if="!getHourPayWorkMasters">
            <span class="name_field">Стоимость:</span>
            <span v-if="montage.montagersInfo[user.uid] && montage.montagersInfo[user.uid].costMontage">
              {{montage.montagersInfo[user.uid].costMontage}}р.
            </span>
          </v-flex>
      </v-layout>
    </v-container>

    <v-expansion-panels v-if="montage.images && montage.images.length > 0">
      <v-expansion-panel>
        <v-expansion-panel-header>
          Фото
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-carousel
            v-model="activImg"
            :show-arrows="montage.images.length > 1"
            height="280"
            style="position: relative;"
            hide-delimiters
          >
            <template v-for="(item,i) in montage.images" >
              <div
                :key="`full_${i}`"
                style="position: absolute; top: 0px; right: 10px; z-index: 1;"
                class="d-flex justify-end pa-1"
              >
                <v-icon @click="$showImageMob(images[activImg], images)" size="40" color="red">mdi-fullscreen</v-icon>
              </div>
              <v-carousel-item :key="i">
                  <template v-slot:default>
                    <div style="height: 100%;" class="d-flex align-center justify-center">
                      <v-img
                        :src="images[i]"
                        max-height="280"
                        class="grey lighten-2"
                        contain
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                  </template>
                </v-carousel-item>
            </template>
          </v-carousel>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <Comments
      ref="comments"
      :item_id="montage.id"
      :item="montage"
		/>
  </v-card-text>
</template>

<script>
import Comments from './Comments/Index.vue'
export default {
  props: {
    montage: {
      type: Object,
      default: {}
    }
  },
  components: {
    Comments,
  },
  data: () => ({
    images: [],
    activImg: 0
  }),
  watch: {
    montage: {
      deep: true,
      immediate: true,
      async handler(newValue) {
        if (newValue.images.length > this.images.length) {
          // let images = []
          // if (this.montage.images && this.montage.images.length > 0) {
          //   for (let key in this.montage.images) {
          //     // let imgUrl = await this.$getFbFileUrl(this.montage.images[key])
          //     images.push(this.montage.images[key])
          //   }
          // }
          this.images = newValue.images
        }
      }
    }
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser']
    },
    getHourPayWorkMasters() {
      return this.$store.getters['settings/getHourPayWorkMasters']
    }
  }
}
</script>
