<template>
	<v-alert
		v-model="show"
		colored-border
		color="#e0a677"
		border="left"
		width="400"
		elevation="6"
		transition="slide-x-transition"
		@click="$openModalFromNotif(item)"
	>
		<v-list-item-title
			style="white-space: normal;"
			v-text="item.title"
			class="mb-1"
		/>
		<v-list-item-subtitle
			style="white-space: normal;"
			v-text="$sliceStr(item.text, 100)"
		/>
	</v-alert>
</template>

<script>
import { mapActions } from 'vuex'
export default {
	props: ['item'],
	data: () => ({
		show: true
	}),
	created() {
		setTimeout(() => {
			this.show = false
			this.showAlertNotify({ ...this.item, showAlert: false })
		}, 3200)
	},
	methods: {
		...mapActions('notify', ['showAlertNotify']),
	}
}
</script>