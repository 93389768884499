export default {
  state: {
		// index
    filterTasksByNumberOrder: null,
    filterTasksByStatus: null,
    filterTasksByManager: null,
    filterTasksByDesigner: null,
    filterTasksByMonth:  null,
		filterTasksByClient: null,
		// archive
		filterArchiveTasksByNumberOrder: null,
    filterArchiveTasksByManager: null,
    filterArchiveTasksByMonth:  null,
    filterArchiveTasksByTypeTask:  null,
    filterArchiveTasksByClient:  null,
		filterArchiveTasksByDesigner: null
  },
  getters: {
		// index
    getFilterTasksByNumberOrder: state => {
      return state.filterTasksByNumberOrder
    },
    getFilterTasksByStatus: state => {
      return state.filterTasksByStatus
    },
    getFilterTasksByManager: state => {
      return state.filterTasksByManager
    },
		getFilterTasksByDesigner: state => {
      return state.filterTasksByDesigner
    },
    getFilterTasksByMonth: state => {
      return state.filterTasksByMonth
    },
		getFilterTasksByClient: state => {
      return state.filterTasksByClient
    },
		// archive
    getFilterArchiveTasksByNumberOrder: state => {
      return state.filterArchiveTasksByNumberOrder
    },
    getFilterArchiveTasksByManager: state => {
      return state.filterArchiveTasksByManager
    },
    getFilterArchiveTasksByMonth: state => {
      return state.filterArchiveTasksByMonth
    },
		getFilterArchiveTasksByTypeTask: state => {
      return state.filterArchiveTasksByTypeTask
    },
		getFilterArchiveTasksByClient: state => {
      return state.filterArchiveTasksByClient
    },
		getFilterArchiveTasksByDesigner: state => {
      return state.filterArchiveTasksByDesigner
    },
  },
  mutations: {
		// index
    resetAllFilterTasks(state) {
      state.filterTasksByNumberOrder = null
      state.filterTasksByStatus = null
      state.filterTasksByManager = null
      state.filterTasksByDesigner= null
      state.filterTasksByMonth = null
      state.filterTasksByClient = null
    },
    setFilterTasksByNumberOrder(state, payload) {
      state.filterTasksByNumberOrder = payload;
    },
    setFilterTasksByStatus(state, payload) {
      state.filterTasksByStatus = payload;
    },
    setFilterTasksByManager(state, payload) {
      state.filterTasksByManager = payload;
    },
		setFilterTasksByDesigner(state, payload) {
      state.filterTasksByDesigner = payload;
    },
    setFilterTasksByMonth(state, payload) {
      state.filterTasksByMonth = payload;
    },
		setFilterTasksByClient(state, payload) {
      state.filterTasksByClient = payload;
    },
		// archive
    resetAllFilterArchiveTasks(state) {
      state.filterArchiveTasksByNumberOrder = null
      state.filterArchiveTasksByManager = null
      state.filterArchiveTasksByMonth = null
      state.filterArchiveTasksByTypeTask = null
      state.filterArchiveTasksByClient = null
      state.filterArchiveTasksByDesigner = null
    },
    setFilterArchiveTasksByNumberOrder(state, payload) {
      state.filterArchiveTasksByNumberOrder = payload;
    },
    setFilterArchiveTasksByManager(state, payload) {
      state.filterArchiveTasksByManager = payload;
    },
    setFilterArchiveTasksByMonth(state, payload) {
      state.filterArchiveTasksByMonth = payload;
    },
		setFilterArchiveTasksByTypeTask(state, payload) {
      state.filterArchiveTasksByTypeTask= payload;
    },
		setFilterArchiveTasksByClient(state, payload) {
      state.filterArchiveTasksByClient = payload;
    },
		setFilterArchiveTasksByDesigner(state, payload) {
      state.filterArchiveTasksByDesigner = payload;
    },
  }
}