<template>
  <div>
    <SocketWatch :tables="['manufactory']" @loadData="loadData" />
    <MobPage v-if="isMobile" />
    <PcPage v-else />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
const MobPage = () => import('./MobPage.vue')
const PcPage = () => import('./PcPage.vue')

export default {
  name: 'ManufactoryArchiveTasksForMaster',
  components: {
    MobPage,
    PcPage
  },
  data: () => ({
    isMobile: isMobile
  }),
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$store.dispatch('manufactory/loadTasksArchiveForMaster', { id: this.user.id })
    }
  },
  computed: {
    
    tasks() {
      return this.$store.getters['manufactory/getTasksArchiveForMaster']
    },
    user() {
      return this.$store.getters['user/getUser']
    }
  }
}
</script>
