<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" width="290" persistent>
        <v-card class="selectDate" width="290">
          <v-card-title class="selectDate_header" v-if="title">
            <span>
              {{ title }}<span v-if="withTime && stringDeadline && stringDeadline.length > 0">: </span>
              <span v-if="withTime">{{ stringDeadline }}</span>
            </span>
          </v-card-title>

          <v-date-picker v-if="!goToTime" class="selectDate_datePicker" no-title first-day-of-week="1" v-model="date" locale="ru" color="rgb(224, 166, 119)" />
					<div v-if="goToTime" class="pt-4 px-6 pb-2">
						<v-text-field
							v-model="time"
							type="time"
							label="Введите время"
							color="rgb(224, 166, 119)"
						></v-text-field>
					</div>
          <!-- <v-time-picker v-if="goToTime" @click:hour="timeSetHour" @click:minute="timeSetMin" format="24hr" no-title locale="ru" color="rgb(224, 166, 119)" /> -->

          <v-card-actions>
            <v-btn color="orange" text @click="close()">Отмена</v-btn>
            <v-spacer />
            <v-btn color="blue" :disabled="withTime && goToTime && (!time || (time.length < 5 || time.includes('-')))" text @click="withTime && !goToTime ? goToTime = true : go()">Продолжить</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    withTime: {
      type: Boolean,
      default: false
    },
    selectDate: {
      type: String,
      default: null
    },
  },
  data: () => ({
    dialog: true,
    date: new Date().toISOString().substr(0, 10),
		time: '17:30',
    hour: '',
    min: '',
    goToTime: false
  }),
  created() {
    if (this.selectDate) {
      this.date = this.selectDate
    }
  },
  computed: {
    // time() {
    //   return `${this.hour ? this.hour : '--'}:${this.min ? this.min : '--'}`
    // },
    stringDeadline() {
      let str = ''
      if (this.date) {
        let date = new Date(this.date)
        str = `${date.toLocaleString('ru', {day: 'numeric', month: 'short' })}`
      }
      if (this.withTime) {
        if (str.length > 0) {
          str = `${str} ${this.time}`
        } else {
          str = `${this.time}`
        }
      }
      return str
    }
  },
  methods: {
    timeSetHour(val) {
      if (val != undefined) {
        if (String(val).length > 1) {
          this.hour = `${val}`
        } else {
          this.hour = `0${val}`
        }
      }
    },
    timeSetMin(val) {
      if (val != undefined) {
        if (String(val).length > 1) {
          this.min = `${val}`
        } else {
          this.min = `0${val}`
        }
      }
    },
    go() {
      if (this.withTime) {
        this.$emit('close', {
          date: this.date,
          time: this.time
        })
      } else {
        this.$emit('close', this.date)
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.selectDate {
  &_header {
    font-size: 1.2em !important;
  }

  &_datePicker {
    .v-date-picker-table {
      height: 100%;
    }
  }
}
</style>