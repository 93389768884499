<template>
	<div>
		<v-card dark>
			<div class="row px-3 ma-0 pa-0">
				<div class="col-md-3 col-6">
					<v-text-field dense color="rgb(224, 166, 119)" v-model="filterUserDataByMonth" @change="loadData"
						@click="selectMonth()" label="Месяц" clearable />
				</div>
				<div class="col-md-3 col-6" v-if="currentUser.role === 'admin'">
					<v-autocomplete dense color="rgb(224, 166, 119)" v-model="filterOrdersByManager" @change="loadData"
						label="Менеджер" :items="managers" item-text="displayName" return-object clearable />
				</div>
			</div>
		</v-card>
		<StateBlockEarned :user="user" />
	</div>
</template>



<script>
import StateBlockEarned from "./StateBlockEarned.vue"
import SelectMonth from '@/components/Modals/SelectMonth'
export default {
	name: 'User',

	components: {
		StateBlockEarned
	},
	data() {
		return {
			filterOrdersByManager: null,
		}
	},
	async created() {
		this.loadData()
	},
	async beforeRouteLeave(to, from, next) {
		this.setDefVal()
		next()
	},
	methods: {
		async loadData() {
			this.$store.dispatch('users/loadManagers')
			this.$store.dispatch('orders/loadOrdersForManager', { id: this.uid, month: this.filterUserDataByMonth })
			this.$store.dispatch('orders/loadOrdersForManagerAll', { id: this.uid, month: this.filterUserDataByMonth })
			const response = await this.$store.dispatch('users/getCurUser', this.uid)
			const user = response.data.data
			this.$store.commit('orders/setOrders', user.orders)
			this.$store.commit('montages/setMontages', user.montages)
			this.$store.dispatch('hourlyWorks/loadTasks', { id: this.uid, month: this.filterUserDataByMonth })
			this.$store.dispatch('prepayments/loadPrepayments', { id: this.uid, month: this.filterUserDataByMonth })
			this.$store.dispatch('premiums/loadPremiums', { id: this.uid, month: this.filterUserDataByMonth })
			this.$store.dispatch('deductions/loadDeductions', { id: this.uid, month: this.filterUserDataByMonth })
			this.$store.dispatch('montages/loadMontagesForMontager', { id: this.uid, month: this.filterUserDataByMonth })
			this.$store.dispatch('manufactory/loadTasksForMaster', { id: this.uid, month: this.filterUserDataByMonth })
		},

		async selectMonth() {
			let modalMonth = await this.$showModal({
				component: SelectMonth,
				isPersistent: true,
				props: {
					title: 'Выберите месяц',
					selectMonth: this.filterUserDataByMonth
				}
			})
			if (modalMonth) {
				this.filterUserDataByMonth = modalMonth
				this.loadData()
			}
		},
		setDefVal() {
			this.$store.commit('orders/setOrdersForManagerAll', [])
			this.$store.commit('orders/setOrdersForManager', [])
			this.$store.commit('montages/setMontagesForMontager', [])
			this.$store.commit('manufactory/setTasksForMaster', [])
			this.$store.commit('hourlyWorks/setTasks', [])
			this.$store.commit('prepayments/setPrepayments', [])
			this.$store.commit('deductions/setDeductions', [])
			this.$store.commit('premiums/setPremiums', [])
		}
	},
	computed: {
		filterUserDataByMonth: {
			get() {
				return this.$store.getters['users/getFilterUserDataByMonth']
			},
			set(value) {
				this.$store.commit('users/setFilterUserDataByMonth', value)
			}
		},

		uid() {
			if (this.filterOrdersByManager) {
				return this.filterOrdersByManager.id
			}
			if (this.currentUser && this.currentUser.role != 'admin') {
				return this.currentUser.id
			}
			return this.$route.params.uid
		},
		user() {
			return this.$store.getters['users/getCurUser']
		},
		users() {
			return this.$store.getters['users/getUsers']
		},
		managers() {
			return this.$store.getters['users/getManagers']
		},
		currentUser() {
			return this.$store.getters['user/getUser']
		}
	},
	watch: {
		filterUserDataByMonth(newVal, oldVal) {
			this.loadData()
		}
	}
}
</script>


