<template>
  <div class="iframeList">
    <MasterWorkTasks />
    <MasterProducedTasks />
  </div>
</template>

<script>
import MasterWorkTasks from './Components/MasterWorkTasks.vue'
import MasterProducedTasks from './Components/MasterProducedTasks.vue'

export default {
  name: 'ManagerHome',
  components: {
    MasterWorkTasks,
    MasterProducedTasks
  }
}
</script>
