<template>
  <!-- <v-card>
    <pre>init: {{ init  }}</pre>
    <div>message: {{ message }}</div>
    <div>tables: {{ tables }}</div>
    <v-btn @click="sendSocket(test)">send Socket</v-btn>
  </v-card> -->
</template>

<script>
let socket
import { difference } from 'lodash'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SocketWatch',
  props: {
    tables: {
      type: Array,
      default: () => []
    },
    test :{
      type: Array,
      default: () => ['all']
    },
  },

  data: () => ({
    load: false,
  }),
  
  methods: {
    ...mapActions('socket', ['sendSocket' ])
  },
 
  computed: {
    ...mapGetters('socket', ['init', 'message'])
  },
  watch: {
    message(value) {
      if (difference(this.tables, ['all', ...value]).length !== this.tables.length) {
        this.$emit('loadData')
      }
    },
  }
}
</script>