<template>
	<v-row>
		<v-col cols="12" class="py-0">
			<v-form ref="address">
				<div v-for="(address, address_index) in address" :key="address_index" class="d-flex">
					<v-textarea
						color="#e0a677"
						:value="address"
						:label="'Адрес ' + (address_index+1)"
						rows="2"
						@input="$emit('changeAddress', { index: address_index, val: $event })"
					/>
					<div style="padding-bottom: 19px;margin-left: 5px;">
						<div 
							style="
								height: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								flex-wrap: wrap;
								width: 35px;
								background: #ebebeb;
							"
						>
							<v-btn icon color="blue" @click="$emit('addFieldAddress')">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
							<v-btn
								v-if="address.length > 1"
								icon
								color="red"
								@click="$emit('removeFieldAddress', address_index)"
							>
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>
					</div>
				</div>
			</v-form>
		</v-col>
	</v-row>
</template>

<script>
export default {
	props: ['address']
}
</script>