import firebase from "firebase/app";
import 'firebase/firebase-messaging'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'

var firebaseConfig = {
    apiKey: process.env.VUE_APP_FB_APIKEY,
    authDomain: process.env.VUE_APP_FB_AUTHDOMAIN,
    projectId: process.env.VUE_APP_FB_PROJECTID,
    storageBucket: process.env.VUE_APP_FB_STORAGEBUCKET,
    messagingSenderId: process.env.VUE_APP_FB_MESSAGINGSENDERID,
    appId: process.env.VUE_APP_FB_APPID
}

const fb = firebase.initializeApp(firebaseConfig)
const db = fb.firestore()
const auth = fb.auth()
const func = fb.functions("europe-west2")
const storage = fb.storage()
const fcm = firebase.messaging.isSupported() ? fb.messaging() : null

const metaInfoCollection = db.collection('metaInfo')
const montagesCollection = db.collection('montages')
const usersCollection = db.collection('users')
const brigsCollection = db.collection('brigs')
const locationsCollection = db.collection('locations')
const clientsCollection = db.collection('clients')
const manufactoryCollection = db.collection('manufactory')
const prepaymentsCollection = db.collection('prepayments')
const deductionsCollection = db.collection('deductions')
const premiumsCollection = db.collection('premiums')
const hourlyWorksCollection = db.collection('hourlyWorks')
const tasksCollection = db.collection('tasks')
const ordersCollection = db.collection('orders')
const faqCategoriesCollection = db.collection('faq-categories')
const faqMaterialsCollection = db.collection('faq-materials')

var now = new Date();
var millisTill10 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 7, 50, 0, 0) - now;
if (millisTill10 < 0) {
    millisTill10 += 86400000;
}
setTimeout(() => {
    location.reload()
}, millisTill10)

export {
    fb,
    db,
    auth,
    func,
    storage,
    fcm,
    metaInfoCollection,
    montagesCollection,
    usersCollection,
    brigsCollection,
    locationsCollection,
    clientsCollection,
    manufactoryCollection,
    prepaymentsCollection,
    deductionsCollection,
    premiumsCollection,
    hourlyWorksCollection,
    tasksCollection,
		ordersCollection,
		faqCategoriesCollection,
		faqMaterialsCollection
}
