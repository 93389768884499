<template>
  <v-card>
    <v-card-title class="userTableTitle">
      <div style="font-weight: 400;">
        Завершённые заказы
        <span v-if="costOrders">{{ costOrders }}</span>
      </div>
      <v-spacer />
			<v-data-footer
				v-if="options && pagination"
				:options.sync="options"
				:pagination="pagination"
				items-per-page-text=""
				:items-per-page-options="[5, 10, 15, 20, 30, 40]"
				style="width: 310px; padding: 0;"
				class="rl-data-footer"
			/>
    </v-card-title>
    <v-divider />
    <div v-if="!orders || orders.length === 0" class="infoNoItems">
      Нет заказов
    </div>
		<v-data-table
			v-if="orders && orders.length > 0"
			:headers="tableHeadres"
			:items="orders"
			:items-per-page="10"
			:dense="!$isMobile"
			:options="options"
			@click:row="editOrder($event)"
			@update:options="options = $event"
			@pagination="pagination = $event"
			hide-default-footer
		>
			<template v-slot:[`item.archiveDate`]="{ item }">
				{{ $formatDate(new Date(item.archive_date)) }}
			</template>
			<template v-slot:[`item.client`]="{ item }">
				<span v-if="item.client">{{ item.client.name }}</span>
				<span v-else>Не указан</span>
			</template>
			<template v-slot:[`item.cost`]="{ item }">
				{{ fullCost(item) }}р.
			</template>
			<template v-slot:[`item.percent`]="{ item }">
				{{ item.percentOfOrder || 0 }}%
			</template>
			<template v-slot:[`item.amount-work`]="{ item }">
				{{ (fullCost(item) * item.percentOfOrder || 0) / 100 }}р
			</template>
		</v-data-table>
  </v-card>
</template>

<script>
import OrderModal from  '@/components/Modals/Order/Index'

export default {
  props: {
    user: {
      type: Object,
      default: {}
    }
  },
	data: () => ({
		options: null,
		pagination: null
  }),
	methods: {
		fullCost(order) {
			let summ = 0
			order.works.forEach(work => {
				summ = summ + Number(work.cost)
			});
			return summ
		},
		async editOrder(order) {
      await this.$showModal({
        component: OrderModal,
        isPersistent: true,
        props: {
          edit: true,
          data: order
        }
      })
    }
	},
  computed: {
		tableHeadres() {
			return [
				{
					text: 'Дата заверш.',
					align: 'start',
					sortable: false,
					value: 'archiveDate'
				},
				{
					text: 'Номер',
					align: 'start',
					sortable: false,
					value: 'number'
				},
				{
					text: 'Клиент',
					align: 'start',
					sortable: false,
					value: 'client'
				},
				{
					text: 'Стоимость',
					align: 'start',
					sortable: false,
					value: 'cost'
				},
				{
					text: 'Процент',
					align: 'start',
					sortable: false,
					value: 'percent'
				},
				{
					text: 'Заработал',
					align: 'start',
					sortable: false,
					value: 'amount-work'
				}
			]
		},
    orders() {
      return this.$store.getters['orders/getOrdersForManager']
		// return this.user.orders || []
	},
    costOrders() {
      if (!this.orders || this.orders.length === 0) {
        return false
      }
      let price = 0
      this.orders.map(order => {
				let fullCost = this.fullCost(order)
				let percent = order.percentOfOrder || 0
				price = price + Number((fullCost * percent) / 100)
      })
      return `${price}р.`
    }
  }
}
</script>