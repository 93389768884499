var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pages-warehouse"},[_c('SocketWatch',{attrs:{"tables":['categorys', 'products']},on:{"loadData":_vm.loadData}}),_c('v-row',{staticClass:"ma-0 px-1 py-2"},[_c('v-col',{staticClass:"pa-0 px-4 mb-4",attrs:{"md":"3","cols":"12"}},[_c('Menu')],1),_c('v-col',{staticClass:"pa-0 px-4",attrs:{"md":"9","cols":"12"}},[_c('v-card',{attrs:{"tile":_vm.$isMobile}},[(_vm.user.role === 'admin' && _vm.selectedCategory)?_c('div',{staticClass:"d-flex align-center px-4 py-4"},[(_vm.user.role === 'admin' && _vm.selectedCategory)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){_vm.sort = !_vm.sort}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-sort")])],1):_vm._e(),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.addProduct()}}},[_c('v-icon',{attrs:{"size":"21"}},[_vm._v("mdi-plus")])],1)],1):_vm._e(),(!_vm.selectedCategory)?_c('div',{staticClass:"px-4 pt-4"},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"pa-0 ",attrs:{"cols":"4"}},[_c('v-select',{attrs:{"color":"rgb(224, 166, 119)","label":"Тип","items":[{
                      label: 'Списания',
                      value: 'minus'
                    },
                    {
                      label: 'Поступления',
                      value: 'plus'
                    }
                  ],"item-text":"label","clearable":""},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)],1):_vm._e(),_c('v-divider'),(!_vm.selectedCategory)?_c('Positions',{attrs:{"table":"","type":_vm.type}}):[(_vm.sort)?_c('draggable',_vm._b({staticClass:"tableMontage",on:{"start":function($event){_vm.drag = true},"end":function($event){_vm.drag = false}},model:{value:(_vm.productsList),callback:function ($$v) {_vm.productsList=$$v},expression:"productsList"}},'draggable',_vm.dragOptions,false),[_c('transition-group',{attrs:{"type":"transition","name":"flip-list"}},_vm._l((_vm.productsList),function(item){return _c('v-list-item',{key:item.id},[_c('v-list-item-icon',{staticClass:"my-3"},[_c('v-icon',[_vm._v("mdi-drag")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(item.name))])],1)],1)}),1)],1):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"single-expand":"","expanded":_vm.expanded,"show-expand":"","dense":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.category_id",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.categorysObj[item.category_id] ? _vm.categorysObj[item.category_id].name : 'Без категории')+" ")]}},{key:"item.count",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.count)+" "+_vm._s(item.unit)+" ")]}},{key:"item.comment",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.comment)+" ")]}},{key:"item.action",fn:function(ref){
                  var item = ref.item;
return [(_vm.user.role !== 'manager')?[(['admin'].includes(_vm.user.role) || _vm.user.warehouseManager)?_c('v-btn',{attrs:{"x-small":"","outlined":""},on:{"click":function($event){return _vm.countPlusProduct(item)}}},[_vm._v(" Добавить ")]):_vm._e(),(['admin'].includes(_vm.user.role) || _vm.user.warehouseManager)?_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","outlined":"","color":"primary"},on:{"click":function($event){return _vm.countMinusProduct(item)}}},[_vm._v(" Списать ")]):_vm._e(),(_vm.user.role === 'admin')?_c('v-btn',{attrs:{"small":"","icon":"","color":"#424242"},on:{"click":function($event){return _vm.editProduct(item)}}},[_c('v-icon',{attrs:{"size":"18"}},[_vm._v("mdi-pencil")])],1):_vm._e()]:_vm._e()]}},{key:"expanded-item",fn:function(ref){
                  var item = ref.item;
                  var headers = ref.headers;
return [(_vm.expanded.length && _vm.expanded[0].id === item.id)?_c('td',{attrs:{"colspan":headers.length}},[_c('Positions',{attrs:{"product":item}})],1):_vm._e()]}}],null,true)})]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }