<template>
  <div style="height: 100%;">
    <SocketWatch :tables="['hourlyWorks']" @loadData="loadData" />
    <MobPage v-if="isMobile" />
    <PcPage v-else />
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
const MobPage = () => import('./MobPage.vue')
const PcPage = () => import('./PcPage.vue')

export default {
  components: {
    MobPage,
    PcPage
  },
  data: () => ({
    isMobile: isMobile
  }),
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$store.dispatch('hourlyWorks/loadTasks', { id: this.user.id, month: this.filterHourlyWorksByMonth })
    }
  },
  computed: {
    hourlyWorks() {
      return this.$store.getters['hourlyWorks/getTasks']
    },
    filterHourlyWorksByMonth() {
      return this.$store.getters['hourlyWorks/getFilterHourlyWorksByMonth']
    },
    user() {
      return this.$store.getters['user/getUser']
    }
  },
  watch: {
    filterHourlyWorksByMonth(val) {
      if (val) {
        this.$store.dispatch('hourlyWorks/loadTasks', { id: this.user.id, month: this.filterHourlyWorksByMonth })
      }
    }
  }
}
</script>
