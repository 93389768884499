<template>
  <v-hover v-slot="{ hover }">
    <v-card flat class="mx-auto">
      <v-img
        :src="path"
        aspect-ratio="1.2"
        class="grey lighten-2"
      >
      
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
        <div
          v-if="hover"
          class="d-flex previewImage"
        >
          <v-btn icon color="grey" @click="isMobile ? $showImageMob(path, images) : $showImage(path, images)">
            <v-icon color="grey" class="pr-1">mdi-magnify-plus-outline</v-icon>
          </v-btn>
          <v-btn  icon color="grey" @click="downloadImage(path)">
            <v-icon color="grey" class="pl-1">mdi-download</v-icon>
          </v-btn>
          <v-btn v-if="!disTrach" icon color="#bf4037" @click="$emit('delete', path)">
            <v-icon color="#bf4037" class="pl-1">mdi-trash-can-outline</v-icon>
          </v-btn>
          
          
        </div>
      </v-img>
    </v-card>
  </v-hover>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import downloadjs from 'downloadjs';

export default {
  props: {
    path: {
      default: ''
    },
    images: {
      type: Array,
      default: null
    },
    disTrach: {
      default: 'false'
    }
  },
  data: () => ({
    isMobile: isMobile
  }),
  methods: {
    downloadImage(url) {
      // files.forEach(url => {
        // /get-file/
        downloadjs(url.replace("//", "/get-file/"))
      // });
    },
  }
}
</script>