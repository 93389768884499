export default {
  state: {
      montagesLoaded: false,
      unassignedMontagesLoaded: false,
      managerMontagesLoaded: false,
      assignedMontagesLoaded: false,
  },
  getters: {
	  getMontagesLoaded: state => {
		  return state.montagesLoaded
    },
    getUnassignedMontagesLoaded: state => {
		  return state.unassignedMontagesLoaded
    },
    getManagerMontagesLoaded: state => {
		  return state.managerMontagesLoaded
    },
    getAssignedMontagesLoaded: state => {
		  return state.assignedMontagesLoaded
    }
  },
  mutations: {
    setMontagesLoaded(state, payload) {
		  state.montagesLoaded = payload
    },
    setUnassignedMontagesLoaded(state, payload) {
		  state.unassignedMontagesLoaded = payload
    },
    setManagerMontagesLoaded(state, payload) {
      state.managerMontagesLoaded = payload;
    },
    setAssignedMontagesLoaded(state, payload) {
		  state.assignedMontagesLoaded = payload
    }
  }
}