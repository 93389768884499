export default {
    namespaced: true,
    state: {
        drawer: true,
        miniDrawer: false,
        error: null,
        loading: false,
        routes: []
    },
    getters: {
        getDrawer: state => {
            return state.drawer
        },
        getMiniDrawer: state => {
            return state.miniDrawer
        },
        getError: state => {
            return state.error
        },
        getLoading: state => {
            return state.loading
        },
        getRoutes: state => {
            return state.routes
        }
    },
    mutations: {
        setDrawer(state, payload) {
            state.drawer = payload;
        },
        setMiniDrawer(state, payload) {
            state.miniDrawer = payload;
        },
        setError(state, payload) {
            state.error = payload
        },
        setLoading(state, payload) {
            state.loading = payload
        },
        setRoutes(state, payload) {
            state.routes = payload
        }
    },
}