<template>
  <v-row justify="center">
    <v-dialog 
      v-model="dialog"
      :fullscreen="mob"
      :transition="mob ? 'dialog-bottom-transition' : ''"
      :scrollable="!mob"
      :width="mob ? '' : 290"
      :persistent="!mob"
    >
        <v-card class="selectPerformers" :width="mob ? '' : 290">
          <template v-if="mob">
            <v-toolbar color="rgb(224, 166, 119)">
              <v-toolbar-title class="white--text">Выберите помошника</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="close()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-subheader v-if="!getHourPayWorkMasters">Максимальная стоимость: {{ costForProd }}р.</v-subheader>
            <v-subheader
              v-if="!validCost"
              style="height: auto; color: red; line-height: 20px;"
            >
              Указанная стоимость превышает максимальную!
            </v-subheader>
          </template>

          <v-card-title v-else>
            <span style="width: 100%;">Выберите помошника</span>
            <v-subheader v-if="!getHourPayWorkMasters" style="height: auto;" class="pa-0">Максимальная стоимость: {{ costForProd }}р.</v-subheader>
            <v-subheader
              v-if="!validCost"
              style="height: auto; color: red; line-height: 20px;"
              class="pa-0"
            >
              Указанная стоимость превышает максимальную!
            </v-subheader>
          </v-card-title>

          <v-card-text 
            :style="mob ? '' : 'max-height: 300px;'"
            :class="mob ? 'px-4' : ''"
          >
            <v-radio-group :key="updateKey" column label="Мастера:" class="selectPerformers_radio ma-0 mt-4 pa-0">
              <template v-for="(montager, index_montager) in users">
                <div class="mb-2" :key="index_montager">
                  <v-checkbox
                    v-model="montagers"
                    :disabled="montager.id === user.id"
                    @change="changeMontagers(montager)"
                    :key="`check_${index_montager}`"
                    color="rgb(224, 166, 119)"
                    :label="`${montager.displayName}${montager.brig ? ` (${montager.brig.displayName})` : ''}`"
                    :value="montager.id"
                    class="pa-0 ma-0"
                    :style="`${mob ? 'margin-left: -2px !important;' : ''} ${montagersInfo[montager.id] && montagersInfo[montager.id].boss ? 'font-weight: bold;' : '' }`"
                  />
                  <v-text-field
                    v-if="!getHourPayWorkMasters && montagers.includes(montager.id)"
                    :key="`cost_${index_montager}`"
                    v-model="montagersInfo[montager.id].costWork"
                    :placeholder="`Стоимость для ${montager.displayName}`"
                    @input="changeMontagerCost(montager.id, $event)"
                    type="number"
                    suffix="р."
                    class="pa-0"
                    color="rgb(224, 166, 119)"
                  />
                </div>
              </template>
            </v-radio-group>
          </v-card-text>

          <v-card-actions v-if="mob">
            <v-spacer />
            <v-btn :disabled="!validCost" color="orange" text @click="go()">Сохранить</v-btn>
            <v-spacer />
          </v-card-actions>
          
          <v-card-actions v-else>
            <v-btn color="orange" text @click="close()">Отмена</v-btn>
            <v-spacer />
            <v-btn :disabled="!validCost" color="blue" text @click="go()">Продолжить</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { RoleText } from '@/helpers'

export default {
  props: {
    task: {
      type: Object,
      default: null
    },
    mob: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    roleText: RoleText,
    dialog: true,
    data: {},
    costForProd: 0,
    montagers: [],
    montagersInfo: {},
    updateKey: 0,
  }),
  created() {
    if (this.task && this.task.montagers) {
      this.data = JSON.parse(JSON.stringify({
        costForProd: this.task.costForProd || 0,
        montagers: this.task.montagers,
        montagersInfo: this.task.montagersInfo
      }))
      this.costForProd = this.data.costForProd
      // this.montagers = this.data.montagers ? [ ...this.data.montagers ] : []
      this.montagers = this.data.montagers ? this.data.montagers.split(',').map(el => {
        el = Number(el)
        return el
      }) : []
      this.montagersInfo = this.data.montagersInfo ? { ...this.data.montagersInfo } : {}
      console.log('this.data',this.montagers)
    }
  },
  computed: {
    users() {
      return this.$store.getters['users/getWorkers']
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    cost() {
      let montagers = this.montagersInfo
      let cost = 0
      for (let key in montagers) {
        let obj = montagers[key]
        if (obj && obj.costWork) {
          cost = cost + Number(obj.costWork)
        }
      }
      return cost
    },
    validCost() {
      if (this.getHourPayWorkMasters) {
        return true
      } else {
        if (this.costForProd) {
          let maxCost = Number(this.costForProd)
          if (this.cost > maxCost) {
            return false
          }
          return true
        }
      }
      return false
    },
    getHourPayWorkMasters() {
      return this.$store.getters['settings/getHourPayWorkMasters']
    }
  },
  methods: {
    changeMontagerCost(id, cost) {
      this.montagersInfo[id] = { ...this.montagersInfo[id], costWork: cost}
      this.montagersInfo = { ...this.montagersInfo }
    },
    changeMontagers(montager) {
      let id = montager.id
      let name = montager.displayName
      if (!this.montagers.includes(id) && this.montagersInfo[id]) {
        let boss = this.montagersInfo[id].boss ? true : false
        delete this.montagersInfo[id]
        if (boss && this.montagers.length > 0) {
          this.montagersInfo[this.montagers[0]] = { ...this.montagersInfo[this.montagers[0]], boss: true }
        }
      } else if (this.montagers.includes(id)) {
        let cost = this.data && this.costForProd ? this.costForProd - this.cost : 0
        if (this.montagersInfo[id]) {
          this.montagersInfo[id] = { ...this.montagersInfo[id], name: name, costWork: cost }
        } else {
          console.log(this.montagers.length)
          if (this.montagers.length === 1) {
            this.montagersInfo[id] = { name: name, costWork: cost, boss: true }
          } else {
            this.montagersInfo[id] = { name: name, costWork: cost }
          }
        }
      }
      console.log(this.montagersInfo)
    },
    go() {
      let brig = null
      this.$emit('close', { 
        montagers: this.montagers,
        montagersInfo: this.montagersInfo
      })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
.selectPerformers {
  &_radio {
    .v-input--checkbox {
      .v-messages {
        display: none;
      }
    }
    .v-input__slot {
      margin: 0;
    }
    .v-text-field__details {
      display: none;
    }
  }
}
</style>