<template>
  <div :class="{ 'pa-3 pb-0': !isMobile }">
    <v-card :tile="isMobile">
      <div v-if="isMobile" class="mobDateFilter">
        <div class="mobDateFilter_date">
          {{ filterMontagesByDateText }}
        </div>
        <div class="mobDateFilter_nav">
          <div class="mobDateFilter_nav_left">
            <v-btn 
              @click="filterDate('before')"
              icon
              color="black"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </div>
          <div class="mobDateFilter_nav_center">
            <v-btn 
              @click="filterDate('yesterday')"
              :color="yesterdayDate === filterMontagesByDateNumber ? 'rgb(224, 166, 119)' : 'black'" 
              small
              text
            >
              Вчера
            </v-btn>
            <v-btn
              @click="filterDate('today')"
              :color="todayDate === filterMontagesByDateNumber ? 'rgb(224, 166, 119)' : 'black'"
              small
              text
            >
              Сегодня
            </v-btn>
            <v-btn
              :color="tomorrowDate === filterMontagesByDateNumber ? 'rgb(224, 166, 119)' : 'black'"
              @click="filterDate('tomorrow')"
              small
              text
            >
              Завтра
            </v-btn>
          </div>
          <div class="mobDateFilter_nav_right">
            <v-btn
              @click="filterDate('next')"
              icon
              color="black"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <v-layout v-else>
        <div class="d-flex">
          <div class="px-1 d-flex align-center">
            <v-icon
              size="22"
              color="black"
              @click="filterDate('before')"
              dark
            >mdi-chevron-left</v-icon>
          </div>
          <div class="px-1 d-flex align-center">
            <v-btn
              small
              :color="yesterdayDate === filterMontagesByDateNumber ? 'rgb(224, 166, 119)' : null" 
              @click="filterDate('yesterday')"
              text
            >
              Вчера
            </v-btn>
          </div>
          <div class="px-1 d-flex align-center">
            <v-btn
              small
              :color="todayDate === filterMontagesByDateNumber ? 'rgb(224, 166, 119)' : null"
              @click="filterDate('today')"
              text
            >
              Сегодня
            </v-btn>
          </div>
          <div class="px-1 d-flex align-center">
            <v-btn
              small
              :color="tomorrowDate === filterMontagesByDateNumber ? 'rgb(224, 166, 119)' : null"
              @click="filterDate('tomorrow')"
              text
            >
              Завтра
            </v-btn>
          </div>
          <!-- <div class="px-1 d-flex align-center">
            <v-btn
              small
              :color="afterTomorrow === filterMontagesByDateNumber ? 'rgb(224, 166, 119)' : null"
              @click="filterDate('afterTomorrow')"
              text
            >
            Послезавтра
            </v-btn>
          </div> -->
          <div class="px-1 d-flex align-center">
            <v-icon
              size="22"
              color="black"
              @click="filterDate('next')"
              dark
            >mdi-chevron-right</v-icon>
          </div>

          
        </div>
        <div class="px-3 pt-1">
          <v-text-field
            dense
            color="rgb(224, 166, 119)"
            :value="filterMontagesByDateText"
            @click="selectDate()"
            placeholder="Дата"
          />
        </div>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import SelectDate from '@/components/Modals/SelectDate'

export default {
    name: 'DateFilterAssignedMontages',
    data: () => ({
      isMobile: isMobile,
      todayDate: new Date().toISOString().substr(0, 10)
    }),
    computed: {
      filterMontagesByDateNumber() {
        let date = this.filterMontagesByDate
        return date.toISOString().substr(0, 10)
      },
      filterMontagesByDateText() {
        let date = this.filterMontagesByDate
        return new Date(date).toLocaleString('ru', {weekday: 'long', day: 'numeric', month: 'short'})
      },
      filterMontagesByDate: {
        get() { return this.$store.getters['montages/getFilterAssignedMontagesByDate'] },
        set(val) { this.$store.commit('montages/setFilterAssignedMontagesByDate', val) }
      },
      yesterdayDate() {
        let date = new Date()
        let goodDate = new Date(new Date().setDate(date.getDate()-1))
        return goodDate.toISOString().substr(0, 10)
      },
      tomorrowDate() {
        let date = new Date()
        let goodDate = new Date(new Date().setDate(date.getDate()+1))
        return goodDate.toISOString().substr(0, 10)
      },
      afterTomorrowDate(){
        let date = new Date()
        let goodDate = new Date(new Date().setDate(date.getDate() +2))
        return goodDate.toISOString().substr(0, 10)
      }
    },
    methods: {
      async selectDate() {
        let modalDate = await this.$showModal({
          component: SelectDate,
          isPersistent: true,
          props: {
            title: 'Дата монтажа',
            selectDate: this.filterMontagesByDate ? this.filterMontagesByDateNumber : new Date().toISOString().substr(0, 10)
          }
        })
        if (modalDate) {
          this.$emit('updDate', new Date(modalDate))
          // this.filterMontagesByDate = new Date(modalDate)
          // this.$store.dispatch('montages/loadAssignedMontages')
        }
      },
      filterDate(val) {
        let date = this.filterMontagesByDate
        let newDate = ''
        switch (val) {
          case 'tomorrow':
            this.$emit('updDate', new Date(this.tomorrowDate))
            // this.filterMontagesByDate = new Date(this.tomorrowDate)
            // this.$store.dispatch('montages/loadAssignedMontages')
            break;
          case 'yesterday':
            this.$emit('updDate', new Date(this.yesterdayDate))
            // this.filterMontagesByDate = new Date(this.yesterdayDate)
            // this.$store.dispatch('montages/loadAssignedMontages')
            break;
          case 'today':
            this.$emit('updDate', new Date(this.todayDate))
            // this.filterMontagesByDate = new Date(this.todayDate)
            // this.$store.dispatch('montages/loadAssignedMontages')
            break;
          case 'afterTomorrow':
            this.$emit('updDate', new Date(this.afterTomorrowDate))
            // this.filterMontagesByDate = new Date(this.todayDate)
            // this.$store.dispatch('montages/loadAssignedMontages')
          break;

          case 'before':
            newDate = new Date(date.setDate(date.getDate()-1))
            this.$emit('updDate', newDate)
            // this.filterMontagesByDate = newDate
            // this.$store.dispatch('montages/loadAssignedMontages')
            break;
          case 'next':
            newDate = new Date(date.setDate(date.getDate()+1))
            this.$emit('updDate', newDate)
            // this.filterMontagesByDate = newDate
            // this.$store.dispatch('montages/loadAssignedMontages')
            break;
        }
      },
    }
}
</script>

<style lang="scss">
.mobDateFilter {
  background-color: white;

  &_date {
    font-size: 0.8em;
    padding: 0.4em 5px;
    padding-bottom: 0em;
    text-align: center;
  }

  &_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4em 5px;
    padding-top: 0em;
  }
}
</style>