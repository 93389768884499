<template>
  <!-- красный rl #bf4037 -->
  <!-- темный серый фон в таб #626b70 -->
  <!-- новый морской rgb(224, 166, 119) -->
  <!-- новый горчичный #eec374 -->
  <v-app-bar 
    v-if="stateNavbar"
    flat
    app
    :dense="!isMobile"
    color="rgb(224, 166, 119)"
    extension-height="0"
    class="header"
    :class="{ 'header__min-padding': isMobile }"
  >
    <v-app-bar-nav-icon icon="mdi-trach" v-if="$vuetify.breakpoint.mobile" @click.stop="backBtn ? $router.push(backBtn) : drawer = !drawer">
      <template v-if="backBtn" v-slot:default>
        <v-icon>mdi-arrow-left</v-icon>
      </template>
    </v-app-bar-nav-icon>

    <v-row v-if="tabs && isMobile && selectPage">
      <v-col cols="10">
        <v-select
          class="selectClean"
          color="white"
          :value="selectPage"
          @change="goToPage"
          :items="tabs"
          return-object
        ></v-select>
      </v-col>
    </v-row>

    <v-tabs
      v-else-if="tabs && !isMobile"
      color="white"
      background-color="rgb(224, 166, 119)"
      class="header__tabs"
    >
      <v-tab
        v-for="item in tabs"
        :key="item.path"
        :to="`${item.path}`"
        exact
        :show-arrows="false"
        :style="isMobile ? 'min-width: 70px; font-size: 0.78em; padding: 0 10px;' : 'font-size: 0.78em;'"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>

    <v-toolbar-title
      v-else
      :style="`color: white; ${isMobile ? 'padding-left: 0px; font-size: 1.25em;' : 'font-size: 1.1em;'}`"
    >
      {{ pageTitle }}
    </v-toolbar-title>
    <!-- <button @click="toCount" v-if="!isDone">Расчет</button>
    <div v-else>Расчет произведен!</div> -->
    <v-spacer></v-spacer>
    <NotificationsDropList />
    <v-menu v-if="user" offset-y>
      <template v-slot:activator="scope">
        <v-btn
          text
          large
          v-bind="scope.attrs"
          v-on="scope.on"
          class="pa-1"
        >
        
          <!-- <span class="pl-1" style="color: white;">{{ user.displayName }}</span> -->
          <my-mini-avatar :user="user"/>
          <div class="">
            <v-icon v-if="!scope.value" color="white">mdi-chevron-down</v-icon>
            <v-icon v-else color="white">mdi-chevron-up</v-icon>
          </div>
        </v-btn>
      </template>
      <v-card max-width="300">
        <v-list dense>
          <v-list-item>
            <v-list-item-avatar>
              <my-mini-avatar :user="user"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 1em;">
                {{ user.displayName }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        
        <!-- Настройки аккаунта пользователя -->
        <!-- <v-list dense class="pt-0" v-if="supportPush">
          <v-subheader style="height: 30px;">Настройки</v-subheader>
          <v-list-item-group v-model="userSettings" @change="changeSettings" multiple>
            <v-list-item>
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>Push-уведомления</v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list> -->
        <!-- <v-divider v-if="supportPush" /> -->
        <v-list dense>
          <v-list-item @click="logout">
            <v-list-item-title>Выход</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>

    <ProgressBar
      :loading="loading"
      color="#ace2fc"
      slot="extension"
    />
  </v-app-bar>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import ProgressBar from "@/components/Templ/ProgressBar"
import NotificationsDropList from "@/components/Notifications/DropList"
import api from '@/utils/api'

import { mapState, mapActions, } from 'vuex';
export default {
  name: 'Navbar',
  components: {
    NotificationsDropList,
    ProgressBar
  },
  data: () => ({
    isMobile: isMobile,
    userSettings: [],
    isDone: false
  }),
  
  computed: {
    ...mapState('notify', ['notifications']),
    supportPush() {
      return 'Notification' in window
    },
    backBtn() {
      let route = this.$route
      if (this.user.role === 'admin' && route.name === 'User') {
        return { path: '/users' }
      }
      return null
    },
    selectPage() {
      let route = this.$route
      let findTab = this.tabs.find(x => {
        return route.path === x.path ? x : null
      })
      return findTab
    },
    tabs() {
      let route = this.$route
      if (route && route.matched && route.matched.length > 1) {
        if (route.matched[0] && route.matched[0].meta.tabs) {
          return route.matched[0].meta.tabs
        }
        return null 
      }
      return null
    },
    pageTitle() {
      const route = this.$route
      if (route.name === 'User' && route.params.uid && this.users.length > 0) {
        let findUser = this.users.find(x => x.uid === route.params.uid)
        if (findUser) {
          return findUser.displayName
        }
        return 'Пользователь'
      } else if (route.meta && route.meta.text) {
        return route.meta.text
      } else if (route && route.matched && route.matched.length > 1) {
        if (route.matched[0] && route.matched[0].meta.text) {
          return route.matched[0].meta.text
        }
        return ''
      }
      return ''
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    rightMenuShow() {
      return this.$route.name != 'Home' && !['master'].includes(this.user.role) && !isMobile
    },
    stateNavbar() {
      return this.$route.name != 'Login' && this.user
    },
    drawer: {
      get() {
        return this.$store.getters['templ/getDrawer']
      },
      set(val) {
        this.$store.commit('templ/setDrawer', val)
      }
    },
    miniDrawer: {
      get() {
        return this.$store.getters['templ/getMiniDrawer']
      },
      set(val) {
        this.$store.commit('templ/setMiniDrawer', val)
      }
    },
    loading() {
      return this.$store.getters['templ/getLoading']
    }
  },
  methods: {
    async toCount(){
        await api.get("/сalculate").then(response => {
            if (response.data.status){
                this.isDone = true
            }
        })
    },
    loadData() {
      this.loadNotify()
    },

    changeSettings(val) {
      console.log(val)
    },
    onPush() {

    },
    goToPage(tab) {
      this.$router.push({ path: tab.path })
    },
    changeDrawer () {
      this.drawer != this.drawer
    },
    logout () {
      // this.$auth.signOut().then(() => {
      //   this.$store.commit('user/setUser', null)
      //   this.$router.push({ path: '/login' })
      //   location.reload()
      // })
      localStorage.removeItem('jwt')
      this.$store.commit('user/setUser', null)
      this.$router.push({ path: '/login' })
      location.reload()
    }
  },
}
</script>

<style lang="scss">
.header {
  &__tabs {
    .v-slide-group__prev, .v-slide-group__next {
      display: none !important;
    }
  }

  &__min-padding {
    .v-toolbar__content {
      padding-left: 8px;
      padding-right: 2px;
    }
  }
}
.v-toolbar__extension {
  /* height: 0px !important; */
  padding: 0px !important;
}
</style>