import api from '@/utils/api'

export default {
  namespaced: true,
  state: {
    notifications: []
  },
  getters: {
    getShowAlertNotif: (state) => state.notifications.filter(item => item.showAlert) 
  },
  mutations: {
    SET_NOTIFI(state, payload) {
      state.notifications = payload;
    }
  },
  actions: {
    async loadNotify({ commit, dispatch, rootState }) {
      commit('templ/setLoading', true, { root: true })
      
      let { data, status } = await api.post('/notifications/get-list',{
        limit: 10000, 
        offset: 0,
        strict: [
          // { input: 'from_id', value: '5' },
          { input: 'to', value: rootState.user.user.id.toString() },
          { input: 'accepted', value: false },

        ]
      })

      // console.log(data.data.map(({ to }) => to))

      if (status == 200 && data.status){
        commit('SET_NOTIFI', data.data.map(item => {
          try {
            item.payload = JSON.parse(item.payload)
          } catch (e) {
            item.payload = {}
          }
          
          return item
        }) || [])
      }

      commit('templ/setLoading', false, { root: true })
    },

    async acceptedNotify({dispatch}, {id, accepted})  {
      let { data, status } = await api.put('/notifications/update', {
        id,
        accepted: !accepted
      })
      if (status == 200 && data.status) {
        dispatch('loadNotify')
      }
    },

    async acceptedAllNotify({dispatch, state})  {
      const asyncRes = await Promise.all( state.notifications.map(async ({id}) => await api.put('/notifications/update', {
        id,
        accepted: true
      })));
      dispatch('loadNotify')

    },

    async showAlertNotify({dispatch}, { id, showAlert })  {
      let { data, status } = await api.put('/notifications/update', {
        id,
        showAlert
      })
      if (status == 200 && data.status){
        // console.log(data.notifications)
        dispatch('loadNotify')
      }
    },


    async notify({ commit, dispatch, rootState, rootGetters }, {
      to,
      from = rootState.user.user.id,
      title = '',
      text = '',
      action = '',
      payload = {},
      accepted = false,
      showAlert = true,
      date = new Date(),
      del = false,
    }) {
      if (Number(to) === Number(rootGetters['user/getUser'].id)) return
      const user = rootGetters['users/getUser'](Number(to))
      console.log(`Уведомление отправлено:` ,to, user )
      console.log(`Уведомление отправлено: ${user ? user.displayName : '' } - id: ${to}`, user )
     
      
      commit('templ/setLoading', true, { root: true })
      let { data, status } = await api.post('/notifications/create', {
        to: String(to),
        test: "test",
        from,
        title,
        text,
        action,
        payload: JSON.stringify(payload),
        showAlert,
        accepted,
        date,
        del
      } )

      if (status == 200 && data.status){
        dispatch('loadNotify')
        dispatch('socket/sendSocket',['notify'], { root: true })
      }

      commit('templ/setLoading', false, { root: true })
    }
  }
}