import api from '@/utils/api'

import { currentOrderStatus } from '@/helpers'

import loadedOrders from './loaded'
import filterOrders from './filter'

export default {
  namespaced: true,
  state: {
    myOrders: [],
    myDraftOrders: [],
    // pagination user orders
    ordersForManager: [],
    ordersForManagerAll: [],
    // loaded
    ...loadedOrders.state,
    // filters
    ...filterOrders.state,
    orders: [],
    draftOrders: [],
    archiveOrders: [],
    limit: 0,
    offset: 0,
    count: 0,
  },
  getters: {
    getMyOrders: state => {
      const sortedArr = state.myOrders.sort(function (a, b) {
        // return a.deadline.toDate() - b.deadline.toDate();
        return new Date(a.deadline) - new Date(b.deadline);
      })
      return sortedArr
    },
    getMyDraftOrders: state => {
      const sortedArr = state.myDraftOrders.sort(function (a, b) {
        return new Date(a.deadline) - new Date(b.deadline);

      })
      return sortedArr
    },
    getOrdersForManager: (state, getters) => {
      const sortedOrders = state.ordersForManager.sort(function (a, b) {
        return new Date(b.deadline) - new Date(a.deadline);
      })
      return sortedOrders
    },
    getOrdersForManagerAll: (state, getters) => {
      const sortedOrders = state.ordersForManagerAll.sort(function (a, b) {
        return new Date(b.deadline) - new Date(a.deadline);
      })
      return sortedOrders
    },
    // loaded
    ...loadedOrders.getters,
    // filters
    ...filterOrders.getters,
    getOrders: state => {
      let orders = state.orders
      let search = state.filterOrdersByNumberOrder ? state.filterOrdersByNumberOrder.toLowerCase() : null

      if (search) {
        orders = orders.filter(x => {
          if (x.number) {
            let number = x.number.toLowerCase()
            if (number.includes(search)) { return true }
          }
          return false
        })
      }

      const sortedArr = orders.sort(function (a, b) {
        return new Date(a.deadline) - new Date(b.deadline);
      })
      return sortedArr
    },
    getDraftOrders: state => {
      let orders = state.draftOrders
      let search = state.filterDraftOrdersByNumberOrder ? state.filterDraftOrdersByNumberOrder.toLowerCase() : null

      if (search) {
        orders = orders.filter(x => {
          if (x.number) {
            let number = x.number.toLowerCase()
            if (number.includes(search)) { return true }
          }
          return false
        })
      }

      const sortedArr = orders.sort(function (a, b) {
        return new Date(a.deadline) - new Date(b.deadline);
      })
      return sortedArr
    },
    getArchiveOrders: state => {
      let orders = state.archiveOrders
      let search = state.filterArchiveOrdersByNumberOrder ? state.filterArchiveOrdersByNumberOrder.toLowerCase() : null

      if (search) {
        orders = orders.filter(x => {
          if (x.number) {
            let number = x.number.toLowerCase()
            if (number.includes(search)) { return true }
          }
          return false
        })
      }

      const sortedArr = orders.sort(function (a, b) {
        return new Date(a.deadline) - new Date(b.deadline);
      })
      return sortedArr
    },
    count: state => state.count,
  },
  mutations: {
    setMyOrders(state, payload) {
      state.myOrders = payload
    },
    setMyDraftOrders(state, payload) {
      state.myDraftOrders = payload
    },
    setOrdersForManager(state, payload) {
      state.ordersForManager = payload;
    },
    setOrdersForManagerAll(state, payload) {
      console.log("setOrdersForManagerAll=================================", payload)
      state.ordersForManagerAll = payload;
    },
    // loaded
    ...loadedOrders.mutations,
    // filters
    ...filterOrders.mutations,
    setOrders(state, payload) {
      state.orders = payload
    },
    setDraftOrders(state, payload) {
      state.draftOrders = payload
    },
    setArchiveOrders(state, payload) {
      state.archiveOrders = payload
    },
    updateOrder(state, order) {
      let orders = state.orders.map(el => {
        if (el.id == order.id) {
          el = order
        }
        return el;
      })
      state.orders = orders
    },
    addToOrders(state, payload) {
      console.log('addToOrders', payload)
      let orders = state.orders
      orders.push(payload)
      state.orders = orders
    },
    addToDraft(state, payload) {
      let orders = state.draftOrders
      orders.push(payload)
      state.draftOrders = orders
    },
    addToMyOrders(state, payload) {
      let orders = state.myOrders
      orders.push(payload)
      state.myOrders = orders
    },
    addToMyDraft(state, payload) {
      let orders = state.myDraftOrders
      orders.push(payload)
      state.myDraftOrders = orders
    },
    SET_LIMIT(state, payload) {
      state.limit = payload
      // console.log('SET_LIMIT',payload)

    },
    SET_OFFSET(state, payload) {
      state.offset = payload
      // console.log('SET_OFFSET',payload)
    },
    SET_COUNT(state, payload) {
      state.count = payload

    }
  },
  actions: {
    async loadArchiveOrders({ commit, dispatch, getters, rootGetters, state }, pagination) {
      console.log('====', 'loadArchiveOrders')
      commit('templ/setLoading', true, { root: true })
      let
        limit = state.limit,
        offset = state.offset
      //   if (snapshots.loadArchiveOrders) {
      //     snapshots.loadArchiveOrders()
      //     snapshots.loadArchiveOrders = null
      //   }

      let user = rootGetters['user/getUser']

      //   let queryStore = ordersCollection

      let filterByMonth = getters.getFilterArchiveOrdersByMonth


      //   queryStore = queryStore.where('del', '==', false)
      // 			.where('archive', '==', true).where('client', '!=', null)

      let filterByClient = getters.getFilterArchiveOrdersByClient
      if (filterByClient && filterByClient.id) {
        filterByClient = filterByClient.id
      }

      let filterByStatus = getters.getFilterArchiveOrdersByStatus
      let filterByManager = getters.getFilterArchiveOrdersByManager
      if (filterByManager && filterByManager.id) {
        // let managerRef = usersCollection.doc(filterByManager.uid)
        // queryStore = queryStore.where('creator', '==', managerRef)
        filterByManager = filterByManager.id
      }
      let queryStore
      if (filterByMonth) {
        filterByMonth = filterByMonth.split('-')
        let date = new Date(filterByMonth[0], filterByMonth[1], 0);
        let lastDay = date.getDate();
        filterByMonth = filterByMonth.join('-')
        queryStore = await api.post('orders/get-list', {
          limit,
          offset,
          date_from: `${filterByMonth}-01 00:00:01.344+03:00`,
          date_to: `${filterByMonth}-${lastDay} 23:59:59.344+03:00`,
          query_and: [
            { input: 'del', value: false },
            { input: 'archive', value: true },
            user.role == 'manager' ? { input: 'creator_id', value: user.id } : filterByManager ? { input: 'creator_id', value: filterByManager } : null,
            filterByStatus ? { input: 'status', value: filterByStatus } : null,
            filterByClient ? { input: 'client_id', value: filterByClient } : null,
          ]
        })
      } else {
        queryStore = await api.post('orders/get-list', {
          limit,
          offset,
          query_and: [
            { input: 'del', value: false },
            { input: 'archive', value: true },
            user.role == 'manager' ? { input: 'creator_id', value: user.id } : filterByManager ? { input: 'creator_id', value: filterByManager } : null,
            filterByStatus ? { input: 'status', value: filterByStatus } : null,
            filterByClient ? { input: 'client_id', value: filterByClient } : null,
          ]
        })
      }
      commit('SET_COUNT', queryStore.data.count)
      queryStore = queryStore.data.data || []
      let orders = []
      queryStore.map(async (el) => {
        let order = await dispatch('orderSerializer', el)
        orders.push(order)
      })
      commit('setArchiveOrders', orders)
      commit('templ/setLoading', false, { root: true })
      commit('setArchiveOrdersLoaded', true)
      //   snapshots.loadArchiveOrders = queryStore.onSnapshot(async snapshot => {
      //     let ordersArr = []

      //     for (let doc of snapshot.docs) {
      //       let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
      //       ordersArr.push(order)
      //     }

      //     commit('setArchiveOrders', ordersArr)
      //     commit('templ/setLoading', false, { root: true })
      // 			commit('setArchiveOrdersLoaded', true)
      //   })

    },
    async loadDraftOrders({ commit, dispatch, getters, rootGetters, state }, pagination) {
      console.log('====', 'loadDraftOrders')
      commit('templ/setLoading', true, { root: true })

      //   if (snapshots.loadDraftOrders) {
      //     snapshots.loadDraftOrders()
      //     snapshots.loadDraftOrders = null
      //   }

      let user = rootGetters['user/getUser']
      let queryStore
      // let queryStore = ordersCollection
      // let queryStore = await api.post('orders/get-list',{
      // 	limit: 10000,
      // 	offset: 0,

      // })
      let filterByClient = getters.getFilterDraftOrdersByClient
      let lastDay
      let filterByManager = getters.getFilterDraftOrdersByManager
      if (filterByManager && filterByManager.id) {
        filterByManager = filterByManager.id
      }
      if (filterByClient && filterByClient.id) {
        filterByClient = filterByClient.id
      }
      let filterByMonth = getters.getFilterDraftOrdersByMonth
      if (filterByMonth) {
        filterByMonth = filterByMonth.split('-')
        let date = new Date(filterByMonth[0], filterByMonth[1], 0);
        lastDay = date.getDate();
        filterByMonth = filterByMonth.join('-')
        queryStore = await api.post('orders/get-list', {
          limit: state.limit,
          offset: state.offset,
          date_from: `${filterByMonth}-01 00:00:01.344+03:00`,
          date_to: `${filterByMonth}-${lastDay} 23:59:59.344+03:00`,
          query_and: [
            { input: 'del', value: false },
            { input: 'status', value: 'draft' },
            { input: 'archive', value: false },
            filterByClient ? { input: 'client_id', value: filterByClient } : null,
            user.role == 'manager' ? { input: 'manager_id', value: user.id } : filterByManager ? { input: 'creator_id', value: filterByManager } : null
          ]
        })
      } else {
        queryStore = await api.post('orders/get-list', {
          limit: state.limit,
          offset: state.offset,
          query_and: [
            { input: 'del', value: false },
            { input: 'status', value: 'draft' },
            { input: 'archive', value: false },
            filterByClient ? { input: 'client_id', value: filterByClient } : null,
            user.role == 'manager' ? { input: 'manager_id', value: user.id } : filterByManager ? { input: 'creator_id', value: filterByManager } : null
          ]
        })
      }
      commit('SET_COUNT', queryStore.data.count)
      queryStore = queryStore.data.data || []
      let ordersArr = []
      // queryStore = queryStore.data.data ? queryStore.data.data : queryStore
      if (queryStore.length > 0) {
        console.log('queryStore.length > 1')
        queryStore.forEach(async (el) => {
          let order = await dispatch('orderSerializer', el)
          ordersArr.push(order)
        })
      }
      // else if(queryStore.length != 0){
      // 	console.log('queryStore.length != 0', queryStore)
      // 	let order = await dispatch('orderSerializer', queryStore)
      // 	ordersArr.push(order)
      // }

      commit('setDraftOrders', ordersArr)
      commit('templ/setLoading', false, { root: true })
      commit('setDraftOrdersLoaded', true)
      // 		snapshots.loadDraftOrders = queryStore.onSnapshot(async snapshot => {
      //     let ordersArr = []

      //     for (let doc of snapshot.docs) {
      //       let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
      //       ordersArr.push(order)
      //     }

      //     commit('setDraftOrders', ordersArr)
      //     commit('templ/setLoading', false, { root: true })
      // 			commit('setDraftOrdersLoaded', true)
      //   })
    },
    async loadOrders({ commit, dispatch, getters, rootGetters, state }, pagination) {
      console.log('====', 'loadOrders')
      commit('templ/setLoading', true, { root: true })

      //   if (snapshots.loadOrders) {
      //     snapshots.loadOrders()
      //     snapshots.loadOrders = null
      //   }
      // console.log('loadOrders')
      let filterByManager = getters.getFilterOrdersByManager
      let user = rootGetters['user/getUser']
      let filterByClient = getters.getFilterOrdersByClient
      if (filterByClient && filterByClient.id) {
        filterByClient = filterByClient.id
      }
      let queryStore
      let filterByMonth = getters.getFilterOrdersByMonth
      let lastDay
      if (filterByMonth) {
        filterByMonth = filterByMonth.split('-')
        let date = new Date(filterByMonth[0], filterByMonth[1], 0);
        lastDay = date.getDate();
        filterByMonth = filterByMonth.join('-')
      }
      let filterByStatus = getters.getFilterOrdersByStatus
      if (filterByStatus && filterByStatus.length > 0) {
        // queryStore = queryStore.where('status', '==', filterByStatus)
        // queryStore = queryStore.where('status', 'in', filterByStatus)
        if (filterByMonth) {
          queryStore = await api.post('orders/get-list', {
            limit: state.limit,
            offset: state.offset,
            date_from: `${filterByMonth}-01 00:00:01.344+03:00`,
            date_from: `${filterByMonth}-${lastDay} 23:59:59.344+03:00`,
            query: filterByStatus.map(el => {
              return { input: 'status', value: el }
            }),
            query_and: [{ input: 'del', value: false }, { input: 'archive', value: false }, filterByManager ? { input: 'creator_id', value: filterByManager.id } : null, filterByClient ? { input: 'client_id', value: filterByClient } : null]
          })
        } else {
          queryStore = await api.post('orders/get-list', {
            limit: state.limit,
            offset: state.offset,
            query: filterByStatus.map(el => {
              return { input: 'status', value: el }
            }),
            query_and: [{ input: 'del', value: false }, { input: 'archive', value: false }, filterByManager ? { input: 'creator_id', value: filterByManager.id } : null, filterByClient ? { input: 'client_id', value: filterByClient } : null]
          })
        }
      } else {
        if (filterByMonth) {
          queryStore = await api.post('orders/get-list', {
            limit: state.limit,
            offset: state.offset,
            date_from: `${filterByMonth}-01 00:00:01.344+03:00`,
            date_to: `${filterByMonth}-${lastDay} 23:59:59.344+03:00`,
            query_and: [{ input: 'del', value: false }, { input: 'archive', value: false }, user.role == 'manager' ? { input: 'creator_id', value: user.id } : filterByManager ? { input: 'creator_id', value: filterByManager.id } : null, filterByClient ? { input: 'client_id', value: filterByClient } : null]
          })
        } else {
          queryStore = await api.post('orders/get-list', {
            limit: state.limit,
            offset: state.offset,
            query_and: [{ input: 'del', value: false }, { input: 'archive', value: false }, user.role == 'manager' ? { input: 'creator_id', value: user.id } : filterByManager ? { input: 'creator_id', value: filterByManager.id } : null, filterByClient ? { input: 'client_id', value: filterByClient } : null]
          })
        }
      }
      commit('SET_COUNT', queryStore.data.count)
      // console.log('SET_COUNT',queryStore)
      queryStore = queryStore.data.data || []
      // let users = rootGetters['users/getUsers']
      let orders = []
      queryStore.forEach((order) => {
        // let order = await dispatch('orderSerializer',el)
        // el.creator = users.find(user => user.id == el.creator_id)
        if (order.contact) {
          order.contact = JSON.parse(order.contact)
        }
        if (order.client_data) {
          order.client_data.contacts = JSON.parse(order.client_data.contacts)
          order =
          {
            ...order,
            // client: {
            // 	...order.clientData,
            // 	id: order.client.id
            // }
            // client: users.data.data.find(el => el.id == order.client)
            client: order.client_data
          }
          delete order.clientData
        }

        if (order.creator_id) {
          let creator = {}
          if (order.creatorData) {
            creator = order.creatorData
            // creator = users.data.data.find(el => el.id == order.creatorData)
          } else {
            // creator = await order.creator.get()
            // creator = creator.data()
            creator = order.creator_id
          }
          order = {
            ...order,
            creator
            // creator: {
            // 	...creator,
            // 	id: order.creator.id
            // }
          }
          delete order.creatorData
        }
        if (order.percent_of_order) {
          order.percentOfOrder = order.percent_of_order
          delete order.percent_of_order
        }
        orders.push(order)
      })
      commit('setOrders', orders)
      commit('templ/setLoading', false, { root: true })
      commit('setOrdersLoaded', true)

    },
    async loadOrdersForManager({ commit, dispatch, getters, state }, data) {
      console.log('====', 'loadOrdersForManager')
      commit('templ/setLoading', true, { root: true })

      //   if (snapshots.loadOrdersForManager) {
      //     snapshots.loadOrdersForManager()
      //     snapshots.loadOrdersForManager = null
      //   }

      //   let queryStore = ordersCollection.where('archive', '==', true).where('status', '==', 'ready')
      let queryStore = await api.post('orders/get-list', {
        limit: state.limit,
        offset: state.offset,
        query: [
          { input: "archive", value: true },
          { input: "status", value: "ready" },
        ],
        query_and: [
          { input: "del", value: false },
          data.month ? { input: "archive_date", value: data.month } : null,
          data.id ? { input: "creator_id", value: data.id } : null,
        ]

      })

      commit('SET_COUNT', queryStore.data.count)
      // queryStore = queryStore.data.data
      //   if (data.uid || data.id) {
      // 		// let userRef = usersCollection.doc(data.id)
      // 		// let userRef = await axios.get(`${url}/users/get/${data.uid}`)
      //    	// queryStore = queryStore.where('creator', '==', userRef)
      // 	// console.log(queryStore)
      // 	queryStore = queryStore.find(el => el.creator == data.uid || data.id)
      // 	commit('SET_COUNT', queryStore.length)
      // }
      //   console.log(queryStore)
      // if (data.month) {
      //   let date = new Date(data.month), y = date.getFullYear(), m = date.getMonth()
      //   let firstDay = new Date(y, m, 1, 0)
      //   let lastDay = new Date(y, m + 1, 0, 23, 59)
      //   // queryStore = queryStore.orderBy("archiveDate").startAt(firstDay).endAt(lastDay)
      // }

      let ordersArr = []
      queryStore.data.data.forEach(async (el) => {
        let order = await dispatch('orderSerializer', el)
        ordersArr.push(order)
      })

      // ordersArr = ordersArr.filter(x => !x.del)
      commit('setOrdersForManager', ordersArr)
      commit('templ/setLoading', false, { root: true })
      //   snapshots.loadOrdersForManager = await queryStore.onSnapshot(async snapshot => {
      //     let ordersArr = []
      //     for (let doc of snapshot.docs) {
      //       let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
      //       ordersArr.push(order)
      //     }
      //     ordersArr = ordersArr.filter(x => !x.del)

      //     commit('setOrdersForManager', ordersArr)
      //     commit('templ/setLoading', false, { root: true })
      //   })
    },


    async loadOrdersForManagerAll({ commit, dispatch, getters, state }, data) {
      commit('templ/setLoading', true, { root: true })
      let queryStore = await api.post('orders/get-list', {
        limit: state.limit,
        offset: state.offset,
        query: [],
        query_and: [
          { input: "del", value: false },
          data.month ? { input: "date", value: data.month } : null,
          data.id ? { input: "creator_id", value: data.id } : null,
        ]
      })
      let ordersArr = []
      queryStore.data.data.forEach(async (el) => {
        let order = await dispatch('orderSerializer', el)
        ordersArr.push(order)
      })
      commit('setOrdersForManagerAll', ordersArr)
      commit('templ/setLoading', false, { root: true })
    },

    async loadMyDraftOrders({ commit, dispatch, rootState, state }) {
      commit('templ/setLoading', true, { root: true })

      //   if (snapshots.loadMyDraftOrders) {
      //     snapshots.loadMyDraftOrders()
      //     snapshots.loadMyDraftOrders = null
      //   }

      let user = rootState.user.user
      // let userRef = usersCollection.doc(user.uid)

      let queryStore = await api.post('orders/get-list', {
        limit: state.limit,
        offset: state.offset,
        input: 'client',
        query_and: [{ input: 'del', value: false }, { input: 'archive', value: false }, { input: 'creator_id', value: user.id }, { input: 'status', value: 'draft' }]
      })
      //   let queryStore = ordersCollection
      // 			.where('del', '==', false)
      // 			.where('archive', '==', false)
      //     .where('creator', '==', userRef)
      //     .where('status', '==', 'draft')
      // 			.where('client', '!=', null)
      let ordersArr = []
      commit('SET_COUNT', queryStore.data.count)
      queryStore = queryStore.data.data || []
      queryStore.forEach(async (el) => {
        let order = await dispatch('orderSerializer', el)
        ordersArr.push(order)
      })
      commit('setMyDraftOrders', ordersArr)
      commit('templ/setLoading', false, { root: true })
      //   snapshots.loadMyDraftOrders = queryStore.onSnapshot(async snapshot => {
      //     let ordersArr = []

      //     for (let doc of snapshot.docs) {
      //       let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
      //       ordersArr.push(order)
      //     }

      //     commit('setMyDraftOrders', ordersArr)
      //     commit('templ/setLoading', false, { root: true })
      //   })
    },
    async loadMyOrders({ commit, dispatch, rootState, state }) {
      console.log('====', 'loadMyOrders')
      commit('templ/setLoading', true, { root: true })

      //   if (snapshots.loadMyOrders) {
      //     snapshots.loadMyOrders()
      //     snapshots.loadMyOrders = null
      //   }

      let user = rootState.user.user
      // let userRef = usersCollection.doc(user.uid)

      let queryStore = await api.post('orders/get-list', {
        limit: state.limit,
        offset: state.offset,
        query: [{ input: 'del', value: false }, { input: 'archive', value: 'false' }, { input: 'creator_id', value: user.id }]
      })
      queryStore = queryStore.data.data || []
      queryStore = queryStore.filter(x => x.status != 'draft')
      commit('SET_COUNT', queryStore.length)
      //   let queryStore = ordersCollection
      // 			.where('del', '==', false)
      // 			.where('archive', '==', false)
      //     .where('creator', '==', userRef)
      //     .where('status', '!=', 'draft')
      let ordersArr = []
      queryStore.forEach(async (el) => {
        let orders = await dispatch('orderSerializer', el)
        ordersArr.push(orders)
      })

      commit('setMyOrders', ordersArr)
      commit('templ/setLoading', false, { root: true })
      //   snapshots.loadMyOrders = queryStore.onSnapshot(async snapshot => {
      //     let ordersArr = []

      //     for (let doc of snapshot.docs) {
      //       let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
      //       ordersArr.push(order)
      //     }

      //     commit('setMyOrders', ordersArr)
      //     commit('templ/setLoading', false, { root: true })
      //   })
    },
    async loadOrder({ commit, dispatch, rootState }, number) {
      console.log('====', 'loadOrder')
      commit('templ/setLoading', true, { root: true })
      let queryStore = await api.get('orders/get', number)

      // let queryStore = ordersCollection.where('number', '==', number)
      // let result = await queryStore.get()

      queryStore = queryStore.data.data || []
      queryStore = await dispatch('orderSerializer', queryStore[0])
      // for (let doc of result.docs) {
      // 	let order = await dispatch('orderSerializer', { ...doc.data(), id: doc.id })
      // 	ordersArr.push(order)
      // }
      commit('templ/setLoading', false, { root: true })
      // позже надо слелать вывод ошибки если вдруг заказ не будет найден
      return queryStore
    },

    async loadOrderById({ commit, state, dispatch, getters }, id) {
      commit('templ/setLoading', true, { root: true })
      let queryStore = await api.post('orders/get-list', {
        limit: state.limit,
        offset: state.offset,
        query: [
          { input: 'id', value: id },
        ]
      })
      console.log(id, queryStore.data, 'loadOrderById')
      const doc = await dispatch('orderSerializer', queryStore.data.data[0])

      commit('templ/setLoading', false, { root: true })
      return doc
    },

    async loadOrderLink({ commit, dispatch, rootState, rootGetters, rootActions, state }, number) {
      commit('templ/setLoading', true, { root: true })

      let links = []

      // load Montages by number
      let queryStoreMontages = await api.post('montages/get-list', {
        limit: state.limit,
        offset: state.offset,
        strict: [
          { input: 'del', value: false },
          { input: 'number_Order', value: number }
        ]
      })
      // let queryStoreMontages = montagesCollection.where('del', '==', false)
      // 	.where('numberOrder', '==', number)
      // let resultMontages = await queryStoreMontages.get()
      queryStoreMontages = queryStoreMontages.data.data || []
      let users = rootGetters['clients/getClients']
      queryStoreMontages.forEach(async (el) => {
        let montage = await dispatch('montages/montageSerializer', el, { root: true })
        links.push({
          type: 'montage',
          id: montage.id,
          title: users.find(user => user.id == montage.client_id).name,
          status: montage.status
        })
      })
      // for (let doc of resultMontages.docs) {
      // 	let montage = await dispatch('montages/montageSerializer', { ...doc.data(), id: doc.id }, {root:true})
      // 	links.push({
      // 		type: 'montage',
      // 		id: montage.id,
      // 		title: montage.client.name,
      // 		status: montage.status
      // 	})
      // }

      // load Manufactory by number
      // let queryStoreManufactory = manufactoryCollection.where('del', '==', false)
      // 	.where('numberOrder', '==', number)
      let queryStoreManufactory = await api.post('manufactory/get-list', {
        limit: state.limit,
        offset: state.offset,
        strict: [
          { input: 'del', value: false },
          { input: 'number_order', value: number }
        ]
      })

      // console.log(typeof number, number)

      // console.log(queryStoreManufactory, "queryStoreManufactory")
      // let resultManufactory = await queryStoreManufactory.get()
      queryStoreManufactory = queryStoreManufactory.data.data || []
      queryStoreManufactory.forEach(async (el) => {
        let manufactory = await dispatch('manufactory/taskSerializer', el, { root: true })
        links.push({
          type: 'manufactory',
          id: manufactory.id,
          title: users.find(user => user.id == manufactory.client_id).name
        })
      })
      // for (let doc of resultManufactory.docs) {
      // 	let manufactory = await dispatch('manufactory/taskSerializer', { ...doc.data(), id: doc.id }, {root:true})
      // 	links.push({
      // 		type: 'manufactory',
      // 		id: manufactory.id,
      // 		title: manufactory.client.name,
      // 		status: manufactory.status
      // 	})
      // }

      // load Task by number
      // let queryStoreTask = tasksCollection.where('del', '==', false)
      // 	.where('numberOrder', '==', number)
      let queryStoreTask = await api.post('tasks/get-list', {
        limit: state.limit,
        offset: state.offset,
        query_and: [
          { input: 'del', value: false },
          { input: 'number_Order', value: number }
        ],
        strict: [{ input: 'number_Order', value: number }]
      })

      queryStoreTask = queryStoreTask.data.data || []
      queryStoreTask.forEach(async (el) => {
        // el.from = users.find(user => user.id == el.from)
        el.from_data ? el.from = el.from_data : ''
        el.whoTo = el.who_to_data || []
        el.whoToData = el.who_to_data || []
        el.color ? el.color = JSON.parse(el.color) : ''
        el.images ? el.images = el.images.split(',') : ''
        el.whoTo = el.whoTo.map(whoTo => {
          whoTo = whoTo.id
          return whoTo
        })
        // el.whoToData = users.find(user => user.id == el.whoToData);
        links.push({
          type: 'task',
          id: el.id,
          title: el.title,
          status: el.archive ? 'В архиве' : el.ready ? 'Готово' : el.design ? 'В работе' : 'В печати'
        })
      })
      // let resultTask = await queryStoreTask.get()
      // for (let doc of resultTask.docs) {
      // 	let task = { ...doc.data(), id: doc.id }
      // 	links.push({
      // 		type: 'task',
      // 		id: task.id,
      // 		title: task.title,
      // 		status: task.archive ? 'В архиве' : task.ready ? 'Готово' : 'В работе'
      // 	})
      // }

      commit('templ/setLoading', false, { root: true })
      return links
    },
    async createOrder({ commit, rootState, dispatch, rootGetters }, from) {
      console.log('====', 'createOrder')
      commit('templ/setLoading', true, { root: true })
      // let user = rootState.user.user
      // let creator = usersCollection.doc(user.uid)
      // let user = await api.get(`${url}/users/get/7`).then(response => {
      // 	if(response.status == 200){
      // 		return response.data.data
      // 	}
      // })
      // console.log('from ',from)
      let user = rootGetters['user/getUser']
      let numberOrder = await dispatch('getNewNumberOrder')
      const obj = {
        // creator: creator,
        creator_id: user.id,
        // creatorData: {
        // 	id: user.uid,
        // 	avatar: user.avatar,
        // 	del: user.del,
        // 	displayName: user.displayName,
        // 	email: user.email,
        // 	location: user.location
        // },
        // creatorData: user.id,
        archive: false,
        del: false,
        client: null,
        date: new Date(),
        deadline: new Date(),
        paid: [],
        // paid: '',
        works: [],
        // works: '',
        status: 'draft',
        number: numberOrder
      }

      // let newOrder = await ordersCollection.add(obj)
      let newOrder = await api.post('orders/create', {
        ...obj
      })

      // console.log(obj)
      let order = await dispatch('orderSerializer', newOrder.data.orders)
      commit('templ/setLoading', false, { root: true })
      console.log('before switch', order)
      switch (from.name) {
        case 'Orders':
          console.log('switch orders')
          commit('addToOrders', order)
          break;
        case 'OrdersDraft':
          commit('addToDraft', order)
          break;
        case 'MyOrders':
          commit('addToMyOrders', order)
          break;
        case 'MyOrdersDraft':
          commit('addToMyDraft', order)
          break;
      }
      return await dispatch('orderSerializer', order)
      // return newOrder.data.data
    },

    async updateOrder({ commit, dispatch, rootGetters }, data) {
      commit('templ/setLoading', true, { root: true })
      //   let doc = ordersCollection.doc(data.id)
      let order = await api.get('orders/get', data.id).then(response => {
        if (response.status == 200) {
          return response.data.data
        }
      })

      let obj = data.data
      // let users = rootGetters['users/getUsers']
      console.log(
        'obj', obj
      )

      if (obj.client) {
        obj.client_id = obj.client.id
      }

      if (obj.creator) {
        obj.creator_id = obj.creator.id
      }

      if (obj.whoTo) {
        obj.whoTo.map(el => {
          el = el.id
          return el
        })
      }

      if (obj.client_id) {
        if (obj.client_id) {
          // obj.clientData = {
          // 	del: obj.client.del,
          // 	name: obj.client.name,
          // 	path: obj.client.path,
          // 	phonenumber: obj.client.phonenumber,
          // 	surname: obj.client.surname,
          // 	address: obj.client.address ? obj.client.address : []
          // }
          // obj.clientData = users.find(user => user.id == obj.client_id)
          // obj.client = users.find(user => user.id == obj.client_id)
          // obj.client = obj.client_id
          // obj.client = clientsCollection.doc(obj.client.id)
          await dispatch('clients/updateClient', { id: obj.client.id, data: obj.client.clientData }, { root: true })
          // obj.clientData = { ...obj.clientData, id: obj.client_id }
        } else {
          let newClientObj = {
            del: false,
            name: obj.client.name,
            created: new Date(),
            address: obj.client.address ? [...obj.client.address] : [],
            surname: obj.client.surname,
            phone: obj.client.phone,
            path: obj.client.path
          }
          let newClient = await dispatch('clients/createClient', newClientObj, { root: true }).then(response => {
            if (response.status == 200) {
              return response.data.clients
            }
          })
          // obj.client = clientsCollection.doc(newClient.id)
          obj.client_id = newClient.id
          // obj.clientData = { id: newClient.id, ...newClientObj }
        }
      }

      if (obj.percentOfOrder) {
        obj.percent_of_order = obj.percentOfOrder
        delete obj.percentOfOrder
      }

      // await doc.update(obj)
      let doc
      delete obj.client
      if (obj.contact) {
        obj.contact = JSON.stringify(obj.contact)
      }
      await api.put('orders/update', {
        id: order.id,
        ...obj
      }).then(response => {

        if (response.status == 200) {

          doc = response.data.orders
        }
      })



      doc = await dispatch('orderSerializer', doc)
      commit('updateOrder', doc)
      commit('templ/setLoading', false, { root: true })
      dispatch('socket/sendSocket', ['orders'], { root: true })
      return doc
    },
    async deleteOrder({ commit, dispatch, rootGetters }, order) {
      let doc
      await api.put('orders/update', {
        id: order.id,
        del: true,
      }).then(response => {

        if (response.status == 200) {
          console.log("Заказ удален")
          dispatch('socket/sendSocket', ['orders'], { root: true })
          doc = response.data.orders
        }
      })

      return doc
    },
    async orderSerializer({ state, dispatch }, ref) {
      let order = ref
      if (order.contact) {
        order.contact = JSON.parse(order.contact)
      }
      //   if (!ref.path) {
      //     order = ref
      //   } else {
      //     let res = await ref.get()
      //     order = { ...res.data(), id: res.id }
      //   }
      // let users = await axios.post(`${url}/users/get-list`,{
      // 	limit: 1000,
      // 	offset: 0
      // })
      if (order.client_data) {
        order.client_data.contacts = JSON.parse(order.client_data.contacts)
        order =
        {
          ...order,
          // client: {
          // 	...order.clientData,
          // 	id: order.client.id
          // }
          // client: users.data.data.find(el => el.id == order.client)
          client: order.client_data
        }
        delete order.clientData
      }

      if (order.creator_id) {
        let creator = {}
        if (order.creatorData) {
          creator = order.creatorData
          // creator = users.data.data.find(el => el.id == order.creatorData)
        } else {
          // creator = await order.creator.get()
          // creator = creator.data()
          creator = order.creator_id
        }
        order = {
          ...order,
          creator
          // creator: {
          // 	...creator,
          // 	id: order.creator.id
          // }
        }
        delete order.creatorData
      }
      if (order.percent_of_order) {
        order.percentOfOrder = order.percent_of_order
        delete order.percent_of_order
      }
      console.log('serialized order', order)
      return order
    },
    async getNewNumberOrder(state) {
      console.log('====', 'getNewNumberOrder')
      let toDay = new Date()
      let year = String(toDay.getFullYear()).substring(2)
      let month = toDay.getMonth() + 1

      if (month < 10) {
        month = `0${month}`
      }

      let date = new Date(), y = date.getFullYear(), m = date.getMonth()
      let firstDay = new Date(y, m, 1, 0)
      let lastDay = new Date(y, m + 1, 0, 23, 59)

      // console.log(`Дата по UTC: ${date}`)
      // console.log(`Первый день(${firstDay}), последний день(${lastDay})`)

      let numberInMonth = '01'
      // let orderQueryStore = await ordersCollection.orderBy('date').startAt(firstDay).endAt(lastDay).get()
      let orderQueryStore = await api.post('orders/get-list', {
        limit: state.limit,
        offset: state.offset,
        sort: 'date'
      })
      // let lengthList = orderQueryStore.docs
      let lengthList = orderQueryStore.data.count + 1

      if (lengthList < 10) {
        numberInMonth = `0${lengthList}`
      } else {
        numberInMonth = lengthList
      }
      // console.log('getNewNumberOrder',year,month,numberInMonth)
      return `${year}${month}${numberInMonth}`
    }
  }
}