<template>
  <div class="mobTasksPage">
    <SocketWatch :tables="['manufactory']" @loadData="loadData" />
    <v-tabs
      v-model="tab"
      fixed-tabs
      color="rgb(224, 166, 119)"
      background-color="#626b70"
      class="mobTasksPage_tabs"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="item in tabs"
        :key="item.key"
      >
        {{ item.text }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in tabs"
        :key="item.key"
      >
        <Tasks :tasks="tasks" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Tasks from '../Components/Tasks.vue'

export default {
  name: 'MasterTasksMob',
  components: {
    Tasks
  },
  data: () => ({
    tab: 0,
    tabs: [
      {
        key: 'work',
        text: 'В очереди'
      },
      {
        key: 'produced',
        text: 'В работе'
      }
    ]
  }),
  created() {
    this.loadData()
  },
  computed: {
    
    tasks() {
      let status = this.tabs[this.tab].key
      return this.tasksObj && this.tasksObj[status] ? this.tasksObj[status] : []
    },
    tasksObj() {
      let work = this.$store.getters['manufactory/getTasksWorkForMaster']
      let produced = this.$store.getters['manufactory/getTasksProducedForMaster']
      return { work, produced }
    },
    user() {
      return this.$store.getters['user/getUser']
    }
  },
  methods: {
    loadData() { 
      this.$store.dispatch('manufactory/loadTasksWorkForMaster', { id: this.user.id })
      this.$store.dispatch('manufactory/loadTasksProducedForMaster', { id: this.user.id })
    }
  }

}
</script>

<style lang="scss">
.mobTasksPage {
  .v-tabs-items {
    background-color: #424242 !important;
  }

  &_tabs {
    position: sticky;
    top: 56px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    z-index: 2;
  }
}
</style>
