<template>
  <div>
    <el-popover
      placement="bottom"
      trigger="click"
      popper-class="el-tiptap-popper"
    >
      <div class="el-tiptap-popper__menu">
        <div
          class="el-tiptap-popper__menu__item"
          @click="openUrlPrompt"
        >
          <span>Загрузить по ссылке</span>
        </div>

        <div
          class="el-tiptap-popper__menu__item"
          @click="imageUploadDialog"
        >
          <span>Загрузить с устройства</span>
        </div>
      </div>

      <CommandButton
        slot="reference"
        :enable-tooltip="true"
        tooltip="Добавить изображение"
        icon="image"
      />
    </el-popover>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/popover.css';
import { Popover } from 'element-ui';
import CommandButton from '@/components/Faq/Pages/components/TextEditor/extensions/components/CommandButton.vue';
import DialogAddImageByUrl from './DialogAddImageByUrl.vue'
import DialogAddImageFromDevice from './DialogAddImageFromDevice.vue'

export default {
	components: {
		'el-popover': Popover,
		CommandButton
	},
  name: "ImageMenuButton",
  props: ["editorContext"],
  methods: {
		async openUrlPrompt() {
			const res = await this.$showModal({
        component: DialogAddImageByUrl,
        isPersistent: true,
        props: {
          edit: false,
          data: null
        }
      })
			if (res && res.url) {
				this.editorContext.commands.image({ src: res.url });
			}
		},
		async imageUploadDialog() {
			const res = await this.$showModal({
        component: DialogAddImageFromDevice,
        isPersistent: true,
        props: {
          edit: false,
          data: null
        }
      })
			if (res && res.url) {
				this.editorContext.commands.image({ src: res.url });
			}
		},
  }
};
</script>
