import Vue from 'vue';
import Vuex from 'vuex';
import templ from './modules/templ';

// users
import users from './modules/users/users';
import user from './modules/users/user';

// montages
import montages from './modules/montages/montages';

// brigs
import brigs from './modules/brigs/brigs';

// locations
import locations from './modules/locations/locations';

// clients
import clients from './modules/clients/clients';

// manufactory
import manufactory from './modules/manufactory/tasks';

// finance
import prepayments from './modules/finance/prepayments';
import deductions from './modules/finance/deductions';
import premiums from './modules/finance/premiums';

// hourlyWorks
import hourlyWorks from './modules/hourlyWorks/hourlyWorks';

// tasks
import tasks from './modules/tasks/tasks';
import fromMeTasks from './modules/tasks/fromMeTasks';
import meTasks from './modules/tasks/meTasks';

// orders
import orders from './modules/orders/orders';

// metaInfo
import settings from './modules/metaInfo/settings';
import comments from './modules/comments/comments';
import socket from './modules/socket/socket';

// warehouse
import warehouse from './modules/warehouse/warehouse';
import notify from './modules/notify/notify';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        templ,
        users,
        user,
        montages,
        brigs,
        locations,
        clients,
        manufactory,
        prepayments,
        deductions,
        premiums,
        hourlyWorks,
        tasks,
				orders,
        fromMeTasks,
        meTasks,
        settings,
        comments,
        socket,
        warehouse,
        notify,
    },
    state: {},
    getters: {},
    mutations: {},
    actions: {},
  });
  