<template>
  <div>
    <Tasks :tasks="tasks" />
  </div>
</template>

<script>
import Tasks from '../Components/Tasks.vue'

export default {
  name: 'MasterTasksMob',
  components: {
    Tasks
  },
  data: () => ({
    tab: '',
    tabs: [
      {
        key: 'work',
        text: 'В очереди'
      },
      {
        key: 'produced',
        text: 'В работе'
      }
    ]
  }),
  computed: {
    tasks() {
      return this.$store.getters['manufactory/getTasksArchiveForMaster']
    }
  }
}
</script>