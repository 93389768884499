<template>
  <v-card>
    <v-card-title class="userTableTitle">
      <div style="font-weight: 400;">
        Почасовая работа
        <span v-if="costHourlyWorks">{{ costHourlyWorks }}</span>
      </div>
    </v-card-title>
    <v-divider />
    <div v-if="!hourlyWorks || hourlyWorks.length === 0" class="infoNoItems">
      Нет сделанной работы
    </div>
    <v-simple-table :dense="!$isMobile" v-if="hourlyWorks.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Дата</th>
            <th class="text-left">Часы</th>
            <th class="text-left">Что сделал</th>
            <th class="text-left">Итого</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="task in hourlyWorks"
            :key="task.id"
            class="tableItemList"
            @click="editTask(task)"
            :set="ifHPWM = $ifHourPayWorkMasters(task, user)"
          >
            <td style="min-width: 85px;">{{ $formatDate(new Date(task.date)) }}</td>
            <td style="min-width: 90px;">
              <span v-if="task.hourPayWorkMasters">
                {{ ifHPWM.allHour }} {{ $numWord(ifHPWM.allHour, ['час', 'часа', 'часов']) }}
              </span>
              <span v-else>
                {{ task.numberOfHours }} {{ $numWord(task.numberOfHours, ['час', 'часа', 'часов']) }}
              </span>
            </td>
            <td style="min-width: 120px;">{{ $sliceStr($getStrTasks(task.tasks), 60) }}</td>
            <td>
              <span v-if="task.hourPayWorkMasters">
                {{ ifHPWM.cost }}р.
              </span>
              <span v-else-if="task.numberOfHours && task.rate">
                {{ Number(task.numberOfHours) * task.rate }}р.
              </span>
              <span v-else>Ничего</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import ModalTask from '@/components/PagesByRole/Master/HourlyWorks/Components/ModalTask/Index'

export default {
  props: {
    user: {
      type: Object,
      default: {}
    }
  },
  computed: {
    hourlyWorks() {
      return this.$store.getters['hourlyWorks/getTasks']
      // return this.user.hourlyWorks || []
    },
    costHourlyWorks() {
      if (!this.hourlyWorks || this.hourlyWorks.length === 0) {
        return false
      }
      let cost = 0
      this.hourlyWorks.map(work => {
        if (work.hourPayWorkMasters) {
          let ifHPWM = this.$ifHourPayWorkMasters(work, this.user)
          cost = Number(cost) + ifHPWM.cost
        } else if (work.numberOfHours) {
          let rate = 0
          if (work.rate) {
            rate = Number(work.rate)
          }
          cost = Number(cost) + (Number(work.numberOfHours) * rate)
        }
      })

      return `${cost}р.`
    },
  },
  methods: {
    async editTask(task) {
      console.log(task)
      let editTask = await this.$showModal({
        component: ModalTask,
        isPersistent: true,
        props: {
          task: task
        }
      })
      if (editTask) {
        editTask.date = new Date(editTask.date)
        this.$store.dispatch('hourlyWorks/updateTask', { data: editTask, id: task.id })
      }
    },
  }
}
</script>