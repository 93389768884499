<template>
  <div class="pages-warehouse">
    <SocketWatch  :tables="['categorys', 'products']" @loadData="loadData" />
    <v-row class="ma-0 px-1 py-2">
      <v-col md="3" cols="12" class="pa-0 px-4 mb-4">
        <Menu />
      </v-col>
      <v-col md="9" cols="12" class="pa-0 px-4">
        <v-card :tile="$isMobile">
          <div v-if="user.role === 'admin' && selectedCategory" class="d-flex align-center px-4 py-4">
            
            <v-btn
              v-if="user.role === 'admin' && selectedCategory"
							small icon
							@click="sort = !sort"
						>
							<v-icon size="22">mdi-sort</v-icon>
						</v-btn>
            <v-btn class="ml-auto" icon small @click="addProduct()">
              <v-icon size="21">mdi-plus</v-icon>
            </v-btn>

          </div>
          <div v-if="!selectedCategory" class="px-4 pt-4">
            <v-row class="ma-0 pa-0">
              <v-col cols="4" class="pa-0 ">
                <v-select
                  color="rgb(224, 166, 119)"
                  v-model="type"
                  label="Тип"
                  :items="[{
                      label: 'Списания',
                      value: 'minus'
                    },
                    {
                      label: 'Поступления',
                      value: 'plus'
                    }
                  ]"
                  item-text="label"
                  
                  clearable
                />
              </v-col>
              </v-row>
          </div>
          <v-divider />
          
          <Positions table :type="type" v-if="!selectedCategory" />
          <template v-else>
          <draggable
            v-if="sort"
            class="tableMontage"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            v-model="productsList"
          >

          <transition-group type="transition" name="flip-list">
						<v-list-item
              
              
              v-for="item in productsList"
              :key="item.id"
						>
              <v-list-item-icon class="my-3">
                <v-icon>mdi-drag</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
              </v-list-item-content>
						</v-list-item>
					</transition-group>
					
        
        </draggable>
          
          <v-data-table
            v-else
            :headers="headers"
            :items="products"
            single-expand
            :expanded.sync="expanded"
            show-expand
            dense
          >
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:[`item.category_id`]="{ item }">
              {{ categorysObj[item.category_id] ? categorysObj[item.category_id].name : 'Без категории'}} 
            </template>
            <template v-slot:[`item.count`]="{ item }">
              <!-- <v-btn small v-if="['admin'].includes(user.role) || user.warehouseManager" @click="countPlusProduct(item)" icon color="#424242">
                <v-icon size="18">mdi-plus</v-icon>
              </v-btn> -->
             {{ item.count}} {{ item.unit}}
              <!-- <v-btn small v-if="['admin'].includes(user.role) || user.warehouseManager" @click="countMinusProduct(item)" icon color="#424242">
                <v-icon size="18">mdi-minus</v-icon>
              </v-btn> -->
            </template>
            <template v-slot:[`item.comment`]="{ item }">
              {{ item.comment }}
            </template>
            <template v-slot:[`item.action`]="{ item }">
             <template v-if="user.role !== 'manager'">
            
            
              <v-btn 
                x-small 
                outlined 
                v-if="['admin'].includes(user.role) || user.warehouseManager" 
                @click="countPlusProduct(item)"  
              >
                Добавить
              </v-btn>

              <v-btn
                x-small 
                class="ml-2"
                outlined
                color="primary" 
                v-if="['admin'].includes(user.role) || user.warehouseManager" 
                @click="countMinusProduct(item)" 
              >
                Списать
              </v-btn>

              <v-btn small v-if="user.role === 'admin'" @click="editProduct(item)" icon color="#424242">
                <v-icon size="18">mdi-pencil</v-icon>
              </v-btn>
              <!-- <v-btn small v-if="user.role === 'admin'" @click="delProduct(item)" icon color="#bf4037">
                <v-icon size="18">mdi-trash-can-outline</v-icon>
              </v-btn> -->

            </template>
            </template>
            <template v-slot:expanded-item="{  item , headers}">
              <td v-if="expanded.length && expanded[0].id === item.id" :colspan="headers.length" >
              <Positions :product="item" />
            </td>
            </template>
          </v-data-table>
        </template>
        </v-card>
      </v-col> 
    </v-row>
  </div>
</template>

<script>
  import Menu from '../components/Menu.vue'
  import ModalProduct from '../components/modals/Product.vue'
  import ModalAddOrWriteOff from '../components/modals/AddOrWriteOff.vue'
  import Positions from '../components/Positions.vue'
  import draggable from 'vuedraggable'
  import {
    mapActions,
    mapState,
    mapGetters
  } from 'vuex'
  export default {
    components: {
      draggable,
      ModalAddOrWriteOff,
      ModalProduct,
      Positions,
      Menu,
    },
    created() {
      this.loadData()
    },
    data: () => ({
      expanded: [],
      count: 0,
      type: 'plus',
      drag: false,
      sort: false,
    }),

    watch: {
      selectedCategory() {
        this.loadProducts()
      }
    },
    computed: {
      ...mapState('warehouse', [
        'selectedCategory',
        'selectedProduct',
        'products',
        'categorys',
        'positions',
      ]),
      productsList: {
        get() {
          return this.products
        },
        set(value) {
          this.sortProducts(value)
        }
      },
      dragOptions() {
        return {
          animation: 200,
          group: "description",
        }
      },
      ...mapGetters('warehouse', ['categorysObj']),
      user() {
        return this.$store.getters['user/getUser']
      },
     
      headers() {
        const arr = [
              {
                text: 'Название',
                align: 'start',
                sortable: false,
                value: 'name',
              },
              {
                text: 'Количество',
                align: 'start',
                sortable: false,
                value: 'count',
              },
              {
                text: 'Комментарий',
                align: 'start',
                sortable: false,
                value: 'comment',
              },
              {
                text: 'Категория',
                align: 'start',
                sortable: false,
                value: 'category_id',
              },
              {
                text: 'Actions',
                align: 'start',
                sortable: false,
                value: 'action',
              },

              
            ]
           
        return arr
      }
    },
    methods: {
      loadData() {
        this.loadCategorys()
        this.loadProducts()
        // this.loadPositions()
      },
      ...mapActions('warehouse', [
        'loadCategorys',
        'loadProducts',
        'loadPositions',
        'sortProducts',

        'createCategory',
        'createProduct',
        'createPosition',

        'updateCategory',
        'updateProduct',
        'updateCountProduct',
        'updatePosition',

        'deleteCategory',
        'deleteProduct',
        'deletePosition',
      ]),
      
      async editProduct(item) {
        const product = await this.$showModal({
					component: ModalProduct,
					isPersistent: false,
					props: {
            edit: true,
						data: item
					}
				})

				if (product) {
					this.updateProduct({...item, ...product})
				}
      },
      
      async addProduct() {
        const product = await this.$showModal({
					component: ModalProduct,
					isPersistent: false,
					props: {
						data: {
              category_id: this.selectedCategory ? this.selectedCategory.id : null
            }
					}
				})

				if (product) {
					this.createProduct(product)
				}
      },

      async delProduct(item) {
        this.deleteProduct(item)
      },

      async countPlusProduct(item) {
        this.expanded = []
        const res = await this.$showModal({
					component: ModalAddOrWriteOff,
					isPersistent: false,
					props: {
            who_id: this.user.id,
            type: 'plus',
						data: item
					}
				})

				if (res) {
					this.updateCountProduct(res)
				}
      },

      async countMinusProduct(item) {
        this.expanded = []
        const res = await this.$showModal({
					component: ModalAddOrWriteOff,
					isPersistent: false,
					props: {
            who_id: this.user.id,
            type: 'minus',
						data: item
					}
				})

				if (res) {
					this.updateCountProduct(res)
				}
      }
    }
  }
</script>

<style>
  .pages-warehouse {

  }
</style>