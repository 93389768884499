<template>
	<div class="iframe iframe_full pa-1">
		<SocketWatch :tables="['tasks']" @loadData="loadData" />
		<v-card>
			<v-card-title class="d-flex py-0">
				<v-icon class="mr-2">mdi-message-text-clock-outline</v-icon>
				<div>
					<v-tabs v-model="tab" color="rgb(224, 166, 119)">
						<v-tab v-for="item in tabs" :key="item.key" :show-arrows="false">
							{{ item.title }}
						</v-tab>
					</v-tabs>
				</div>
				<v-spacer />
				<v-data-footer v-if="options[tab] && paginations[tab]" :options.sync="options[tab]"
					:pagination="paginations[tab]" items-per-page-text="" :items-per-page-options="[5, 10, 15, 20, 30, 40]"
					style="width: 310px; padding: 0;" class="rl-data-footer" />
			</v-card-title>
			<v-divider />
			<v-tabs-items v-model="tab">
				<v-tab-item v-for="(item, index_item) in tabs" :key="item.key">
					<WidgetMeTask v-if="item.key === 'meTask'" :options="options[tab]"
						@updOptions="updOptions($event, index_item)" @updPagination="updPagination($event, index_item)" />
					<WidgetFromMeTask v-if="item.key === 'fromMeTask'" :options="options[tab]"
						@updOptions="updOptions($event, index_item)" @updPagination="updPagination($event, index_item)" />
					<WidgetDesignTask v-if="item.key === 'designTask'" :options="options[tab]"
						@updOptions="updOptions($event, index_item)" @updPagination="updPagination($event, index_item)" />
				</v-tab-item>
			</v-tabs-items>
		</v-card>
	</div>
</template>

<script>
	import {
		isMobile
	} from 'mobile-device-detect';
	import TaskModal from '@/components/Modals/Task/Index'

	import WidgetMeTask from '@/components/Tasks/Widgets/MeTask.vue'
	import WidgetFromMeTask from '@/components/Tasks/Widgets/FromMeTask.vue'
	import WidgetDesignTask from '@/components/Tasks/Widgets/DesignTask.vue'

	export default {
		name: 'TabsTask',
		components: {
			WidgetMeTask,
			WidgetFromMeTask,
			WidgetDesignTask
		},
		props: ['tabsTasks'],
		data: () => ({
			isMobile: isMobile,
			tab: 0,
			options: {},
			paginations: {}
		}),
		computed: {
			tabs() {
				let arr = [{
						title: 'Задачи мне',
						key: 'meTask'
					},
					{
						title: 'Задачи от меня',
						key: 'fromMeTask'
					},
					{
						title: 'Задачи дизайнерам',
						key: 'designTask'
					},
				]

				if (this.tabsTasks) {
					arr = arr.filter(x => this.tabsTasks.includes(x.key))
				}

				return arr
			}
		},
		created() {
			this.loadData()
		},
		methods: {
			loadData() {
				this.$store.dispatch('fromMeTasks/loadTasks')
			},
			updOptions(val, key) {
				this.options[key] = val
				this.options = {
					...this.options
				}
			},
			updPagination(val, key) {
				this.paginations[key] = val
				this.paginations = {
					...this.paginations
				}
			},
			newTask() {
				this.$showModal({
					component: TaskModal,
					isPersistent: true,
					props: {
						edit: false,
						// design: this.design,
						data: null
					}
				})
				this.$store.dispatch('fromMeTasks/loadTasks')
			},
		}
	}
</script>