<template>
	<div style="height: 100%;" class="d-flex align-start flex-column">
		<div style="width: 100%;" class="mb-auto">
			<v-card
				class="pa-0"
				tile
				:elevation="0"
			>
				<v-card-title 
					class="pa-1 pl-0"
					style="font-size: 1.1em; line-height: 1.3em;"
				>
					Задача:
					<span v-if="design" class="pl-1" style="font-weight: 400;">
						Дизайнеру
					</span>
					<span v-else-if="meTask" class="pl-1" style="font-weight: 400;">
						Мне
					</span>
					<span v-else class="pl-1" style="font-weight: 400;">
						Кому то
					</span>
				</v-card-title>
			</v-card>
			<v-card
				v-if="numberOrder"
				class="pa-0"
				tile
				:elevation="0"
			>
				<v-card-title 
					class="pa-1 pl-0"
					style="font-size: 1.1em; line-height: 1.3em;"
				>
					Заказ:
					<span 
						v-if="!/[a-zA-Z]/g.test(numberOrder) && (user.role === 'admin' || (taskFrom && taskFrom.id === user.id))"
						class="rl-link pl-1" style="font-weight: 400;"
						@click="$openOrder(numberOrder)"
					>
						{{ numberOrder}}
					</span>
					<span v-else class="pl-1" style="font-weight: 400;">
						{{ numberOrder}}
					</span>
				</v-card-title>
			</v-card>
			<v-card
				v-if="edit"
				class="pa-0"
				tile
				:elevation="0"
			>
				<v-card-title 
					class="pa-1 pl-0"
					style="font-size: 1.1em; line-height: 1.3em;"
				>
					Дедлайн:
					<span class="pl-1" style="font-weight: 400;">
						{{ $formatDateTime(form.deadline) }}
					</span>
					<v-spacer />
					<v-btn
						v-if="user.role === 'admin' || (taskFrom && taskFrom.id === user.id)"
						icon
						small
						@click="$emit('update', { deadline: form.deadline })"
					>
						<v-icon small>mdi-pencil</v-icon>
					</v-btn>
				</v-card-title>
			</v-card>
			<v-card
				v-if="edit"
				class="pa-0"
				tile
				:elevation="0"
			>
				<v-card-title 
					class="pa-1 pl-0"
					style="font-size: 1.1em; line-height: 1.3em;"
				>
					Статус:
					<span class="pl-1" style="font-weight: 400;">
						<template v-if="task && task.archive">В архиве</template>
						<template v-else-if="task && task.print">В печать</template>
						<template v-else-if="!task || !task.accepted">Ожидает</template>
						<template v-else-if="task && task.ready">Готово</template>
						
						<template v-else>В работе</template>
						
					</span>
				</v-card-title>
			</v-card>
			<v-card v-if="!meTask" class="pa-0" tile :elevation="0">
				<v-card-title 
					class="pa-1 pl-0"
					style="font-size: 1.1em; line-height: 1.3em;"
				>
					Участники:
					<v-spacer />
					<v-menu
						v-if="!edit || (task && !task.archive && (user.role === 'admin' || (taskFrom && taskFrom.id === user.id)))"
						bottom
						left
						:close-on-content-click="false"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								icon
								small
								v-bind="attrs"
								v-on="on"
							>
								<v-icon small>mdi-pencil</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item
								v-for="(item, index_item) in users"
								:key="index_item"
								dense
							>
								<v-list-item-avatar class="my-0">
									<my-mini-avatar :user="item"/>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title v-text="item.displayName" />
								</v-list-item-content>
								<v-list-item-action>
									<v-checkbox 
										color="rgb(224, 166, 119)"
										:input-value="form.whoTo.includes(item.id)"
										@change="$emit('changeWhoTo', item.id)"
									/>
								</v-list-item-action>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-card-title>
				<span v-if="form.whoTo.length === 0">
					Укажите участников
				</span>
				<div class="d-flex flex-wrap">
					<div v-if="taskFrom && form.whoTo.length > 0" class="pa-1 pl-0 pr-2">
						<my-mini-avatar
							:user="taskFrom"
						/>
					</div>
					<template v-if="form.whoTo.length > 0">
						<template v-for="(item, index) in form.whoToData">
							<div 
								v-if="!taskFrom || taskFrom && item != taskFrom.id"
								:key="`${index}_${item}`"
								class="pa-1 pl-0 pr-2"
							>
								<my-mini-avatar
									:user="item"
								/>
							</div>
						</template>
					</template>
				</div>
			</v-card>
		</div>
		<div style="width: 100%;">
			
			<v-btn
				v-if="task && !task.ready && (form.whoTo.includes(user.id) || user.role === 'admin')"
				width="100%"
				color="green"
				class="mt-2 white--text"
				@click="$emit('update', { ready: true, print: false })"
			>
				<v-icon left>
					mdi-check
				</v-icon>
				Готово
			</v-btn>
			<template v-if="!edit || task && !task.archive && (task.ready && (user.role === 'admin' || (taskFrom && taskFrom.id === user.id)))">
			<v-btn
				
				block
				color="blue"
				class="mt-2 white--text"
				@click="$emit('update', { ready: false })"
			>
				<v-icon left>
					mdi-timer-cog-outline
				</v-icon>
				В работу
			</v-btn>
			
		</template>
		<v-btn
				v-if="design && (!edit || task && !task.archive && (task.ready && (user.role === 'admin' || (taskFrom && taskFrom.id === user.id))))"
				block
				color="#6f8198"
				class="mt-2 white--text"
				@click="$emit('update', { ready: false, print: true })"
			>
				<v-icon left>
					mdi-printer-outline
				</v-icon>
				В печать
			</v-btn>
			<v-btn
				v-if="edit && task && task.ready && !task.archive && (user.role === 'admin' || (taskFrom && taskFrom.id === user.id))"
				block
				color="#bc4037"
				class="mt-2 white--text"
				@click="$emit('update', { archive: true })"
			>
      
				<v-icon left>
					mdi-archive
				</v-icon>
				В архив
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		'task',
		'user',
		'taskFrom',
		'form',
		'edit',
		'users',
		'numberOrder',
		'design',
		'meTask'
	],
	created(){

	}
}
</script>