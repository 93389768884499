<template>
  <div class="brig pa-3">
    <SocketWatch :tables="['brigs']" @loadData="loadData" />
    <v-card>
      <v-simple-table :dense="!isMobile">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Бригада</th>
              <th class="text-left">Район</th>
              <th class="text-left">Монтажники</th>
              <th class="text-right">Действия</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(brig, index_id) in brigs" :key="index_id">
              <td>{{ brig.displayName }}</td>
              <td>{{ brig.location  }}</td>
              <td>
                <span v-if="brig.users_data && brig.users_data.length > 0">
                  <span v-for="(user, index_user) in brig.users_data" :key="index_user">
                    {{ user.displayName }}<!--
                    --><span v-if="index_user + 1 < brig.users_data.length">, </span>
                  </span>
                </span>
                <span v-else>Не назначены</span>
              </td>
              <td class="text-right" style="">
                <v-btn @click="editBrig(brig)" icon small color="#424242">
                  <v-icon size="18">mdi-pencil</v-icon>
                </v-btn>
                <v-btn @click="deleteBrig(brig)" icon small color="#bf4037">
                  <v-icon size="18">mdi-trash-can-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn small @click="createBrig()">
          <v-icon>mdi-account-multiple-plus</v-icon>
          <span class="pl-2">Добавить бригаду</span>
        </v-btn>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import Brig from  '@/components/Modals/Brig/Index'
import Confirm from  '@/components/Modals/Confirm'

export default {
  name: 'Usres',
  components: {},
  data: () => ({
    isMobile: isMobile,
    error: null
  }),
  async created() {
    this.loadData()
  },
  computed: {
    brigs() {
      return this.$store.getters['brigs/getBrigs']
    },
    users() {
      return this.$store.getters['users/getUsers']
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch('brigs/loadBrigs')
      this.$store.dispatch('users/loadUsers')
    },
    async editBrig(brig) {
      const editBrig = await this.$showModal({
        component: Brig,
        isPersistent: true,
        props: {
          edit: true,
          data: brig
        }
      })
      if (editBrig) {
        this.$store.dispatch('brigs/updateBrig', {
          id: brig.id,
          data: editBrig
        })
      }
    },
    async createBrig() {
      const createBrig = await this.$showModal({
        component: Brig,
        isPersistent: true
      })

      if (createBrig) {
        await this.$store.dispatch('brigs/createBrig', createBrig)
        this.$store.dispatch('brigs/loadBrigs')
      }
    },
    async deleteBrig(brig) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление бригады`,
          text: `Действительно хотите удалить бригаду "${brig.displayName}"?`
        }
      })

      if (res) {
        await this.$store.dispatch('brigs/deleteBrig', brig)
        this.$store.dispatch('brigs/loadBrigs')
      }
    }
  }
}
</script>
