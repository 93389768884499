<template>
	<v-card
		min-height="100"
		width="300"
		class="pa-2 px-3"
	>
		<SocketWatch :tables="['clients']" @loadData="loadData" />
		<v-container 
			v-if="loading && !client"
			style="height: 200px;"
			class="pa-0 d-flex justify-center align-center"
		>
			<v-progress-circular
				indeterminate
				color="rgb(224, 166, 119)"
			/>
		</v-container>
		<v-container class="pa-0" v-else>
			<v-list-item two-line v-if="client.name">
				<v-list-item-content>
					<v-list-item-title>Имя клиента</v-list-item-title>
					<v-list-item-subtitle>{{ client.name }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item two-line v-if="client.surname">
				<v-list-item-content>
					<v-list-item-title>Фамилия</v-list-item-title>
					<v-list-item-subtitle>{{ client.surname }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item two-line v-if="client.entity">
				<v-list-item-content>
					<v-list-item-title>Юрлицо</v-list-item-title>
					<v-list-item-subtitle>{{ client.entity }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item two-line v-if="client.phone">
				<v-list-item-content>
					<v-list-item-title>Телефон</v-list-item-title>
					<v-list-item-subtitle>{{ client.phone }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item two-line v-if="client.path">
				<v-list-item-content>
					<v-list-item-title>Расположение макетов</v-list-item-title>
					<v-list-item-subtitle>{{ client.path }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item two-line v-if="client.comment">
				<v-list-item-content>
					<v-list-item-title>Комментарий</v-list-item-title>
					<v-list-item-subtitle>{{ client.comment }}</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
			<v-list-item two-line v-if="client.address && client.address.length > 0">
				<v-list-item-content>
					<v-list-item-title>Адреса</v-list-item-title>
					<v-list-item-subtitle
						v-for="(adress, index_adress) in client.address"
						:key="index_adress"
					>
						{{ adress }}
					</v-list-item-subtitle>
				</v-list-item-content>
			</v-list-item>
		</v-container>
	</v-card>
</template>

<script>
import { clientsCollection } from '@/plugins/firebase'
import api from '@/utils/api'
export default {
	props: ['id'],
	data: () => ({
		loading: true,
		client: null
	}),
	async created() {
		this.loadData()
	},
	methods: {
		async loadData() {
			if (this.id) {
				// let queryStore = clientsCollection.doc(this.id)
				let queryStore = await api.get('clients/get',this.id)
				// let res = await queryStore.get()
				let res = queryStore.data.data
				// this.client = res.data()
				this.client = res
				this.loading = false
			}
		}
	}
}
</script>