<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center pb-0">
      <my-mini-avatar :user="user" />
      <v-text-field
        dense
        color="rgb(224, 166, 119)"
        v-model="commentField"
        @keyup.enter="newComment"
        class="ml-3 pr-4"
        placeholder="Напишите комментарий..."
        append-icon="mdi-attachment"
        append-outer-icon="mdi-keyboard-return"
        @click:append="attachmentFile"
        @click:append-outer="newComment"
      />
    </v-col>
    <div style="display: none;" ref="file"></div>
    <v-col cols="12" class="py-0">
      <v-container fluid class="pa-0">
        <v-row>
          <v-col
            v-for="(image, index_image) in filesCommentField"
            :key="index_image"
            class="d-flex child-flex"
            cols="3"
          >
            <my-preview-image :fbPath="image" @delete="deleteImage" />
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import imageCompression from 'browser-image-compression'
import api from '@/utils/api'
const fileUrl = process.env.VUE_APP_FILE_URL
import { mapActions } from 'vuex'
export default {
  props: ['item_id', 'item'],
  data: () => ({
    commentField: '',
    filesCommentField: [],
    compressedFile: null,
    files: null,
  }),
  methods: {
    ...mapActions('notify', ['notify']),
    async deleteImage(path) {
      let fileIndex = this.filesCommentField.findIndex(x => x === path)
      if (fileIndex != -1) {
        // let delFile = await this.$delFbFile(path)
        // if (delFile) {
          this.filesCommentField.splice(fileIndex, 1)
        // }
      }
    },
    async attachmentFile() {
      const input = document.createElement("input")
      input.type = "file";
      input.accept='image/*'
      input.multiple='multiple'

      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight: 1520,
        useWebWorker: true
      }

      input.onchange = async (e) => {
          var files = await e.target.files
          this.files = files
          for(let key in files) {
            let file = files[key]
            if (file && file instanceof File) {
             
              let compressedFile = await imageCompression(file, options)
              this.compressedFile = compressedFile
              // let filePath = `task/comment/${new Date().getTime()}_${key}.${/[^.]+$/.exec(file.name)}`
              this.uploadingImage = true
              let filePath = await api.upload('upload', compressedFile).then(response => {
                if(response.status == 200){
                 
                  this.uploadingImage = false
                  return `${fileUrl}/${response.data.url}`
                }
              })
              // this.form.images.push(filePath)
              this.filesCommentField.push({
                  uploading: true,
                  filePath: filePath
                })
  
              // let ref = this.$storage.ref().child(filePath).put(compressedFile)
              // ref.on(`state_changed`, snapshot => {}, error => {
              //   console.log(error)
              // }, () => {
                let fileIndex = this.filesCommentField.findIndex(x => x.uploading && x.filePath === filePath)
                if (fileIndex != -1) {
                  this.$set(this.filesCommentField, fileIndex, filePath)
                }
              // })
            }
          }
      };
      this.$refs.file.appendChild(input)
      input.click();
    },
    async newComment() {
      let obj = {
        text: this.commentField,
        files: String(this.filesCommentField),
        date: new Date,
        // from: usersCollection.doc(this.user.uid),
        from_id: this.user.id,
        manufacturer_id: this.item_id,
        program: false
      }

      // await manufactoryCollection.doc(this.item_id).collection('comments').add(obj)
      await api.post('comments/create',obj).then(response => {
        if(response.status == 200 && response.data.status){
          let comment = response.data.comments
          comment.files = comment.files ? comment.files.split(',') : []
          this.$store.commit('comments/ADD_COMMENT',comment)
          this.$emit('load', comment)
          this.sendNotif(this.commentField)
        }
      })

      this.commentField = ''
      this.filesCommentField = []
     
    },

    async sendNotif(comment) {
			let manager = this.item.manager.id
      let prodDirs = this.$store.getters['users/getProdDirs'].map(({id}) => id)
			let whoTo = [manager, ...prodDirs, this.item.montagers].map((id) => Number(id))
			let indexIID = whoTo.findIndex(x => x === this.user.id)
			if (indexIID != -1) {
				whoTo.splice(indexIID, 1)
			}
			let title = `${this.user.displayName} написал комментарий`
			let text = `Производство ${this.item.client.name}: '${comment}'`

			if (whoTo && whoTo.length > 0) {
        console.log(whoTo)
				for (let to of whoTo) {
         if (to) {
         
          await this.notify({
            to,
						title: title,
						text: text,
						action: 'newCommentToManufactory',
						payload: {
							taskId: this.item_id
						}
					})
         }
					
				}
			}
		}
  },

  computed: {
    user() {
      return this.$store.getters['user/getUser']
    }
  }
}
</script>