
// Main
import ViewHome from '@/views/home/ViewHome.vue'
import Home from '@/components/PagesByRole/Manager/Home.vue'

// Montages
import ViewMontages from '@/views/montages/ViewMontages.vue'
import UnassignedMontages from '@/components/Montages/Pages/UnassignedMontages.vue'
import AssignedMontages from '@/components/Montages/Pages/AssignedMontages.vue'
import Montages from '@/components/Montages/Pages/Montages.vue'

// Manufactory
import ManufactoryViewTasks from '@/views/manufactory/ViewTasks.vue'
import ManufactoryTasks from '@/components/Manufactory/Pages/Tasks.vue'
import ManufactoryArchiveTasks from '@/components/Manufactory/Pages/ArchiveTasks.vue'

// Tasks
import ViewTasks from '@/views/tasks/ViewTasks.vue'
import Tasks from '@/components/Tasks/Pages/Index.vue'
import TasksArchive from '@/components/Tasks/Pages/TasksArchive.vue'

// Orders
import ViewOrders from '@/views/orders/ViewOrders.vue'
import Orders from '@/components/Orders/Pages/Index.vue'
import OrdersDraft from '@/components/Orders/Pages/OrdersDraft.vue'
import OrdersArchive from '@/components/Orders/Pages/OrdersArchive.vue'

// Clients
import ViewClients from '@/views/clients/ViewClients.vue'
import Clients from '@/components/Clients/Pages/Index.vue'

// Users
import ViewUsers from '@/views/users/ViewUsers.vue'
import User from '@/components/Users/Pages/User/Index.vue'

// Faq
import ViewFaq from '@/views/faq/ViewFaq.vue'
import Faq from '@/components/Faq/Pages/Index.vue'

import Warehouse from '@/components/Warehouse/Pages/index.vue'

const managerRoutes = [
  {
    path: '/',
    component: ViewHome,
    meta: {
      order: 0,
      navDrawer: true,
      text: 'Главная',
      icon: 'mdi-home'
    },
    children: [
      { path: '', name: 'Home', component: Home },
    ]
  },
	{
    path: '/tasks',
    component: ViewTasks,
    meta: {
      order: 1,
      navDrawer: true,
      text: 'Задачи',
      icon: 'mdi-message-text-clock-outline',
			tabs: [
				{ path: '/tasks', text: 'Задачи мне' },
				{ path: '/tasks/from-me', text: 'От меня' },
				{ path: '/tasks/designer', text: 'Дизайнерам' },
        { path: '/tasks/archive', text: 'Архив' },
      ]
    },
    children: [
      { path: '', name: 'TasksMe', component: Tasks },
      { path: 'from-me', name: 'TasksFromMe', component: Tasks },
      { path: 'designer', name: 'TasksDesigner', component: Tasks },
      { path: 'archive', name: 'TasksArchive', component: TasksArchive }
    ]
  },
  
  {
    path: '/montages',
    component: ViewMontages,
    meta: {
      order: 4,
      navDrawer: true,
      text: 'Монтажи',
      icon: 'mdi-truck-minus',
      tabs: [
        { path: '/montages', text: 'Не назначенные' },
        { path: '/montages/assigned', text: 'Назначенные' },
        { path: '/montages/all', text: 'Все монтажи' }
      ]
    },
    children: [
      { path: '', name: 'UnassignedMontages', component: UnassignedMontages },
      { path: 'assigned', component: AssignedMontages },
      { path: 'all', component: Montages },
    ]
  },
  {
    path: '/manufactory',
    component: ManufactoryViewTasks,
    meta: {
      order: 3,
      navDrawer: true,
      text: 'Производство',
      icon: 'mdi-archive-cog',
      tabs: [
        { path: '/manufactory', text: 'В процессе' },
        { path: '/manufactory/archive', text: 'Архив' },
      ]
    },
    children: [
      { path: '', name: 'Manufactory', component: ManufactoryTasks },
      { path: 'archive', name: 'ManufactoryArchive', component: ManufactoryArchiveTasks },
    ]
  },
  // {
  //   path: '/manufactory/archive',
  //   name: 'ManufactoryReady',
  //   component: ArchiveTasks,
  //   meta: {
  //     navDrawer: false,
  //     text: 'Архив заданий цеха',
  //     icon: 'mdi-archive-cog'
  //   }
  // },
  {
    path: '/clients',
    component: ViewClients,
    meta: {
      order: 5,
      navDrawer: true,
      text: 'Клиенты',
      icon: 'mdi-account-cash'
    },
    children: [
      { path: '', name: 'Clients', component: Clients }
    ]
  },
	{
    path: '/orders',
    component: ViewOrders,
    meta: {
      order: 2,
      navDrawer: true,
      text: 'Заказы',
      icon: 'mdi-clipboard-multiple-outline',
      tabs: [
				{ path: '/orders', text: 'Текущие' },
        // { path: '/orders/draft', text: 'Предварительные' },
        { path: '/orders/archive', text: 'Архив' },
      ]
    },
    children: [
      { path: '', name: 'Orders', component: Orders },
      { path: 'draft', name: 'OrdersDraft', component: OrdersDraft },
      { path: 'archive', name: 'OrdersArchive', component: OrdersArchive }
    ]
  },
	{
    path: '/profile',
    component: ViewUsers,
    meta: {
      order: 5,
      navDrawer: true,
      text: 'Финансы',
      icon: 'mdi-account'
    },
    children: [
      { path: '', name: 'Profile', component: User },
    ]
  },
  {
    path: '/warehouse',
    component: Warehouse,
    name: 'Warehouse',
    meta: {
      order: 7,
      navDrawer: true,
      text: 'Склад',
      icon: 'mdi-shipping-pallet',
			// tabs: [
			// 	{ path: '/tasks', text: 'Задачи мне' },
      // ]
    },
  },
	{
    path: '/faq',
    component: ViewFaq,
    meta: {
      order: 6,
      navDrawer: true,
      text: 'Помощникус',
      icon: 'mdi-book-open-variant'
    },
    children: [
      { path: '', name: 'Faq', component: Faq }
    ]
  }
]

export default managerRoutes
