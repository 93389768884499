<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="!isMobile ? '500px' : ''"
      transition="dialog-bottom-transition"
      :scrollable="!isMobile"
      :persistent="!isMobile"
    >
      <v-card :tile="isMobile" class="rl-modal">
        <v-toolbar v-if="isMobile" color="rgb(224, 166, 119)" tile style="position: sticky; top: 0px; z-index: 20;">
          <v-toolbar-title v-if="edit" class="white--text">Редактировние пользователя</v-toolbar-title>
          <v-toolbar-title v-else class="white--text">Создание пользователя</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title v-else class="rl-modal_header">
          <span v-if="edit" class="headline">Редактирование пользователя</span>
          <span v-else class="headline">Создание пользователя</span>
          <v-spacer />
          <v-btn icon color="rgb(224, 166, 119)" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container :class="{ 'pa-0': isMobile }">
            <v-form ref="formCreateUser">
              <v-row>
                <v-col cols="12" class="py-0 pb-4">
                  <v-row>
                    <v-col cols="4" class="py-0">
                      <Avatar v-model="form.avatar" />
                    </v-col>
                    <v-col cols="8" class="py-0">
                      <v-row>
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            :dense="!isMobile"
                            color="rgb(224, 166, 119)"
                            v-model="form.displayName"
                            label="Имя пользователя*"
                            :rules="$fieldRules.field"
                            required
                          />
                        </v-col>
                        <v-col cols="12" class="py-0">
                          <v-text-field
                            :dense="!isMobile"
                            color="rgb(224, 166, 119)"
                            v-model="form.email"
                            label="Email*"
                            suffix="@m4r.ru"
                            :rules="$fieldRules.field"
                            :error-messages="errors.email"
                            @input="errors.email = []"
                            required
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-select
                    :dense="!isMobile"
                    color="rgb(224, 166, 119)"
                    v-model="form.location"
                    :items="locations"
                    item-text="name"
                    label="Район*"
                    :rules="$fieldRules.field"
                    required
                  />
                </v-col>
                <v-col cols="12" sm="6" class="pb-0">
                  <v-select
                    :dense="!isMobile"
                    color="rgb(224, 166, 119)"
                    item-color="black"
                    v-model="form.role"
                    :items="userRoles"
                    label="Роль сотрудника*"
                    required
                    @change="changeRole"
                  />
                </v-col>
                <v-col cols="12" sm="6" class="pb-0">
                  <v-text-field 
                    :dense="!isMobile"
                    color="rgb(224, 166, 119)"
                    v-model="form.password"
                    label="Пароль*"
                    :rules="passwordRule"
                    required
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    @click:append="showPass = !showPass"
                  />
                </v-col>
                <v-col cols="12" class="pa-0">
                  <v-row class="ma-0">
                    <v-col cols="12" sm="3" class="pb-0">
                      <v-text-field
                        :disabled="rateDisabled"
                        color="rgb(224, 166, 119)"
                        v-model.number="form.rate"
                        type="number"
                        suffix="р."
                        label="Норма час"
                        :rules="['master', 'prodDir'].includes(form.role) ? $fieldRules.field : []"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="3" class="pb-0">
                      <v-text-field
                        :disabled="!['master'].includes(form.role)"
                        color="rgb(224, 166, 119)"
                        v-model.number="form.rateOnManufactory"
                        type="number"
                        suffix="р."
                        label="Час в производстве"
                        :rules="['master'].includes(form.role) ? $fieldRules.field : []"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="3" class="pb-0">
                      <v-text-field
                        :disabled="!['master'].includes(form.role)"
                        color="rgb(224, 166, 119)"
                        v-model.number="form.rateOnMontage"
                        type="number"
                        suffix="р."
                        label="Час на монтаже"
                        :rules="['master'].includes(form.role) ? $fieldRules.field : []"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="3" class="pb-0">
                      <v-text-field
                        :disabled="!['master'].includes(form.role)"
                        color="rgb(224, 166, 119)"
                        v-model.number="form.rateOverwork"
                        type="number"
                        suffix="р."
                        label="Час переработки"
                        :rules="['master'].includes(form.role) ? $fieldRules.field : []"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-col>
								<v-col cols="12" class="pt-0">
									<v-subheader style="height: auto;" class="pa-0">Ежемесячные выплаты:</v-subheader>
									<v-form ref="formNewMonthlyPayments" class="d-flex align-center" style="width: 100%;">
										<v-row no-gutters class="pa-0">
											<v-col class="pa-0 pr-2" cols="9">
												<v-text-field
													:dense="!isMobile"
													color="rgb(224, 166, 119)"
													v-model="newMonthlyPayments.title"
													placeholder="За что выплата"
													:rules="$fieldRules.field"
													required
												/>
											</v-col>
											<v-col class="pa-0 pl-2" cols="3">
												<v-text-field
													:dense="!isMobile"
													color="rgb(224, 166, 119)"
													v-model="newMonthlyPayments.amount"
													placeholder="Сумма"
													:rules="$fieldRules.cost"
													required
													type="number"
												/>
											</v-col>
										</v-row>
										<v-btn icon small class="ml-3" @click="addNewMonthlyPayments()">
											<v-icon size="22">mdi-keyboard-return</v-icon>
										</v-btn>
									</v-form>
									<v-divider v-if="form.monthlyPayments && form.monthlyPayments.length > 0" />
									<v-simple-table v-if="form.monthlyPayments && form.monthlyPayments.length > 0" :dense="!isMobile">
										<template v-slot:default>
											<tbody>
												<tr
													v-for="payment in form.monthlyPayments"
													:key="payment.id"
												>
													<td class="px-1 text-left" style="width: 70%;">{{ payment.title }}</td>

													<td class="px-1 text-center">{{ payment.amount }}р.</td>

													<td class="px-1 text-right">
														<v-btn @click.stop="deletePayment(payment.id)" icon small color="#bf4037">
															<v-icon size="18">mdi-trash-can-outline</v-icon>
														</v-btn>
													</td>
												</tr>
											</tbody>
										</template>
									</v-simple-table>
								</v-col>
                <v-col cols="12" class="pt-0">
                  <v-subheader style="height: auto;" class="pa-0 pb-2">Может:</v-subheader>
                  <v-row class="ma-0">
                     <v-col cols="6" class="pa-0">
                      <v-checkbox
                        :dense="!isMobile"
                        color="rgb(224, 166, 119)"
                        :disabled="form.role != 'prodDir'"
                        v-model="form.canAssignMontage"
                        label="Назначать монтажи"
                        class="mt-0 pt-0"
                      />
                    </v-col>
                    <v-col cols="6" class="pa-0">
                      <v-checkbox
                        :dense="!isMobile"
                        color="rgb(224, 166, 119)"
                        :disabled="form.role !== 'master'"
                        v-model="form.warehouseManager"
                        label="Заведующий складом"
                        class="mt-0 pt-0"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="orange" text @click="save()">
            <span v-if="edit">Сохранить</span>
            <span v-else>Создать</span>
          </v-btn>
        </v-card-actions>
       
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Avatar from './Components/Avatar.vue'
import { isMobile } from 'mobile-device-detect';
import { RoleText } from '@/helpers'

export default {
  components: {
    Avatar
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => ({
    isMobile: isMobile,
    roleText: RoleText,
    dialog: true,
    showPass: false,
    errors: {
      email: []
    },
    form: {
      avatar: null,
      displayName: '',
      email: '',
      role: 'master',
      location: null,
      password: '',
      rate: 0,
      rateOnManufactory: 0,
      rateOnMontage: 0,
      rateOverwork: 0,
      canAssignMontage: false,
      canConfirmTask: false,
      warehouseManager: false,
			monthlyPayments: []
    },
		newMonthlyPayments: {
			title: '', amount: ''
		},
  }),
  computed: {
    userRoles() {
      const arrUserRoles = []
      for (let key in this.roleText) {
        arrUserRoles.push({ value: key, text: this.roleText[key] })
      }
      return arrUserRoles
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    passwordRule() {
      return [
        v => {
          if (!this.edit) {
            if (v && v.length >= 6) {
              return true
            } else {
              return 'Длина пароля не менее 6 символов'
            }
          } else {
            return true
          }
        }
      ]
    },
    hourPayWorkMasters() {
      return this.$store.getters['settings/stngHourPayWorkMasters']
    },
    rateDisabled() {
      if (this.hourPayWorkMasters && this.form.role === 'master') {
        return true
      }
      return !['master', 'prodDir'].includes(this.form.role)
    }
  },
  created() {
    // console.log('this.data.monthlyPayments',this.data.monthlyPayments)
    if (this.data && this.data.id) {
      this.form.avatar = this.data.avatar
      this.form.displayName = this.data.displayName
      this.form.email = this.data.email && this.data.email.includes('@') ? this.data.email.split('@')[0] : this.data.email
      this.form.location = this.data.location
      this.form.role = this.data.role
      this.form.rate = this.data.rate ? this.data.rate : 0
      this.form.rateOnManufactory = this.data.rateOnManufactory ? this.data.rateOnManufactory : 0
      this.form.rateOnMontage = this.data.rateOnMontage ? this.data.rateOnMontage : 0
      this.form.rateOverwork = this.data.rateOverwork ? this.data.rateOverwork : 0
      this.form.warehouseManager = this.data.warehouseManager != undefined ? this.data.warehouseManager : false
      this.form.canAssignMontage = this.data.canAssignMontage != undefined ? this.data.canAssignMontage : false
      this.form.canConfirmTask = this.data.canConfirmTask != undefined ? this.data.canConfirmTask : false
      this.form.monthlyPayments = this.data.monthlyPayments != undefined ? JSON.parse(this.data.monthlyPayments) : []
    }
  },
  methods: {
		deletePayment(id) {
			let indexWork = this.form.monthlyPayments.findIndex(x => x.id === id)
			if (indexWork != -1) {
				this.form.monthlyPayments.splice(indexWork, 1)
			}
		},
		addNewMonthlyPayments() {
			const formRef = this.$refs.formNewMonthlyPayments
			let validate = formRef.validate()
			if (validate) {
				this.form.monthlyPayments.push({
					...this.newMonthlyPayments,
					id: Date.now()
				})
				this.newMonthlyPayments = {
					title: '',
					amount: ''
				}
				formRef.resetValidation()
			}
		},
    changeRole(role) {
      if (role != 'prodDir') {
        this.form.canAssignMontage = false
        this.form.canConfirmTask = false
      }
    },
    async getChanges() {
      let obj = {}
      for (let key in this.form) {
        if (key === "password" && this.form[key].length > 0) {
          obj[key] = this.form[key]
        } else {
          if ((this.form[key] === null || this.form[key] != undefined) && (this.form[key] != this.data[key])) {
            if (key === 'avatar' && ((this.form[key] === null && this.data[key]) || (this.form[key] && this.data[key] && this.form[key] != this.data[key]))) {
              // await this.$delFbFile(this.data[key])
            }
            obj[key] = this.form[key]
          }
        }
      }
      if (obj.email) {
        obj.email = `${obj.email}@m4r.ru`
      }

      console.log(obj)
      return obj
    },
    async save() {
      const errors = []
      const validate = await this.$refs.formCreateUser.validate()
      errors.push(validate)

      if (!this.edit && `${this.form.email}@m4r.ru` != this.data.email) {
        const checkEmail = await this.$store.dispatch('users/checkExistsEmail', `${this.form.email}@m4r.ru`)
        errors.push(checkEmail)
        if (!checkEmail) {
          this.errors.email.push('E-mail уже используется')
        }
      }

      if (!errors.includes(false)) {
        if (!this.edit) {
          this.$store.dispatch('users/createUser', {
            ...this.form,
            email: `${this.form.email}@m4r.ru`
          })
          this.$emit('close')
        } else {
          this.$store.dispatch('users/updateUser', {
            id: this.data.id,
            data: await this.getChanges()
          })
          this.$emit('close')
        }
      }
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.$emit('close')
      }, 200)
    }
  }
}
</script>
