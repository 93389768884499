<template>
  <v-app style="background:#424242;">
    <Navbar />
    <NavigationDrawer />
    <v-main>
      <router-view />
    </v-main>
    <ShowAlert v-if="!$isMobile" />
  </v-app>
</template>

<script>
  import Navbar from "@/components/Templ/Navbar"
  import NavigationDrawer from "@/components/Templ/NavigationDrawer"
  import ShowAlert from "@/components/Notifications/ShowAlert.vue"

  import ShowImage from '@/components/Modals/ShowImage.vue'
  import ShowImageMob from '@/components/Modals/ShowImageMob.vue'
  import Vue from 'vue'
  import {
    mapActions
  } from 'vuex'
  export default {
    name: 'App',
    components: {
      Navbar,
      NavigationDrawer,
      ShowAlert,
    },

    data: () => ({
      //
    }),
    async created() {
      if (this.user) {
        setTimeout(await this.loadData(), 0)
      }
      Vue.prototype.$showImage = function (imgUrl, images) {
        this.$showModal({
          component: ShowImage,
          isPersistent: false,
          props: {
            image: imgUrl,
            images: images ? images : null
          }
        })
      }

      Vue.prototype.$showImageMob = function (imgUrl, images) {
        this.$showModal({
          component: ShowImageMob,
          isPersistent: false,
          props: {
            image: imgUrl,
            images: images ? images : null
          }
        })
      }
      this.socketInit()
    },

    beforeDestroy() {
      this.closeSocket()
    },
    methods: {
      ...mapActions('socket', ['socketInit']),
      async loadData() {
        // this.$store.dispatch('settings/updSettings', {hourPayWorkMasters: true})
        this.$store.dispatch('settings/loadSettings')
        await this.$store.dispatch('users/loadUsers')
        // if (this.user.role != 'master') {
        await this.$store.dispatch('locations/loadLocations')
        await this.$store.dispatch('brigs/loadBrigs')
        await this.$store.dispatch('clients/loadClients')
        // }
      }
    },
    computed: {
      clients() {
        return this.$store.getters['clients/getClients']
      },
      locations() {
        return this.$store.getters['locations/getLocations']
      },
      brigs() {
        return this.$store.getters['brigs/getBrigs']
      },
      user() {
        return this.$store.getters['user/getUser']
      },
      users() {
        return this.$store.getters['users/getWorkers']
      },
    },
    watch: {
      async user(newVal, oldVal) {
        // console.log('watch',newVal, oldVal)
        if (oldVal === null && newVal) {
          await this.loadData()
        }
      }
    }
  };
</script>

<style lang="scss">
  .vmp__app {
    display: none;
  }
</style>