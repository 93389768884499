<template>
  <v-card>
    <v-card-title class="userTableTitle">
      <div style="font-weight: 400;">Удержания</div>
      <v-spacer></v-spacer>
      <v-btn v-if="currentUser.role === 'admin'" small icon @click="createDeductions()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <div v-if="deductions.length === 0" class="infoNoItems">
      Нет удержаний
    </div>
    <v-simple-table :dense="!$isMobile" v-if="deductions && deductions.length > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Дата</th>
            <th class="text-left">Сумма</th>
            <th class="text-center">Коммент.</th>
            <th v-if="currentUser.role === 'admin'" class="text-center">Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="deduction in deductions"
            :key="deduction.id"
            class="tableItemList"
          >
            <td style="min-width: 80px;">{{ $formatDate(new Date(deduction.date)) }}</td>
            <td>{{ deduction.cost }}р.</td>
            <td class="text-center">
              <v-tooltip v-if="deduction.comment" top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-comment-text-outline
                  </v-icon>
                </template>
                <span>{{ deduction.comment }}</span>
              </v-tooltip>
              <span v-else>Нет</span>
            </td>
            <td v-if="currentUser.role === 'admin'" class="text-center">
              <v-btn @click.stop="deleteItem(deduction)" icon color="#bf4037">
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { usersCollection } from '@/plugins/firebase'
import FinanceComment from '@/components/Modals/FinanceComment.vue'
import Confirm from  '@/components/Modals/Confirm'

export default {
  props: {
    currentUser: {
      type: Object,
      default: {}
    },
    user: {
      type: Object,
      default: {}
    }
  },
  computed: {
    deductions() {
      return this.$store.getters['deductions/getDeductions']
      // return this.user.deductions || []
    }
  },
  methods: {
    async deleteItem(data) {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление удержания`,
          text: `Действительно хотите удалить удержание за ${this.$formatDate(new Date(data.date))}?`
        }
      })

      if (res) {
        await this.$store.dispatch('deductions/deleteDeductions', data)
      }
    },
    async createDeductions() {
      let finObj = await this.$showModal({
        component: FinanceComment,
        isPersistent: false,
        props: {
          title: 'Новое удержание',
          event: 'Удержать'
        }
      })

      if (finObj) {
        this.$store.dispatch('deductions/createDeductions', {
          cost: Number(finObj.summ),
          comment: finObj.comment,
          // user: usersCollection.doc(this.user.uid)
          user_id: this.user.id
        })
      }
    }
  }
}
</script>