<template>
  <v-row class="ma-0 px-1 py-2">
    <v-col cols="12" class="pa-0">
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0 px-3">
          <StateAdmin/>
        </v-col>
        <v-col cols="5" class="py-0">
          <AssignedMontages />
        </v-col>
        <v-col cols="7" class="py-0">
          <DesignTaskDeadline />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import StateAdmin from '@/components/State/StateAdmin.vue'
import AssignedMontages from '@/components/Montages/Widgets/AssignedMontages.vue'
import DesignTaskDeadline from '@/components/Tasks/Widgets/DesignTaskDeadline.vue'

export default {
  name: 'AdminHome',
  components: {
    DesignTaskDeadline,
    AssignedMontages,
    StateAdmin,
  }
}
</script>
