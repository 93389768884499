<template>
  <v-progress-linear
    :active="true"
    :color="color"
    :indeterminate="true"
    class="ma-0"
    height="4"
    :style="`top: ${top}; opacity: ${opacity};`"
  />
</template>

<script>
export default {
  props: {
    loading: Boolean,
    color: {
      type: String,
      default: '#ace2fc'
    },
    top: {
      type: String,
      default: '-2px'
    }
  },
  computed: {
    opacity() {
      return this.loading ? 1 : 0
    }
  }
};
</script>

<style>
.v-progress-linear {
  -moz-transform: scale(1, -1);
  -webkit-transform: scale(1, -1);
  -o-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
</style>