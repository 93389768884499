import axios from 'axios'
const url = process.env.VUE_APP_SERVICE_URL
const token = localStorage['jwt']
const config = {
    headers: {
        'Authorization': token
    },
}

export default {
    async post(service,data){
        let response = await axios.post(`${url}/${service}`,data,{headers: {
            'Authorization': localStorage['jwt']
        }})
        return response
    },
    async get(service,data){
        let response
        if(data){
            response = await axios.get(`${url}/${service}/${data}`,{headers: {
                'Authorization': localStorage['jwt']
            }})
        } else {
            response = await axios.get(`${url}/${service}`,{headers: {
                'Authorization': localStorage['jwt']
            }})
        }
        
        return response
    },
    async put(service,data){
        let response = await axios.put(`${url}/${service}`,data,{headers: {
            'Authorization': localStorage['jwt']
        }})
        return response
    },
    async delete(service,data){
        let response = await axios.delete(`${url}/${service}`,{data: data,headers: {
            'Authorization': localStorage['jwt']
        }})
        return response
    },
    async upload(service,data){
        let form_data = new FormData()
        form_data.append('file',data)
        let response = await axios.post(`${url}/${service}`,form_data,{headers: {
            'Authorization': localStorage['jwt']
        }})
        return response

    }
}