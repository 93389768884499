<template>
  <div :class="{ 'iframe pa-3': !isMobile }">
    <v-card dark :tile="isMobile" >
       <v-card-title v-if="!isMobile">
        <span>{{ data.brig.displayName }}</span>
        <v-spacer></v-spacer>
        <span v-if="user.role === 'admin'">
          <v-btn small v-if="!editOrder" :disabled="data.montages.length === 0" @click.stop="changeOrderMontages()" icon color="#424242">
            <v-icon size="18">mdi-sort</v-icon>
          </v-btn>
          <v-btn small v-if="data.montages.length > 0 && editOrder" @click.stop="saveOrderMontages()" icon color="green">
            <v-icon size="18">mdi-check</v-icon>
          </v-btn>
        </span>
      </v-card-title>
      <v-divider v-if="!isMobile" dark/>
      <div 
        v-if="!data.montages || data.montages.length === 0"
        :class="{ 'mobAssignedMontages_noMontages': isMobile, 'infoNoItems': !isMobile }"
      >
        Нет монтажей
      </div>
      <draggable
        v-if="data.montages.length > 0"
        class="tableMontage"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = false"
        v-model="orderList"
        v-click-outside="clickBlurDrag"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <div
            v-for="montage in editOrder ? orderList : data.montages"
            :key="montage.id"
            @click="!editOrder ? editMontage(montage) : null"
            :class="` unselectable  ${editOrder && isMobile ? 'ma-1' : ''}`"
            v-long-press="800"
            @long-press-start="!editOrder && isMobile ? changeOrderMontages() : null"
          >
            <div class="tableMontage_tr_cont d-flex justify-space-between">
              <div class="tableMontage_tr_cont_status">
                <div v-if="montage.status === 'assigned'" style="background-color: orange;" />
                <div v-if="montage.status === 'work'" style="background-color: blue;" />
                <div v-if="montage.status === 'ready'" style="background-color: green;" />
                <div v-if="montage.status === 'no_work'" style="background-color: #e8443c;" />
              </div>
              <div class="tableMontage_tr_cont_info px-2" :style="`${editOrder ? 'cursor: move;' : ''} ${!isMobile ? 'height: 35px; font-size: 0.78em;' : ''}`">
                <div class="tableMontage_tr_cont_info_container" :style="user.role === 'admin' ? 'width: 86%;' : ''">
                  <span style="min-width: 125px; font-weight: 500; font-size: 1em;">{{ montage.client.name }}</span><!--
                  --><span v-if="!editOrder && montage.tasks" style="font-size: 1em;">,
                      <span v-for="(task, index_task) in montage.tasks" :key="index_task">
                        <span v-if="task.value">
                          <span style="font-weight: 500;">{{ index_task + 1 }}.</span>
                          <span>{{ task.value }}. </span>
                        </span>
                      </span>
                    </span>
                    <span v-if="editOrder">
                      {{ montage.address }}
                    </span>
                </div>
                <!-- <div v-if="user.role === 'admin' && !hourPayWorkMasters" class="tableMontage_tr_cont_info_price">
                  {{ getFullCostMontage(montage) }}
                </div> -->
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
    </v-card>
  </div>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import LongPress from 'vue-directive-long-press'
import ClickOutside from 'vue-click-outside'
import MontageModal from  '@/components/Modals/Montage/Index'
import draggable from 'vuedraggable'
import Confirm from  '@/components/Modals/Confirm'

export default {
  name: 'AssignedMontagesInBrig',
  components: {
    draggable
  },
  directives: {
    'long-press': LongPress,
    ClickOutside
  },
  props: ['data'],
  data: () => ({
    isMobile: isMobile,
    skeletonTypesIframe: {
      'table-thead': 'heading@4',
      'table-row': 'table-cell@4'
    },
    editOrder: false,
    orderList: [],
    drag: false,
    confirmOpen: false
  }),
  created() {},
  methods: {
    getFullCostMontage(montage) {
      let cost = 0
      if (montage.montagersInfo) {
        for (let key in montage.montagersInfo) {
          let montager = montage.montagersInfo[key]
          if (montager.costMontage) {
            cost = Number(cost) + Number(montager.costMontage)
          }
        }
      }
      return `${cost}р.`
    },
    async clickBlurDrag() {
      if (this.editOrder && isMobile && !this.confirmOpen) {
        this.confirmOpen = true
        const res = await this.$showModal({
          component: Confirm,
          isPersistent: true,
          props: {
            title: `Изменение порядка`,
            text: `Действительно хотите изменить порядок для "${this.data.brig.displayName}"?`
          }
        })

        if (res) {
          this.saveOrderMontages()
        } else {
          this.editOrder = false
          this.orderList = null
        }
        this.confirmOpen = false
      }
    },
    async saveOrderMontages() {
      for (let indexMontage in this.orderList) {
        let montage = this.orderList[indexMontage]
        await this.$store.commit('montages/updAssignedMontagesForOrder', { 
          brig: montage.brig.id,
          montage: { id: montage.id, order: indexMontage }
        })

        const  data  = await this.$store.dispatch('montages/updateMontage', {
          id: montage.id,
          data: {
            order: Number(indexMontage) + 1,
          }
        })
        
      }
      this.editOrder = false
      this.orderList = null
      this.$store.dispatch('montages/loadAssignedMontages')
    },
    changeOrderMontages() {
      window.navigator.vibrate([45]);
      this.editOrder = true
      this.orderList = [ ...this.data.montages ]
    },
    showMontage(montage) {
      console.log(montage)
    },
    async editMontage(montage) {
      await this.$showModal({
        component: MontageModal,
        isPersistent: true,
        props: {
          edit: true,
          data: montage
        }
      })
      this.$store.dispatch('montages/loadAssignedMontages')
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: !this.editOrder,
        ghostClass: "ghost"
      }
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    getHourPayWorkMasters() {
      return this.$store.getters['settings/getHourPayWorkMasters']
    },
    hourPayWorkMasters() {
      return this.$store.getters['settings/stngHourPayWorkMasters']
    },
  }
}
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.2s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  // background: #c8ebfb;
}

.mobAssignedMontages {
  &_noMontages {
    display: flex !important;
    justify-content: center !important;
    padding-top: 50% !important;
    color: grey;
    text-transform: uppercase;
  }
}

.tableMontage {
  &_tr {
    background-color: white;

    &_cont {
      &:hover {
        background-color: rgba(199, 199, 199, 0.26);
      }

      &_status {
        width: 0.7%;

        div {
          width: 100%;
          height: 100%;
        }
      }

      &_info {
        width: 99.3%;
        display: flex;
        align-items: center;
        box-shadow: inset 0px -0.5px grey;

        &:hover {
          cursor: pointer;
        }

        &_container {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        
        &_price {
          width: 14%;
          padding-left: 4%;
          padding-right: 5px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: right;
          font-size: 0.95em;
        }
      }

      &_edit {
        width: 7%;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: inset 0px -0.5px grey;
      }
    }
  }

  &_tr_pc:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .tableMontage_tr_cont_info, .tableMontage_tr_cont_edit{
      box-shadow: inset 0px 0px grey;
    }

    .tableMontage_tr_cont .tableMontage_tr_cont_status div {
      border-bottom-left-radius: 5px;
    }
  }
}
</style>