import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ruVuetify from 'vuetify/lib/locale/ru'

let changeRu = { ...ruVuetify }
changeRu.dataTable.itemsPerPageText = 'Кол-во строк:'

Vue.use(Vuetify);

export default new Vuetify({
	lang: {
    locales: { ru: changeRu },
    current: 'ru',
  },
});
