<template>
  <div>
    <Montages />
  </div>
</template>

<script>
import Montages from './Components/Montages.vue'

export default {
  name: 'HomeMobPage',
  data: () => ({}),
  components: {
    Montages
  },
  computed: {}
}
</script>

<style lang="scss">
</style>