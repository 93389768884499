<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      :fullscreen="isMobile"
      :max-width="!isMobile ? '500px' : ''"
      transition="dialog-bottom-transition"
      :scrollable="!isMobile"
      :persistent="!isMobile"
    >
      <v-card :tile="isMobile" class="rl-modal">
        <v-toolbar v-if="isMobile" color="rgb(224, 166, 119)" tile style="position: sticky; top: 0px; z-index: 20;">
          <v-toolbar-title v-if="edit" class="white--text">Редактировние монтажа</v-toolbar-title>
          <v-toolbar-title v-else class="white--text">Новый монтаж</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title v-else class="rl-modal_header">
          <span v-if="edit" class="headline">Редактирование монтажа</span>
         
          <span v-else class="headline">Новый монтаж</span>
          <v-spacer />
          <v-btn icon color="rgb(224, 166, 119)" @click="close()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0" :class="{ 'px-2 pt-3': isMobile }">
					<div 
						v-if="form.numberOrder"
						style="font-size: 1.1em; color: black;"
						:class="{'pb-3': !edit}"
					>
						<span style="font-weight: 500;">Номер заказа: </span> 
						<span v-if="!/[a-zA-Z]/g.test(form.numberOrder) && (edit && (user.role === 'admin' || data.manager.id === user.id))" @click="$openOrder(form.numberOrder)" class="rl-link">{{ form.numberOrder }}</span>
						<span v-else>{{ form.numberOrder }}</span>
					</div>
          <div v-if="edit" class="pb-3" style="font-size: 1.1em; color: black;">
            <!-- Менеджер -->
            <span>
              <span style="font-weight: 500;">Менеджер: </span> {{ data.manager.displayName }}.
            </span>

            <!-- Дата -->
            <span>
              <span style="font-weight: 500;">Дата: </span>
              <span>{{ $formatDate(form.date.seconds ? new Date(form.date) : new Date(form.date)) }}</span>
              <v-btn v-if="canEdit" icon color="black" small @click="editDate()" class="ml-1">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </span>

            <!-- Исполнители -->
            <span v-if="form.montagers && (form.montagersInfo && Object.keys(form.montagersInfo).length > 0)">
              <br>
              <span style="font-weight: 500;">Исполнители:</span>
              
              <span 
                v-for="(montager, index_montager) in form.montagersInfo"
                :key="index_montager"
              >
                <span :style="montager.boss ? 'font-weight: 500;' : ''">
                  {{ montager.name }}
                </span>
                <!-- <span v-if="user.role != 'manager' && !getHourPayWorkMasters">({{ montager.costMontage }}р.)</span> -->
                <span v-if="index_montager != Object.keys(form.montagersInfo)[Object.keys(form.montagersInfo).length - 1]">,</span>
              </span>
              <v-btn v-if="user.role === 'admin'" icon color="black" small @click="editPerformers()" class="ml-1">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </span>

            <!-- Статус -->
            <span v-if="data.status">
              <br>
              <span style="font-weight: 500;">Статус: </span> {{ montagesStatus[data.status] }}
            </span>
          </div>

          <v-container class="py-0">
            <v-form ref="formCreateMontage">
              <v-row>
                <v-col cols="12" class="d-flex pt-0">
									<v-autocomplete
										:class="{ 'mr-2': ['admin', 'manager'].includes(user.role) }"
										:disabled="!canEdit"
										ref="clientName"
										:dense="!isMobile"
										color="#e0a677"
										v-model="form.client"
										placeholder="Клиент"
										:items="clients"
										item-text="name"
										return-object
										@change="setMainClientContact()"
									>
										<template v-slot:no-data>
											<v-row no-gutters class="px-2">
												<v-col cols="12" class="d-flex align-center justify-center">
													<v-btn @click="newClient()" color="#e0a677" text small>
														Добавить клиента
													</v-btn>
												</v-col>
											</v-row>
										</template>
									</v-autocomplete>
									<v-btn
										v-if="['admin', 'manager'].includes(user.role)"
										:disabled="!form.client || (form.client && !form.client.name === undefined)"
										@click="showClientInfo()"
										icon
									>
										<v-icon>mdi-account-eye-outline</v-icon>
									</v-btn>
								</v-col>
                <v-col v-if="form.client.length > 0 && !form.client.id" cols="12" class="py-0">
                  <v-row>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                        :dense="!isMobile"
                        ref="newClientSurname"
                        v-model="newClient.surname"
                        color="rgb(224, 166, 119)"
                        label="Фамилия клиента"
                        :rules="$fieldRules.field"
                      />
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                        :dense="!isMobile"
                        ref="newClientPhonenumber"
                        color="rgb(224, 166, 119)"
                        v-model="newClient.phone"
                        v-mask="'+7(###) ##-##-###'"
                        label="Номер телефона"
                        placeholder="+7("
                        :rules="$fieldRules.phone"
                      />
                    </v-col>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        :dense="!isMobile"
                        color="rgb(224, 166, 119)"
                        v-model="newClient.path"
                        label="Расположение макетов клиента"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-combobox
                    :dense="!isMobile"
                    :disabled="!canEdit"
                    color="rgb(224, 166, 119)"
                    v-model="form.address"
                    label="Адрес*"
                    :rules="$fieldRules.field"
                    required
                    :items="address"
                  />
                </v-col>
                <v-col cols="12" class="py-0">
                  <div v-for="(task, task_index) in form.tasks" :key="task_index" class="d-flex">
                    <v-textarea
                      :dense="!isMobile"
                      :readonly="!canEdit"
                      color="rgb(224, 166, 119)"
                      v-model="task.value"
                      :label="'Что делать ' + (task_index+1)"
                      rows="3"
                    />
                    <div v-if="canEdit" style="padding-bottom: 19px;margin-left: 5px;">
                      <div 
                        style="
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          flex-wrap: wrap;
                          width: 30px;
                          background: #ebebeb;
                        "
                      >
                        <v-btn icon small color="blue" @click="addFieldTask()">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn small v-if="form.tasks.length > 1" icon color="red" @click="removeFieldTask(task_index)">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-textarea
                    :dense="!isMobile"
                    :disabled="!canEdit"
                    color="rgb(224, 166, 119)"
                    v-model="form.comment"
                    label="Что брать ссобой"
                    rows="1"
                  />
                </v-col>
                <v-col cols="12" class="py-0 pb-1">
                  <v-btn depressed :disabled="!canEdit" @click="uploadImage()" width="100%" :loading="uploadingImage">
                    <v-icon>mdi-cloud-upload</v-icon>
                    <span class="pl-2">Загрузить фото</span>
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-container fluid class="pa-0">
                    <v-row v-if="images && images.length > 0">
                      <v-col
                        v-for="(image, index_image) in form.images"
												:key="`${index_image}_${image}`"
                        class="d-flex child-flex"
                        cols="4"
                      >
                        <ImageView
													:key="`img_${index_image}_${image}`"
													:disTrach="!canEdit"
													:path="images[index_image]"
													:images="images"
													@delete="deleteImage(image, index_image)"
												/>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <Comments
            v-if="edit"
            :canEdit='canEditCommit'
             
					  :item_id="data.id"
            :item="data"
				  />
        </v-card-text>

        <v-card-actions class="px-7">
          <v-btn v-if="edit && user.role === 'admin' || edit && data.status === 'unassigned' && user.role === 'manager' && data.manager.id === user.id" color="red" text @click="deleteMontage()">Удалить</v-btn>
          <v-btn 
            v-if="edit && (user.role === 'admin' || user.canAssignMontage) && ['assigned', 'work', 'no_work'].includes(data.status)"
            color="red"
            text
            @click="cancelMontage()"
          >Отменить</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="['admin', 'manager'].includes(user.role)" :disabled="!canEdit" color="orange" text @click="save('unassigned')">Сохранить</v-btn>
          <v-btn v-if="(user.role === 'admin' || user.canAssignMontage) && (!data.montagers || (data.montagers && data.montagers.length === 0))" color="blue" text @click="save('assigned')">Назначить</v-btn>
        </v-card-actions>
        <v-card-actions 
          v-if="
            ['master', 'prodDir'].includes(user.role) &&
            data && data.status != 'ready' &&
            data.status != 'no_work' &&
            data.montagers.includes(user.id)
          "
        >
          <v-btn text color="red"
            v-if="data.status === 'work'" @click="updateStatusMontage(data, 'no_work')">Не выполнено</v-btn>
          <v-btn text color="blue" v-else @click="updateStatusMontage(data, 'work')">В работе</v-btn>
          <v-spacer></v-spacer>
          <v-btn 
            text
            color="green"
            v-if="data.status === 'work'"
            @click="updateStatusMontage(data, 'ready')"
            :disabled="data.status !== 'work'"
          >Готово</v-btn>
        </v-card-actions>
      </v-card>
      <div style="display: none;" ref="file"></div>
    </v-dialog>
  </v-row>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import ImageView from '@/components/Image.vue'
import SelectDate from '@/components/Modals/SelectDate'
import SelectPerformers from '@/components/Modals/SelectPerformers'
import Confirm from  '@/components/Modals/Confirm'
import { Role } from '@/helpers'
import { montagesStatus } from '@/helpers'
var cloneDeep = require('lodash.clonedeep');
import imageCompression from 'browser-image-compression'
import api from '@/utils/api'
import Client from  '@/components/Modals/Clients/Index'
import Comments from './components/Comments/Index.vue'
const fileUrl = process.env.VUE_APP_FILE_URL

export default {
  components: {
    ImageView,
    Comments
  },
  props: {
    edit: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {}
      }
    },
		nmbrOrder: {
      type: String,
      default: null
    },
		clientOrder: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    isMobile: isMobile,
    roles: Role,
    montagesStatus: montagesStatus,
    dialog: true,
    form: {
      client: '',
      address: '',
      tasks: [
        { value: '' }
      ],
      comment: '',
      images: [],
      date: new Date().toISOString().substr(0, 10),
      montagers: [],
      montagersInfo: null,
      brig: null,
      priceForBrig: null,
			numberOrder: null
    },
    images: [],
    uploadingImage: false,
  }),
  async created() {
    // if (!this.clients || this.clients.length === 0) {
      this.$store.dispatch('clients/loadClients')
    // }
    
    // if (!this.brigs || this.brigs.length === 0) {
      this.$store.dispatch('brigs/loadBrigs')
    // }

    // if (!this.users || this.users.length === 0) {
      this.$store.dispatch('users/loadUsers')
    // }

		if (this.nmbrOrder) {
			this.form.numberOrder = this.nmbrOrder
		}

		if (this.clientOrder) {
			this.form.client = this.clientOrder
		}
    

    if (this.data && this.data.id) {
      const client = await cloneDeep(this.data.client);
      const contacts = await  JSON.parse(client.contacts);
      console.log(this.data)
      let data = {
        client: {...client, contacts},
        address: this.data.address,
        tasks: this.data.tasks ? await cloneDeep(this.data.tasks) : [ { value: '' } ],
        // tasks: this.data.tasks ? JSON.parse(this.data.tasks) : [ { value: '' } ],
        comment: this.data.comment,
        images: this.data.images ? await cloneDeep(this.data.images) : [],
        date: this.data.date,
        montagers: this.data.montagers ? await cloneDeep(this.data.montagers) : [],
        montagersInfo: this.data.montagersInfo ? await cloneDeep(this.data.montagersInfo) : null,
        // montagersInfo: this.data.montagersInfo ? JSON.parse(this.data.montagersInfo) : null,
        brig: this.data.brig_id ? this.data.brig_id : null,
        priceForBrig: this.data.priceForBrig ? this.data.priceForBrig : null,
				numberOrder: this.data.numberOrder || null
      }
      this.form = data
    }
  },
  computed: {
    canEdit() {
      if (!this.edit) {
        return true
      } else if (this.user.role === 'admin') {
        return true
      } else if (this.user.id === this.data.manager.id && (!this.data.status || ['unassigned', 'assigned'].includes(this.data.status))) {
        return true
      }
    },
    canEditCommit() {
      if (!this.edit) {
        return true
      } else if (this.user.role === 'admin') {
        return true
      } else if (this.user.id === this.data.manager.id && (!this.data.status || ['unassigned', 'assigned', 'work'].includes(this.data.status))) {
        return true
      }
    },
    user() {
      return this.$store.getters['user/getUser']
    },
    users() {
      return this.$store.getters['users/getWorkers']
    },
    brigs() {
      return this.$store.getters['brigs/getBrigs']
    },
    address() {
      const client = this.form.client
      if (client && client.id && (client.address && client.address.length > 0)) {
        return client.address
      }
      return []
    },
    clients() {
      return this.$store.getters['clients/getClients']
    },
    getHourPayWorkMasters() {
      return this.$store.getters['settings/getHourPayWorkMasters']
    },
  },
  watch: {
    form: {
      deep: true,
      async handler(newValue) {
        if (newValue.images.length != this.images.length) {
          // let images = []
          // if (this.form.images && this.form.images.length > 0) {
          //   for (let key in this.form.images) {
          //     let imgUrl = await this.$getFbFileUrl(this.form.images[key])
          //     images.push(imgUrl)
          //   }
          // }
          this.images = newValue.images
        }
      }
    }
  },
  methods: {
    // loaded() {
    //     this.$store.dispatch('montages/loadUnassignedMontages')
    //     this.$store.dispatch('clients/loadClients')
    //     this.$store.dispatch('users/loadManagers')
    // },
    async showClientInfo() {

      // alert("showClientInfo")
      // console.log(this.form.client, 'this.form.client')

			const client = await this.$showModal({
        component: Client,
        isPersistent: true,
				props: {
					edit: true,
					data: this.clients.find(({id}) => id === this.data.client_id)
				}
      })
			if (client) {
				this.form.client = { ...this.form.client, id: client.id, ...client.data }
			}
		},
		async newClient() {
			let clientNameRef = this.$refs.clientName.internalSearch

			const createClient = await this.$showModal({
        component: Client,
        isPersistent: true,
				props: {
					data: {
						name: clientNameRef || ''
					}
				}
      })
			if (createClient) {
				let obj = { id: createClient.id, ...createClient.data }
				this.form.client = obj
				this.setMainClientContact()
			}
		},
		setMainClientContact() {
			if (this.form.client && this.form.client.contacts) {
				const main = this.form.client.contacts.find(x => x.main)
				if (main) {
					this.form.contact = main
				}
			}
		},
    findClient(val) {
      if (val && !val.id) {
        let clients = this.clients
        let client = clients.find(x => x.name.toLowerCase() === val.toLowerCase())
        if (client && client.id) {
          this.form.client = client
        }
      }
    },
    async editDate() {
      let date = await this.$showModal({
        component: SelectDate,
        isPersistent: true,
        props: {
          title: 'Укажите дату',
          selectDate: this.form.date.seconds ? new Date(this.form.date).toISOString().substr(0, 10) : this.form.date
        }
      })

      if (date) {
        this.form.date = date
      }
    },
    updateStatusMontage(montage, status) {
      this.$store.dispatch('montages/updateMontage', { id: montage.id, data: {
        status: status
      } })
      this.close()
    },
    async editPerformers() {
      let performers = await this.$showModal({
        component: SelectPerformers,
        isPersistent: true,
        props: {
          data: this.form
        }
      })
      // console.log(performers)
      if (performers) {
        await this.$store.dispatch('montages/updateMontage', {
          id: this.data.id,
          data: {
            ...performers
          }
        })
        this.form = { ...this.form, ...performers }
      }
    },
    removeFieldTask(index) {
      this.form.tasks.splice(index, 1)
    },
    addFieldTask() {
      this.form.tasks.push({ value: '' })
    },
    deleteImage(path, index) {
      this.form.images.splice(index, 1)
    },
    async uploadImage() {
      const input = document.createElement("input")
      input.type = "file";
      input.accept='image/*'
      input.multiple='multiple'

      const options = {
        maxSizeMB: 3,
        maxWidthOrHeight: 1520,
        useWebWorker: true
      }

      input.onchange = async (e) => {
        var files = await e.target.files

        for(let key in files) {
          let file = files[key]
          if (file && file instanceof File) {
            let compressedFile = await imageCompression(file, options)

            // let filePath = `montages/${new Date().getTime()}_${key}.${/[^.]+$/.exec(file.name)}`
            // let ref = this.$storage.ref().child(filePath).put(compressedFile)
            // ref.on(`state_changed`, snapshot => {
              // this.uploadingImage = true
            // }, error => {
              // console.log(error)
            // }, () => {
              // ref.snapshot.ref.getDownloadURL().then((url)=>{
                // this.uploadingImage = false
                // this.form.images.push(filePath)
              // })
            // })
              this.uploadingImage = true
              let filePath = await api.upload('upload',compressedFile).then(response => {
                if(response.status == 200){
                  this.uploadingImage = false
                  return `${fileUrl}/${response.data.url}`
                }
              })
              this.form.images.push(filePath)
          }
        }

        this.uploadingImage = false
      };
      this.$refs.file.appendChild(input)
      input.click();
    },
    async cancelMontage() {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Отмена монтажа`,
          text: `Действительно хотите отменить монтаж для "${this.data.client.name}"?`
        }
      })

      if (res) {
        await this.$store.dispatch('montages/updateMontage', {
          data: {
            id: this.data.id,
            status: 'unassigned',
            montagers: [],
            montagersInfo: null,
            brig: null,
            brigData: null,
          },
          action: 'unassigned',
					manager: this.data.manager,
					oldData: this.data
        })
        this.close()
      }
    },
    async deleteMontage() {
      const res = await this.$showModal({
        component: Confirm,
        isPersistent: true,
        props: {
          title: `Удаление монтажа`,
          text: `Действительно хотите удалить монтаж для "${this.data.client.name}"?`
        }
      })

      if (res) {
        this.close()
        setTimeout(() => this.$store.dispatch('montages/deleteMontage', this.data), 0)
  
      }
    },
    save(status) {
      setTimeout(async () => {
        const errors = []
        const validate = await this.$refs.formCreateMontage.validate()
        errors.push(validate)

        if (this.form.client && !this.form.client.id) {
          if (!this.newClient.surname) {
            errors.push(false)
            this.$refs.newClientSurname.validate()
          }

          if (!this.newClient.phone || this.newClient.phone.length != 17) {
            errors.push(false)
            this.$refs.newClientPhonenumber.validate()
          }
        }

        if (!errors.includes(false)) {
          let form = { ...this.form }

          if (!this.edit || status === 'assigned') {
            let date = await this.$showModal({
              component: SelectDate,
              isPersistent: true,
              props: {
                title: status === 'unassigned' ? 'Предварительная дата' : 'Дата монтажа',
                selectDate: form.date.seconds ? new Date(form.date).toISOString().substr(0, 10) : form.date
              }
            })
            if (date) {
              form = { ...form, date: new Date(date) }
            } else {
              return null
            }
          }

          if (status === 'assigned') {
            let performers = await this.$showModal({
              component: SelectPerformers,
              isPersistent: true,
              props: {}
            })
            if (performers) {
              form = { ...form, ...performers }
            }
          }

          // client
          if (form.client.id) {
            form.client.address = form.client.address || []
            if (!form.client.address.includes(form.address)) {
              this.$store.commit('templ/setLoading', true)
              await api.put('clients/update',{
                id: form.client.id,
                address: [...form.client.address, form.address]
              })
              // await clientsCollection.doc(form.client.id).update({
              //   address: [...form.client.address, form.address]
              // })
              this.$store.commit('templ/setLoading', false)
            }
            // form.client = clientsCollection.doc(form.client.id)
            form.client = await api.get('clients/get',form.client.id).then(response => {
              if(response.status == 200){
                return response.data.data.id
              }
            })
          } else {
            // this.$store.commit('templ/setLoading', true)
            // let newClient = await clientsCollection.add({
            //   del: false,
            //   name: form.client,
            //   created: new Date(),
            //   address: form.address ? [form.address] : [],
            //   surname: this.newClient.surname,
            //   phonenumber: this.newClient.phonenumber,
            //   path: this.newClient.path
            // })
            // this.$store.commit('templ/setLoading', false)
						let newClient = {
              name: form.client,
              address: form.address ? [form.address] : [],
              surname: this.newClient.surname,
              phone: this.newClient.phone,
              path: this.newClient.path
            }
						let res = await this.$store.dispatch('clients/createClient', newClient)
            // form.client = clientsCollection.doc(res.id)
            form.client = res.id
          }

          if (!this.edit) {
            form.status = status
          } else if (this.edit && status === 'assigned') {
            form.status = 'assigned'
          }

          if (form.date && form.date.length === 10) {
            form = { ...form, date: new Date(form.date) }
          }


          if (form.date && ((status != 'unassigned' && form.montagers && form.montagers.length > 0) || status === 'unassigned')) {
            if (this.edit) {
              await this.$store.dispatch('montages/updateMontage', {
                id: this.data.id,
                data: {
                  ...form
                },
                action: status,
								manager: this.data.manager,
								oldData: this.data
              })
            } else {
              // let user = usersCollection.doc(this.user.id)
              let user = await api.get('users/get',this.user.id).then(response => {
                if(response.status == 200){
                  return response.data.data
                }
              })
              await this.$store.dispatch('montages/createMontage', {
                ...form,
                manager: user.id,
                created: new Date()
              })
							this.close('newMontage')
            }
            this.close()
          }
          // this.loaded()
        }
      }, 200)
    },
    close(info) {
      this.dialog = false
      setTimeout(() => {
        this.$emit('close', info)
      }, 200)
    }
  }
}
</script>
